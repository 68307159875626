﻿.navbar-default {
    background-color: transparent;
    border: none;
    @media only screen and (max-width : $break-to-m) {
        >.container {
            padding: 0;
        }
    }
}

#navbar {
    @media only screen and (max-width : $break-to-m) {
        padding: 0;
    }
    padding: 20px 0 12px;
}

.navbar-collapse.collapse {
    height: auto!important;
}

.navbar-brand {
    float: none;
    height: auto;
    padding: 27px 0 0;
    @media only screen and (max-width : $break-to-m) {
        padding: 20px 0 0;
    }
    text-align: center;
    a {
        display: block;
    }
}

.navbar-brand img {
    max-height: 40px;
    @media only screen and (max-width : $break-to-m) {
        max-height: 30px;
    }
    height: auto !important;
}

.navbar-default .navbar-toggle {
    border-color: transparent;
    float: left;
    margin-left: 10px;
    margin-right: 0;
    padding: 5px 10px;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background-color: transparent;
}

.slide-menu-wrapper {
    position: relative;
    overflow: hidden;
    /* iOS mobile menu fix
     * https://stackoverflow.com/questions/18271460/fixed-positioning-z-index-issue-in-mobile-safari
     */
    -webkit-transform: translate3d(0,0,0);
}

.slide-menu-wrapper .slide-content {
    position: relative;
    left: 0;
    transition: .5s;
}


.slide-menu-wrapper.open .slide-content {
    left: 260px;
}


.slide-menu-wrapper.open .navbar-toggle:focus,
.slide-menu-wrapper.open .navbar-toggle:hover,
.slide-menu-wrapper.open .navbar-toggle {
    background-color: $white;
    margin: 0;
    padding: 13px;
    border-right: 0 none;
    border-bottom: 0 none;
    border-radius: 0;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: $color-brand-blue;
    height: 4px;
    margin-top: 5px;
    width: 32px;
}

.navbar-collapse {
    border: none;
}

.navbar-collapse.collapse {
    display: block;
}

.mobile-nav.navbar-nav .dropdown-menu,
.mobile-nav.navbar-nav .open .dropdown-menu,
.mobile-nav.navbar-nav {
    margin: 0;
    position: fixed;
    top: 0;
    left: -260px;
    background-color: $color-brand-blue;
    @include vsl-gradient-nav-mobile;
    width: 260px;
    height: 1000vw;
    z-index: 100;
    transition: $global_transition;
    box-shadow: inset -5px 0px 16px #333;
    display: block;
    padding: 15px;
}

.mobile-nav.navbar-nav .open .dropdown-menu,
.slide-menu-wrapper.open .navbar-nav {
    left: 0;
}

.navbar-default .mobile-nav.navbar-nav a,
.navbar-default .mobile-nav.navbar-nav a:focus,
.navbar-default .mobile-nav.navbar-nav a:hover,
.navbar-default .mobile-nav.navbar-nav > .open > a,
.navbar-default .mobile-nav.navbar-nav > .open > a:focus,
.navbar-default .mobile-nav.navbar-nav > .open > a:hover {
    background-color: transparent;
}

.navbar-default .navbar-nav .open .dropdown-menu > .active > a,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    background-color: transparent;
}

.slide-menu-wrapper.open .navbar-toggle:before {
    content: "";
    position: absolute;
    border-right: 16px solid $white;
    border-top: 32.5px solid transparent;
    border-bottom: 32.5px solid transparent;
    left: -16px;
    top: -2px;
    z-index: 110;
}


.navbar-default .mobile-nav.navbar-nav li {
    display: block;
}

.navbar-default .mobile-nav.navbar-nav .dropdown-menu > li > a,
.navbar-default .mobile-nav.navbar-nav .open .dropdown-menu > li > a,
.navbar-default .mobile-nav.navbar-nav > li a,
.navbar-default .mobile-nav.navbar-nav > li a:focus,
.navbar-default .mobile-nav.navbar-nav > li a:hover {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-family: $accent_font-family;
    color: #fff;
    padding: 15px 0;
    position: relative;
    line-height: 1.643em;
    white-space: normal;
}

.navbar-default .mobile-nav.navbar-nav .dropdown-menu > li > ul {
    padding: 0 20px;
}

.navbar-default .mobile-nav.navbar-nav .dropdown-menu > li,
.navbar-default .mobile-nav.navbar-nav .dropdown-menu > li > ul > li a {
    text-transform: uppercase;
    font-size: 0.8571rem;
    font-family: $accent_font-family;
    position: relative;
    line-height: 1.643em;
    white-space: normal;
    display: block;
}

.navbar-default .mobile-nav.navbar-nav .dropdown-menu > li.dropdown {
    color: #667482;
    padding: 15px 0 0;
}

.navbar-default .mobile-nav.navbar-nav .dropdown-menu > li > ul > li a {
    padding: 15px;
    color: $color-brand-blue-sky;
}

.navbar-default .mobile-nav.navbar-nav > li > a > .caret {
    position: absolute;
    right: 20px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid $white;
    border-right: none;
    margin-top: 4px;
}

.navbar-default .mobile-nav.navbar-nav .dropdown-menu > li > a.mobile-nav-back,
.navbar-default .mobile-nav.navbar-nav .open .dropdown-menu > li > a.mobile-nav-back,
.navbar-default .mobile-nav.navbar-nav > li a.mobile-nav-back,
.navbar-default .mobile-nav.navbar-nav > li a.mobile-nav-back:focus {
    padding-left: 20px;
}

.mobile-nav-back:before {
    content: "";
    position: absolute;
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 10px solid $white;
    border-left: none;
    margin-top: 4px;
    left: -5px;
}

.mobile-nav-back:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 230px;
    height: 1px;
    background-color: $color-brand-blue-sky;
    bottom: 0;
    left: -5px;
}

// mobile

@media only screen and (max-width : $break-to-m) {
    .navbar {
        margin-bottom: 0;
    }
    .navbar > .container .navbar-brand img {
        height: auto;
    }

    .banner--home h1 {
        font-size: 1.75rem;
        margin-top: 1.5rem;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : $break-m) {
    .slide-menu-wrapper.open .slide-content {
        left: 0;
    }

    .header-top-nav-container {
        background-color: $color-brand-blue;
        .header-top-nav-wrapper {
            ul li {
                margin-bottom: 0;
                a {
                    color: $white;
                    text-transform: uppercase;
                    font-weight: 300;
                    @include respond-to(0, $custom-m-2) {
                        font-size: 14px;
                    }
                    &:hover,
                    &:focus {
                        i {
                            color: $color-brand-blue-sky;
                            transition: .25s;
                        }
                        outline: 0 none;
                    }
                }
            }
        }
    }

    .header-top-nav-wrapper {
        position: relative;
        width: auto;
        text-align: left;
        margin: 0 auto;
    }

    .header-top-nav {
        position: relative;
        right: auto;
        z-index: 20;
    }
    .header-top-right {
        text-align: right;
    }

    .header-contact {
        display: inline-block;
        margin-right: 1rem;
    }

    .cambium-logo {
        display: inline-block;
        padding-top: 12px;
        text-align: right;
        img {
            height: 35px;
        }
    }

    .header-top-nav-wrapper ul {
        margin: 0;
    }

    .header-top-nav-wrapper ul li a {
        padding: 20px 5px;
        display: inline-block;
        color: $color-brand-blue;
        font-size: 0.7857rem;
    }

    .container .navbar-collapse {
        text-align: center;
        margin-left: -15px;
        margin-right: -15px;
    }

    .navbar {
        margin-bottom: 0;
    }

    .navbar-header {
        float: none;
        text-align: left;
    }

    .navbar > .container .navbar-brand {
        left: auto;
        position: relative;
        top: inherit;
        display: inline-block;
        left: inherit;
        transform: none;
        margin-left: auto;
        width: 100%;
    }



    .desktop-nav.nav {
        margin: 0 auto;
        float: none;
        display: block;
    }

    .desktop-nav li.no-dropdown a,
    .desktop-nav.nav > li {
        float: none;
        display: inline-block;
    }

    .desktop-nav li.no-dropdown a,
    .desktop-nav.nav > li > a {
        font-size: 0.7857rem;
        // text-transform: uppercase;
        color: $color-brand-blue;
    }



    .desktop-nav.nav > li.dropdown {
        position: static;
    }

    .desktop-nav .dropdown-menu {
        width: 100%;
        background-color: $primary-color;
        @include vsl-gradient-nav;
        color: #fff;
        padding: 25px 0 30px;
        display: block;
        opacity: 0;
        visibility: hidden;
        z-index: 1000;
        transition: $global_transition;
        top: calc(100% - 2px);
    }

    .desktop-nav .dropdown-menu .h2 {
        text-transform: uppercase;
    }

    .desktop-nav .dropdown.open .dropdown-menu {
        opacity: 1;
        visibility: visible;
    }

    .desktop-nav .dropdown-menu a {
        font-size: 1.0714rem;
        color: $color-brand-blue-sky;
        font-weight: 700;
        display: inline-block;
        padding: 5px 0 2px;
        line-height: 1.1rem;
    }

    .desktop-nav .dropdown-menu img {
        max-width: 120px;
    }

    .desktop-nav .dropdown-toggle {
        transition: $global_transition;
    }

    .desktop-nav .dropdown-toggle .arrow {
        position: absolute;
        width: 100%;
        border-top: 8px solid transparent;
        border-left-style: solid;
        border-right-style: solid;
        border-left-color: transparent;
        border-right-color: transparent;
        z-index: 0;
        left: 0;
        transition: $global_transition;
        top: 0;
    }

    .desktop-nav .dropdown.open .dropdown-toggle .arrow {
        border-top-color: $color-neutral-gray-07;
        z-index: 1100;
        top: 100%;
    }

    .desktop-nav {
        & > li.dropdown.search-dropdown-toggle {
            position: relative;

            .search-dropdown {
                width: 350px;
                position: absolute;
                left: calc(100% - 350px);
                padding: 20px 0 10px;
                top: calc(100% + 1px);
            }
        }
    }
    .desktop-nav ul {
        padding-top: 0;
        padding-left: 0;
        margin-left: 0;
    }
}
/* Medium Devices, Desktops */
@media only screen and (min-width : $custom-m-2) {
    .navbar > .container .navbar-brand {
        // height: auto;
        // float: left;
        // display: inline-block;
        // width: auto;
        // transform: none;
    }

    .desktop-nav li.no-dropdown a,
    .desktop-nav.nav > li > a {
        font-size: 1rem;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 12px;
    }

    .navbar-brand {
        text-align: left;
    }

    .container .navbar-collapse {
        text-align: right;
    }

    .desktop-nav img {
        padding-top: 15px;
    }
}
/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}


.header-sub-section {
    h1 {
        font-size: 2rem;
    }
    &--blue {
        @include vsl-gradient-full-rev;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;;
        color: $white;
        * {
            color: $white;
        }
    }
}

.vsl-mobile-header {
    display: block;
    background: $color-brand-blue;
    color: $white;
    padding: 1rem;
    text-align: center;
    font-weight: bold;
    font-size: 0.875rem;
    a {
        color: $white;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}