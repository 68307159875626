// Hide from both screenreaders and browsers: h5bp.com/u

.removed {
    display: none !important;
    visibility: hidden;
}
.mobile__removed {
    @include respond-to(0, $break-to-m) {
        display: none !important;
        visibility: hidden;
    }
}
.tablet__removed {
    @include respond-to($break-to-m, $tweak-m-2) {
        display: none !important;
        visibility: hidden;
    }
}
.desktop__removed {
    @include respond-to($tweak-m-2) {
        display: none !important;
        visibility: hidden;
    }
}
.removed {
    display: none !important;
    visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v

.hide {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px) !important;  /* for Internet Explorer */
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    width: 1px !important;
    border: 0 !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
}
.mobile__hide, .mobile-hide {
    @include respond-to(0, $break-to-m) {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

// Extends the .hide class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p

.hide.focusable:active,
.hide.focusable:focus {
    clip: auto !important;
    height: auto !important;
    margin: 0 !important;
    overflow: visible !important;
    //position: static !important;
    width: auto !important;
    z-index: 1000;
}


// Hide visually and from screenreaders, but maintain layout

.invisible {
    visibility: hidden;
}

// Wrapper element to make iframe Embedded videos responsive

.embedded-video,
.embedded-16x9 {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.embedded-video iframe,
.embedded-16x9 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.embedded-4x3 {
    position: relative;
    padding-bottom: 75%; /* 4:3 */
    padding-top: 25px;
    height: 0;
}
.embedded-4x3 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Simple text modifier classes
.font-larger {
    font-size: 1.125em;
}
.font-standard {
    font-size: 1rem;
}
.font-smaller {
    font-size: 0.875em;
}
.font-spaced,
.text-spaced {
    letter-spacing: 1px;
}
.uppercase,.caps {
    text-transform: uppercase;
}
.not-italic {
    font-style: normal
}
.t--left {
    text-align: left;
}
.t--right {
    text-align: right;
}
.text-center,
.text-center-sm,
.t--center {
    text-align: center;
}

.truncate {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Simple layout modifier classes

.no-col-padding {padding: 0;}
.padding-40 {padding: 40px;}
.padding-30 {padding: 30px;}
.padding-20 {padding: 20px;}

// padding top
.p-top,
.p--top {
    padding-top: 1rem;
    &-sm {
        padding-top: .5rem;
    }
}
.pt-10 {padding-top: 10px !important;}
.pt-20 {padding-top: 20px !important;}
.pt-30 {padding-top: 30px !important;}
.pt-40 {padding-top: 40px !important;}
.pt-50 {padding-top: 50px !important;}
.pt-60 {padding-top: 60px !important;}
.pt-70 {padding-top: 70px !important;}
.pt-80 {padding-top: 80px !important;}

// padding bottom
.p-bottom,
.p--bottom {
    padding-bottom: 1rem;
    &-sm {
        padding-bottom: .5rem;
    }
}
.pb-10 {padding-bottom: 10px !important;}
.pb-20 {padding-bottom: 20px !important;}
.pb-30 {padding-bottom: 30px !important;}
.pb-40 {padding-bottom: 40px !important;}
.pb-50 {padding-bottom: 50px !important;}
.pb-60 {padding-bottom: 60px !important;}
.pb-70 {padding-bottom: 70px !important;}
.pb-80 {padding-bottom: 80px !important;}
.pb-90 {padding-bottom: 90px !important;}
.pb-100 {padding-bottom: 100px !important;}

// margin top
.m-top,
.m--top {
    margin-top: 1rem;
    &-sm {
        margin-top: .5rem;
    }
}
.mt-10 {margin-top: 10px !important;}
.mt-20 {margin-top: 20px !important;}
.mt-30 {margin-top: 30px !important;}
.mt-40 {margin-top: 40px !important;}
.mt-50 {margin-top: 50px !important;}
.mt-60 {margin-top: 60px !important;}
.mt-70 {margin-top: 70px !important;}
.mt-80 {margin-top: 80px !important;}
.mt-90 {margin-top: 90px !important;}
.mt-100 {margin-top: 100px !important;}

// margin bottom
.m-bottom,
.m--bottom {
    margin-bottom: 1rem;
    &-sm {
        margin-bottom: .5rem;
    }
}
.mb-10 {margin-bottom: 10px !important;}
.mb-20 {margin-bottom: 20px !important;}
.mb-30 {margin-bottom: 30px !important;}
.mb-40 {margin-bottom: 40px !important;}
.mb-50 {margin-bottom: 50px !important;}
.mb-60 {margin-bottom: 60px !important;}
.mb-70 {margin-bottom: 70px !important;}
.mb-80 {margin-bottom: 80px !important;}
.mb-90 {margin-bottom: 90px !important;}
.mb-100 {margin-bottom: 100px !important;}

.no-margin,
.m--none {
    margin:0 !important;
}
.no-padding,
.p--none {
    padding:0 !important;
}
.no-margin--top,
.m--top-none {
    margin-top:0 !important;
}
.no-padding--top,
.p--top-none {
    padding-top:0 !important;
}
.no-margin--bottom {
    margin-bottom: 0 !important;
}
.no-padding--bottom {
    padding-bottom: 0 !important;
}
.m--left {
    margin-left: 1rem;
}
.m--right {
    margin-right: 1rem;
}
.p--left {
    padding-left: 1em;
}
.p--right {
    padding-right: 1em;
}
.p--all {
    padding: 1em;
}

@include respond-to(0, $break-to-m) {
    .mobile__no-margin, .no-mobile-margin {
        margin:0 !important;
    }
    .mobile__no-padding, .no-mobile-padding {
        padding:0 !important;
    }
    .mobile__no-margin--top {
        margin-top:0 !important;
    }
    .mobile__m--top {
        margin-top: 1em;
    }
    .mobile__m--left {
        margin-left: 1em;
    }
    .mobile__m--right {
        margin-right: 1em;
    }
    .mobile__m--bottom {
        margin-bottom: 1em;
    }
    .mobile__m--both {
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .mobile__p--top, .breadcrumbs+.content:first-of-type>.content__block:first-of-type .content__block.mobile__p--top {
        padding-top: 1em;
    }
    .mobile__p--bottom {
        padding-bottom: 1em;
    }
    .mobile__p--both {
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .mobile__no-sides {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .mobile__font-larger {
        font-size: 1.125em;
    }
    .mobile__font-smaller {
        font-size: 0.875em;
    }
    .mobile__no-border {
        border: 0 none !important;
    }
    .mobile__t--center {
        text-align: center;
    }
}

@include respond-to(0, $custom-m-2) {
    .md__m--top {
        margin-top: 1em;
    }
    .md__m--left {
        margin-left: 1em;
    }
    .md__m--right {
        margin-right: 1em;
    }
    .md__m--bottom {
        margin-bottom: 1em;
    }
}

.block {
    display: block;
}
.block--full {
    display: block;
    width:100%;
}
.mobile-block {
    @include respond-to(0, $break-s) {
        display: block;
    }
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.block-indent {
    margin-left: 1em;
    @include respond-to($break-m) {
        margin-left: 3em;
    }
}
.f--right {
    @include respond-to($break-m) {
        float: right;
    }
}
.f--left {
    @include respond-to($break-m) {
        float: left;
    }
}
.clear-left {
    clear: left;
}
.clear-right {
    clear: right;
}
.clearfix {
    @include clearfix;
}

.full-width {
    width: 100%;
}

.underline-blue,
.product-underline {
    border-bottom: 1px solid $color-brand-blue-sky;
    width: 32px;
    margin: 10px 0 10px;
    &--reading,
    &--literacy {
        border-color: $color-brand-emerald;
    }
    &--writing {
        border-color: $color-brand-blueberry;
    }
    &--math {
        border-color: $color-brand-pomegranate;
    }
    &--assessment {
        border-color: $color-brand-blue-sky;
    }
    &--cs {
        border-color: $color-brand-tangerine;
    }
}