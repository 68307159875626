﻿$product-tile-gutter : 30px;

.product-tiles {
    display: grid;
    grid-column-gap: $product-tile-gutter;
    grid-row-gap: $product-tile-gutter;
    @include respond-to($tweak-s-1) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to($custom-m-2) {
        grid-template-columns: repeat(3, 1fr);
    }
    .product-tile {
        display: grid;
        grid-auto-rows: 1fr;
        position: relative;
        .vsl-arrow {
            padding-right: 0;
            padding-left: 8px;
        }
        &-container {
            display: grid;
            grid-auto-rows: max-content 1fr;
        }
        &-link {
            display: grid;
            grid-auto-rows: 1fr;
            position: relative;
            color: $color-brand-font;
            text-decoration: none;
            cursor: pointer;
            &:hover,
            &:focus {
                // color: $white;
                transition: all .25s;
                .product-overlay {
                    opacity: 0.15;
                }
                .product-theme {
                    transition: all .25s;
                    background-color:  $white;
                    color: $color-brand-blue-sky-dark;
                    background: linear-gradient(-135deg, transparent 33px, $white 0);
                }
            }
            .product-theme {
                padding: 0.75rem 1rem;
                color: $white;
                font-weight: bold;
                text-transform: uppercase;
            }
            .product-content-container {
                display: grid;
                grid-auto-rows: min-content;
                grid-row-gap: 0;
                position: relative;
                background-color: $white;
                text-align: center;
                width: 100%;
                padding: 1em;
                align-content: center;
                .product-description {
                    font-weight: bold;
                    color: $color-brand-blue;
                }
            }
            .product-content {
                position: relative;
            }
            .product-image {
                margin: 1rem auto;
                max-width: 200px;
            }
            .product-description {

            }
        }
    }
    div.product-tile-link {
        background-color: $white;
        .product-tile-container {
            grid-auto-rows: 1fr;
        }
        .product-content-container {
            background-color: transparent;
            grid-auto-rows: 1fr;
            align-content: start;
            padding: 1.5rem;
            .product-content {
                display: grid;
                grid-template-rows: min-content;
            }
            .product-description {
                font-weight: normal;
                color: $color-brand-font;
                text-align: left;
                display: grid;
                p:last-child {
                    align-self: end;
                    margin-bottom: 0;
                }
                .vsl-arrow {
                    padding-left: 0;
                    padding-right: 8px;
                }
            }
        }
    }
}

.product-tiles-container {
    background-color: $color-neutral-gray-07;
    .product-tiles--pairs .product-tiles {
        @include respond-to($tweak-s-1) {
            grid-auto-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
        }
        @include respond-to($custom-m-2) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .product-tiles--fourths .product-tiles {
        @include respond-to($tweak-s-1) {
            grid-auto-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
        }
        @include respond-to($custom-m-2) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

.product-tile .product-overlay {
    display: block; // uncomment when we have white logos
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all .25s;
    background-color:  $color-brand-blue-sky-dark;
}

.product-tile {
    &--Reading,
    &--Literacy {
        .product-theme,
        .product-overlay {
            background-color:  $color-brand-emerald;
        }
        .product-theme {
            background: linear-gradient(-135deg, transparent 33px, $color-brand-emerald 0);
        }
        .product-tile-link:hover .product-theme,
        .product-tile-link:focus .product-theme {
            color: $color-brand-emerald;
        }
    }
    &--Writing {
        .product-theme,
        .product-overlay {
            background-color:  $color-brand-blueberry;
        }
        .product-theme {
            background: linear-gradient(-135deg, transparent 33px, $color-brand-blueberry 0);
        }
        .product-tile-link:hover .product-theme,
        .product-tile-link:focus .product-theme {
            color: $color-brand-blueberry;
        }
    }
    &--Math {
        .product-theme,
        .product-overlay {
            background-color:  $color-brand-pomegranate;
        }
        .product-theme {
            background: linear-gradient(-135deg, transparent 33px, $color-brand-pomegranate 0);
        }
        .product-tile-link:hover .product-theme,
        .product-tile-link:focus .product-theme {
            color: $color-brand-pomegranate;
        }
    }
    &--Assessment {
        .product-theme,
        .product-overlay {
            background-color:  $color-brand-blue-sky;
        }
        .product-theme {
            background: linear-gradient(-135deg, transparent 33px, $color-brand-blue-sky 0);
        }
        .product-tile-link:hover .product-theme,
        .product-tile-link:focus .product-theme {
            color: $color-brand-blue-sky;
        }
    }
    &--Customer {
        .product-theme,
        .product-overlay {
            background-color:  $color-brand-tangerine;
        }
        .product-theme {
            background: linear-gradient(-135deg, transparent 33px, $color-brand-tangerine 0);
        }
        .product-tile-link:hover .product-theme,
        .product-tile-link:focus .product-theme {
            color: $color-brand-tangerine;
        }
    }
}
