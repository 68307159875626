﻿.author-list,
.people-list,
.blog-authors,
.check-list,
.star-list,
.arrow-list {
    margin-bottom: 25px;
    ul {
        position: relative;
        list-style: none;
        padding-left: 1.75rem;
        margin: 0;
        font-size: 0.875rem;
        li {
            position: relative;
            margin-bottom: 0.5rem;
            a {
                display: block;
                position: relative;
                &:hover,
                &:focus {
                    .list-badge {
                        text-decoration: none;
                    }
                }
            }
            &::before {
                // https://fontawesome.com/icons/arrow-right?f=classic&s=solid                position: absolute;
                position: absolute;
                left: -1.65em;
                display: block;
                content: '\f061';
                font-family: "Font Awesome 6 Pro";
                color: $color-brand-blue;
                font-size: 0.875rem;
                font-weight: bold;
            }
        }
        .list-badge {
            color: $white;
            position: relative;
            display: inline-block;
            padding: 2px 5px;
            margin-left: 3px;
            top: -1px;
            background-color: $color-brand-blue;
            border: 1px solid transparent;
            font-size: 0.625rem;
            line-height: 1em;
            border-radius: $global-radius-lg;
        }
    }
}

.arrow-list {
    &-circle {
        ul li::before {
            // https://fontawesome.com/icons/circle-check?f=classic&s=solid
            content: '\f0a9';
        }
    }
    &-square {
        ul li::before {
            // https://fontawesome.com/icons/square-check?f=classic&s=solid
            content: '\f33b';
        }
    }
}

.check-list {
    ul li::before {
        // https://fontawesome.com/icons/check?f=classic&s=solid
        content: '\f00c';
        color: $color-brand-blue-sky;
    }
    &-circle {
        ul li::before {
            // https://fontawesome.com/icons/circle-check?f=classic&s=solid
            content: '\f058';
        }
    }
    &-square {
        ul li::before {
            // https://fontawesome.com/icons/square-check?f=classic&s=solid
            content: '\e27f';
        }
    }
}

.star-list {
    ul li::before {
        // https://fontawesome.com/icons/star?f=classic&s=solid
        content: '\f005';
        color: $color-brand-blue-sky;
    }
    &-circle {
        ul li::before {
            // https://fontawesome.com/icons/circle-star?f=classic&s=solid
            content: '\e123';
        }
    }
    &-square {
        ul li::before {
            // https://fontawesome.com/icons/square-star?f=classic&s=solid
            content: '\e27f';
        }
    }
}

.author-list,
.people-list,
.blog-authors {
    ul li::before {
        // https://fontawesome.com/icons/user?f=classic&s=solid
        content: '\f007';
        color: $color-brand-blue-sky;
    }
    &-circle {
        ul li::before {
            // https://fontawesome.com/icons/circle-user?f=classic&s=solid
            content: '\f2bd';
        }
    }
    &-square {
        ul li::before {
            // https://fontawesome.com/icons/square-user?f=classic&s=solid
            content: '\e283';
        }
    }
}

.author-list,
.people-list,
.blog-authors,
.check-list,
.star-list,
.arrow-list {
    &-inverse {
        color: $white;
        ul li {
            color: $white;
            a {
                color: $white;
            }
            &::before {
                color: $white;
            }
            .list-badge {
                color: $color-brand-blue;
                background-color: $white;
            }
        }
    }
}

.pill-list,
.cloud-list,
.blog-topics,
.blog-years {
    margin-bottom: 25px;
    ul {
        list-style: none;
        overflow: hidden;
        padding-left: 0;
        margin: 0;
        li {
            float: left;
            margin: 5px;
            a {
                color: $color-brand-blue;
                display: inline-block;
                padding: 5px 10px;
                border: 1px solid $color-brand-blue;
                font-size: 0.75rem;
                transition: .25s;
                border-radius: $global-radius-lg;
                &:hover,
                &:focus {
                    background-color: $color-brand-blue-sky;
                    border-color: $color-brand-blue-sky;
                    color: $white;
                    text-decoration: none;
                }
            }
        }
    }
    .list-badge {
        display: inline-block;
        position: relative;
        margin-left: 3px;
        font-size: 0.625rem;
        line-height: 1em;
        opacity: 0.75;
    }
    &-inverse {
        color: $white;
        ul li {
            a {
                color: $white;
                border-color: $white;
                &:hover,
                &:focus {
                    background-color: $white;
                    border-color: $white;
                    color: $color-brand-blue;
                }
            }
        }
    }
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 0;
    overflow: hidden;
    li {
        display: inline-block;
        a, & > span {
            border: 1px solid $color-brand-blue-sky;
            background-color: $color-brand-blue-sky;
            border-radius: $global-radius;
            color: $white;
            font-size: 0.75rem;
            font-weight: bold;
        }
        a {
            &:hover,
            &:focus {
                color: $white;
                background-color: $color-brand-blue;
                border-color: $color-brand-blue;
            }
        }
        &.active {
            a, span {
                z-index: 3;
                color: $white;
                background-color: $color-brand-blue;
                border-color: $color-brand-blue;
            }
            a {
                &:hover,
                &:focus {
                    background-color: $color-brand-blue;
                border-color: $color-brand-blue;
                }
            }
        }
        &:first-child,
        &:last-child {
            >a,
            >span {
                border-radius: $global-radius;
            }
        }
    }
    li:not(:last-child), li:not(:last-child) {
        margin-right: 5px;
    }
}

$number-list: 20px;

.number-list {
    position: relative;
    list-style: none;
    margin: 0 0 25px;
    padding-left: 2.125rem;
    font-size: 1rem;
    counter-reset: step-counter;
    li {
        position: relative;
        margin-bottom: 0.5rem;
        counter-increment: step-counter;
        &::before {
            position: absolute;
            left: calc(-1 * $number-list - 12px);
            display: block;
            content: counter(step-counter);
            color: $white;
            font-size: 0.688rem;
            font-weight: bold;
            background-color: $color-brand-blue-sky;
            border-radius: 50%;
            width: $number-list;
            height: $number-list;
            line-height: $number-list;
            text-align: center;
        }
        a {
            display: block;
            position: relative;
            &:hover,
            &:focus {
                .list-badge {
                    text-decoration: none;
                }
            }
        }
    }
    ol {
        list-style: none;
        margin: 0 0 0.5rem;
        padding-left: 1.75rem;
        counter-reset: step-sub-counter;
        li {
            counter-increment: step-sub-counter;
            &::before {
                content: counter(step-sub-counter);
                background-color: $color-neutral-gray-50;
            }
        }
        ol li::before {
            border-radius: 0;
        }
    }
    ul {
        list-style: none;
        margin: 0 0 0.5rem;
        padding-left: 1.75rem;
        counter-reset: step-sub-counter;
        li {
            counter-increment: step-sub-counter;
            &::before {
                content: '\f061';
                font-family: "Font Awesome 6 Pro";
            }
        }
        ul li::before {
            border-radius: 0;
        }
    }
}