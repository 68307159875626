@mixin badge-note {
    border-left-width: 3px;
    border-left-style: solid;
    background-color: transparent;
    border-radius: 0;
    margin-left:.5em;
    text-transform: uppercase;
}

@mixin alert-variant($background, $text-color) {
    background-color: $background;
    border-color: darken($background, 15%);
    color: $text-color;
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: .4em .7em;
    font-size: 12px;
    line-height: 1;
    color: $white;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: $color-neutral-gray-73;
    border-radius: $global-radius;
    letter-spacing: normal;
    font-style: normal;
    font-weight: bold;
    font-family: $body_font-family;
    text-transform: uppercase;
    &:empty {
      display: none;
    }
    &--success {
        background-color: $color-utility-success;
        &-alt {
            border-width: 1px;
            border-style: solid;
            @include alert-variant( $color-utility-success-light, $color-utility-success);
        }
        &-note {
            @include alert-variant( $color-utility-success-light, $color-utility-success);
            @include badge-note;
        }
    }
    &--info {
        background-color: $color-utility-info;
        &-alt {
            border-width: 1px;
            border-style: solid;
            @include alert-variant($color-utility-info-light, $color-utility-info);
        }
        &-note {
            @include alert-variant($color-utility-info-light, $color-utility-info);
            @include badge-note;
        }
    }
    &--warning {
        background-color: $color-utility-warning;
        &-alt {
            border-width: 1px;
            border-style: solid;
            @include alert-variant($color-utility-warning-light, $color-utility-warning);
        }
        &-note {
            @include alert-variant($color-utility-warning-light, $color-utility-warning);
            @include badge-note;
        }
    }
    &--error {
        background-color: $color-utility-error;
        &-alt {
            border-width: 1px;
            border-style: solid;
            @include alert-variant($color-utility-error-light, $color-utility-error);
        }
        &-note {
            @include alert-variant($color-utility-error-light, $color-utility-error);
            @include badge-note;
        }
    }
    &--inverted {
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        border-color: $white;
        color: $white;
    }
    &+ .badge {
        margin-left: .5em;
    }
}

.btn .badge {
  position: relative;
  top: -1px;
}

a.badge:hover,
a.badge:focus {
  color: $white;
  text-decoration: none;
  cursor: pointer;
}

.vsl-badge-section {
    margin-top: 40px;
    margin-bottom: 40px;
}

.vsl-badges {
    @include clearfix;
    .vsl-badge {
        display: inline-block;
        background-repeat: no-repeat;
        width: 115px;
        height: 130px;
        text-decoration: none;
        svg {
            width: 115px;
            height: 130px;
        }
        &:hover,
        &:focus {
            transform: scale(1.1);
        }
    }
    @include respond-to(0,$custom-m-2) {
        margin-top: 60px;
        text-align: center;
    }
}

.vsl-badge {
    &--reading,
    &--literacy {
        background-image: url("https://vsl.widen.net/content/xwc7rydchb/svg/VSL_Badges_Reading.svg");
        &-white {
            background-image: url("https://vsl.widen.net/content/crnppsyb4s/svg/VSL_Badges_Reading_White.svg");
            &:hover,
            &:focus {
                // background-image: url( 'https://vsl.widen.net/content/xwc7rydchb/svg/VSL_Badges_Reading.svg' );
            }
        }
        &-icon {
            background-image: url("https://vsl.widen.net/content/kr5quc2koq/svg/VSL_Badges_Reading_Icon.svg");
        }
    }
    &--writing {
        background-image: url("https://vsl.widen.net/content/kp1qouxqnp/svg/VSL_Badges_Writing.svg");
        &-white {
            background-image: url("https://vsl.widen.net/content/gatujm2yle/svg/VSL_Badges_Writing_White.svg");
            &:hover,
            &:focus {
                // background-image: url("https://vsl.widen.net/content/kp1qouxqnp/svg/VSL_Badges_Writing.svg");
            }
        }
        &-icon {
            background-image: url("https://vsl.widen.net/content/dmhcqcsevs/svg/VSL_Badges_Writing_Icon.svg");
        }
    }
    &--math {
        background-image: url("https://vsl.widen.net/content/xp4tihxmv0/svg/VSL_Badges_Math.svg");
        &-white {
            background-image: url("https://vsl.widen.net/content/8n4ifgu7ku/svg/VSL_Badges_Math_White.svg");
            &:hover,
            &:focus {
                // background-image: url( 'https://vsl.widen.net/content/xp4tihxmv0/svg/VSL_Badges_Math.svg' );
            }
        }
        &-icon {
            background-image: url("https://vsl.widen.net/content/tcgmyzshvl/svg/VSL_Badges_Math_Icon.svg");;
        }
    }
    &--assessment {
        background-image: url("https://vsl.widen.net/content/l7acpr8qfv/svg/VSL_Badges_Assessment.svg");
        &-white {
            background-image: url("https://vsl.widen.net/content/oxjdwjbpeq/svg/VSL_Badges_Assessment_White.svg");
            &:hover,
            &:focus {
                // background-image: url( 'https://vsl.widen.net/content/l7acpr8qfv/svg/VSL_Badges_Assessment.svg' );
            }
        }
        &-icon {
            background-image: url("https://vsl.widen.net/content/5xfdymu7lc/svg/VSL_Badges_Assessment_Icon.svg");
        }
    }
    &--cs{
        background-image: url("https://vsl.widen.net/content/0gxa0qtmrv/svg/VSL_Badges_Customer_Success.svg");;
        &-white {
            background-image: url("https://vsl.widen.net/content/ptlqznc1yq/svg/VSL_Badges_Customer_Success_White.svg");
            &:hover,
            &:focus {
                // background-image: url( 'https://vsl.widen.net/content/0gxa0qtmrv/svg/VSL_Badges_Customer_Success.svg' );
            }
        }
        &-icon {
            background-image: url("https://vsl.widen.net/content/vdpwuxpauj/svg/VSL_Badges_Customer_Success_Icon.svg");
        }
    }
}

.vsl-badge-icon {
    display: inline-block;
    background-repeat: no-repeat;
    width: 115px;
    height: 115px;
    text-decoration: none;
    svg {
        width: 115px;
        height: 115px;
    }
    &a:hover,
    &a:focus {
        transform: scale(1.1);
    }
}

.dropdown-menu .vsl-badge-icon {
    margin-right: 1rem;
    width: 40px;
    height: 40px;
}

.dropdown-menu .vsl-badge-icon {
    margin-right: 1rem;
    width: 40px;
    height: 40px;
}

.desktop-nav .dropdown-menu .product-category-icon,
.product-category-icon {
    font-size: 1rem;
    span {
        display: inline-block;
    }
    a {
        display : flex;
        align-items : center;
        vertical-align: middle;
        &:hover,
        &:focus {
            padding-left: 0;
            span + span {
                transition: $global_transition;
                padding-left: 3px;
            }
        }
    }
}

.product-category-icon + .product-list {
    margin-left: 55px;
}
