.testimonial {
    background-color: $color-brand-blue;
    padding: 20px;
    color: $white;
}

.carousel {
    height: auto;
    background-color: $color-brand-blue;

    h4, p {
        color: $white;
        text-align: left;
    }

    h4 {
        margin-top: 20px;
        text-shadow: none;
        padding: 0 15px;
        font-size: 16px;
    }

    p {
        font-size: 1rem;
    }
}

.carousel-caption {
    position: relative;
    left: auto;
    right: auto;
    padding-bottom: 0;
}

.carousel-indicators {
    top: 0;
}

.carousel .container {
    padding-top: 0;
}

.carousel-control.right,
.carousel-control.left {
    background-image: none;
}

@media screen and (max-width: 992px) {
    .carousel-control {
        top: -30px;
        bottom: 30px;
    }

    .carousel-indicators {
        top: -35px;
    }
}