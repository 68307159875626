﻿img.set-as-background, .background-image-placeholder img {
    display: none;
}

.image-as-background{
    position:relative;
    background-position:center;
    background-size:cover;

}

.img-hover,
.img--hover {
    transition: $global_transition;
    &:hover,
    &:focus {
        transform: scale(1.1);
    }
}

.img-right {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;
    @include respond-to (0, $tweak-s-2) {
        display: block;
        float: none;
        margin: 0 auto 1rem;
    }
}

.img-left {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
    @include respond-to (0, $tweak-s-2) {
        display: block;
        float: none;
        margin: 0 auto 1rem;
    }
}


// https://miketricking.github.io/bootstrap-image-hover/

.hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: visible;
    position: relative;
    text-align: center;
    cursor: default;

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
        transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
    }

    img {
        display: block;
        position: relative;
        -webkit-transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
        transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
    }

    h2 {
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        position: relative;
        font-size: 16px;
        background: rgba(0, 0, 0, 0.6);
        -webkit-transform: translatey(-100px);
        -ms-transform: translatey(-100px);
        transform: translatey(-100px);
        -webkit-transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
        transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
        padding: 10px;
    }

    a.info {
        text-decoration: none;
        display: inline-block;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        margin: 50px 0 0;
        padding: 7px 14px;
    }

    a.info:hover {
        box-shadow: 0 0 5px #fff;
    }

    :hover img {
        -ms-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    :hover .overlay {
        opacity: 1;
        filter: alpha(opacity=100);
    }

    :hover h2,
    .hovereffect:hover a.info {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-transform: translatey(0);
        -webkit-transform: translatey(0);
        transform: translatey(0);
    }

    :hover a.info {
        -webkit-transition-delay: .2s;
        transition-delay: .2s;
    }
}