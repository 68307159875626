﻿
:root {
    font-size: $root_font-size;
}

.root {
    font-size: $root_font-size;
}

body, .body-text {
    color: $body_color;
    font-family: $body_font-family;
    font-size: $body_font-size;
    font-weight: $body_font-weight;
    transition: $body_transition;
}

.container {
    margin: $container_margin;
    max-width: $container_max-width;
    padding-left: $container_padding-left;
    padding-right: $container_padding-right;
    width: $container_width;
}

.accent-font {
    font-family: $accent_font-family;
    font-style: $accent_font-style;
    font-weight: $accent_font-weight;
    font-size: $accent_font-size;
    text-transform: $accent_text-transform;
}

img {
    max-width: $img_max-width;
}

ul {
    list-style: $ul_list-style;
    /*padding: $ul_padding; <- This was causing nested uls to lose padding*/
}

li {
    // font-size: $root_font-size;
    // line-height: $li_line-height;
    margin-bottom: $li_margin-bottom;

    a, a:link, a:active, a:visited {
        color: #005587;
    }
}

p, .p, article, .article {
    font-size: $p_font-size;
    line-height: $p_line-height;
    margin-bottom:$p_margin-bottom;
    /*padding: $p_padding;*/
    a, a:link, a:active, a:visited {
        color: #005587;
    }
}

small {
    font-size: .75rem;
}

.em {
    font-style: $em_font-style;
}


a {
    color: $a_color;
    text-decoration: $a_text-decoration;
    transition: $global_transition;
}

sup {
    padding-left: 2px;
}

a.hover, a.focus,
a:hover, a:focus {
    color: $a-hover_color;
    text-decoration: $a-hover_text-decoration;
}

a:focus, a.focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

h1, .h1, .accent-title1 {
    font-family: $h1_font-family;
    font-weight: $h1_font-weight;
    line-height: $h1_line-height;
    color: $h1_color;
    margin-top: $h1_margin-top;
    margin-bottom: $h1_margin-bottom;
    font-size: $h1_font-size;
    font-style: $h1_font-style;
}

.hero-banner-custom-bg-img {
    h1, .h1, .accent-title1 {
        color: $white;
        margin-bottom: 0;
    }
}

h1 small, h1 .small, .h1 small, .h1 .small {
    font-size: $h1-small_font-size;
    font-weight: $h1-small_font-weight;
    line-height: $h1-small_line-height;
    color: $h1-small_color;
}

h2, .h2, .accent-title2 {
    font-family: $h2_font-family;
    font-weight: $h2_font-weight;
    line-height: $h2_line-height;
    color: $h2_color;
    margin-top: $h2_margin-top;
    margin-bottom: $h2_margin-bottom;
    font-size: $h2_font-size;
    font-style: $h2_font-style;
    letter-spacing: normal;
}

h2 small, h2 .small, .h2 small, .h2 .small {
    font-size: $h2-small_font-size;
    font-weight: $h2-small_font-weight;
    line-height: $h2-small_line-height;
    color: $h2-small_color;
}

h2.left-border,
.left-border {
    border-left: 3px solid $color-brand-green;
    padding-left: 10px;
}

.reading-theme,
.literacy-theme {
    .left-border {
        border-left-color: $color-brand-emerald;
    }
}
.writing-theme {
    .left-border {
        border-left-color: $color-brand-blueberry;
    }
}
.math-theme {
    .left-border {
        border-left-color: $color-brand-pomegranate;
    }
}
.assessment-theme {
    .left-border {
        border-left-color: $color-brand-blue-sky;
    }
}
.cs-theme {
    .left-border {
        border-left-color: $color-brand-tangerine;
    }
}

h3, .h3, .accent-title3 {
    font-family: $h3_font-family;
    font-weight: $h3_font-weight;
    line-height: $h3_line-height;
    color: $h3_color;
    margin-top: $h3_margin-top;
    margin-bottom: $h3_margin-bottom;
    font-size: $h3_font-size;
    font-style: $h3_font-style;
    text-transform: $h3_text-transform;
}

h3 small, h3 .small, .h3 small, .h3 .small {
    font-size: $h3-small_font-size;
    font-weight: $h3-small_font-weight;
    line-height: $h3-small_line-height;
    color: $h3-small_color;
}

h4, .h4, .accent-title4 {
    font-family: $h4_font-family;
    font-weight: $h4_font-weight;
    line-height: $h4_line-height;
    color: $h4_color;
    margin-top: $h4_margin-top;
    margin-bottom: $h4_margin-bottom;
    font-size: $h4_font-size;
    font-style: $h4_font-style;
}

h4 small, h4 .small, .h4 small, .h4 .small {
    font-size: $h4-small_font-size;
    font-weight: $h4-small_font-weight;
    line-height: $h4-small_line-height;
    color: $h4-small_color;
}

h5, .h5, .accent-title5 {
    font-family: $h5_font-family;
    font-weight: $h5_font-weight;
    line-height: $h5_line-height;
    color: $h5_color;
    margin-top: $h5_margin-top;
    margin-bottom: $h5_margin-bottom;
    font-size: $h5_font-size;
    font-style: $h5_font-style;
}

h5 small, h5 .small, .h5 small, .h5 .small {
    font-size: $h5-small_font-size;
    font-weight: $h5-small_font-weight;
    line-height: $h5-small_line-height;
    color: $h5-small_color;
}

h6, .h6, .accent-title6 {
    font-family: $h6_font-family;
    font-weight: $h6_font-weight;
    line-height: $h6_line-height;
    color: $h6_color;
    margin-top: $h6_margin-top;
    margin-bottom: $h6_margin-bottom;
    font-size: $h6_font-size;
    font-style: $h6_font-style;
}

h6 small, h6 .small, .h6 small, .h6 .small {
    font-size: $h6-small_font-size;
    font-weight: $h6-small_font-weight;
    line-height: $h6-small_line-height;
    color: $h6-small_color;
}

.font-thin,
h1.font-thin,
h2.font-thin,
h3.font-thin,
h4.font-thin,
h5.font-thin,
h6.font-thin {
    font-weight: 200;
}

.font-regular,
h1.font-regular,
h2.font-regular,
h3.font-regular,
h4.font-regular,
h5.font-regular,
h6.font-regular {
    font-weight: 400;
}

.font-thick,
h1.font-thick,
h2.font-thick,
h3.font-thick,
h4.font-thick,
h5.font-thick,
h6.font-thick {
    font-weight: 800;
}

.text-blue,
h1.text-blue,
h2.text-blue,
h3.text-blue,
h4.text-blue,
h5.text-blue,
h6.text-blue {
    color: $color-brand-blue;
}

.accent-text {
    font-size: 1.8rem;
    line-height: 1.6;
    font-weight: 200;
}

.text-highlight {
    color: $color-brand-green-wcag;
    font-weight: bold;
}

.box {
    text-align: center;
    font-size: 1.2rem;
}

.l-h-1-3 {
    line-height: 1.3;
}

.l-h-1-5 {
    line-height: 1.5;
}

.l-h-1-7 {
    line-height: 1.7
}

.l-h-1-9 {
    line-height: 1.9;
}

.primary-color {
    color: $primary-color;
}

.primary-color_background {
    background-color: $primary-color;
}

.primary-color-light {
    color: $primary-color-light;
}

.primary-color-light_background,
.primary-color-light-background {
    background-color: $main-web-lt-blue;
}

.gray-light-background {
    background-color: $color-neutral-gray-07;
}

.primary-color-dark {
    color: $primary-color-dark;
}

.primary-color-dark_background {
    background-color: $primary-color-dark;
}

.secondary-color {
    color: $secondary-color;
}

.secondary-color_background {
    background-color: $secondary-color;
}

.secondary-color-light {
    color: $secondary-color-light;
}

.secondary-color-light_background {
    background-color: $secondary-color-light;
}

.secondary-color-dark {
    color: $secondary-color-dark;
}

.secondary-color-dark_background {
    background-color: $secondary-color-dark;
}

.accent-color {
    color: $accent-color;
}

.accent-color_background {
    background-color: $accent-color;
}

.accent-color-light {
    color: $accent-color-light;
}

.accent-color-light_background {
    background-color: $accent-color-light;
}

.accent-color-dark {
    color: $accent-color-dark;
}

.accent-color-dark_background {
    background-color: $accent-color-dark;
}

.i-am-more-red {
    color: $i-am-more-red;
}

.i-am-more-red_background {
    background-color: $i-am-more-red;
}

.letrs-red_background {
    background-color: $letrs-red;
}

.i-am-more-purple {
    color: $i-am-more-purple;
}

.i-am-more-purple_background {
    background-color: $i-am-more-purple;
}

.vsl-blue-primary {
    color: $vsl-blue-primary;
}

.vsl-blue-secondary {
    color: $vsl-blue-secondary;
}

.vsl-math-green {
    color: $vsl-math-green;
}

.vsl-math-green_background {
    background-color: $vsl-math-green;
}

.vsl-literacy-yellow {
    color: $vsl-literacy-yellow;
}

.vsl-literacy-yellow_background {
    background-color: $vsl-literacy-yellow;
}

.vsl-pd-orange {
    color: $vsl-pd-orange;
}

.vsl-pd-orange_background {
    background-color: $vsl-pd-orange;
}

.vsl-blue-dark {
    color: #1b3664;
}

.vsl-blue-dark_background {
    background-color: #1b3664;
}

/**
 * Horizontal rule base styles
 */
hr {
    border: 0;
    height: 1px;
    background: $color-neutral-gray-13;
    margin: 1rem 0;
}

/**
 * Selection styles
 */
::-moz-selection {
    color: $color-text-highlight;
    background: $color-text-highlight-bg; /* Gecko Browsers */
}

::selection {
    color: $color-text-highlight;
    background: $color-text-highlight-bg; /* WebKit/Blink Browsers */
}

/**
 * Code base styles
 */
code,
kbd,
pre,
samp {
    font-family: $font-family-code;
    font-size: 0.75rem;
}

code {
    display: inline-block;
    background: $color-neutral-gray-02;
    border: 1px solid $color-neutral-gray-13;
    padding: .2rem .5rem;
    line-height: 1.2;
    color: $color-brand-pomegranate;
    border-radius: 4px;
    max-width: 100%;
    overflow: scroll;
}

/**
 * Preformatted text base styles
 */
pre {
    background: $color-neutral-gray-02;
    border: 1px solid $color-neutral-gray-13;
    padding: 1rem;
    overflow-x: auto;

    /**
     * Remove border from code within preformatted text block
     */
    code {
        border: 0;
    }
}

/**
 * Code with languages associated with them
 * 1) Override Prism sysles for code blocks with language
 */
code[class*="language-"],
pre[class*="language-"] {
    font-family:monospace !important;
}


.responsive-iframe-container {
    overflow: hidden;
    padding-top: 80%;
    position: relative;
}

.responsive-iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.only-on-desktop {
    @include respond-to(0, $template-width) {
        display: none;
    }
}

.vsl-arrow {
    font-style: normal;
    display: inline-block;
    padding-right: 8px;
}