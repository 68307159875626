.color-block {
    a.btn {
        color: $color-brand-blue;
    }
    .img-background {
        background-image: url("https://www.voyagersopris.com/images/default-source/filler-images/1200x700_k5_students_reading.png");
        background-color: $color-neutral-gray-13;
        height: 615px;
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .color-bg-darkblue,
    .color-bg {
        background-color: $color-brand-blue;
        padding: 40px;

        p {
            max-width: 600px;
        }

        h2 {
            margin-bottom: 20px;
        }

        h2,
        p {
            color: $white;
        }

        .btn-color-block {
            background-color: $white;
            border: transparent;
            color: $color-brand-blue;
        }
    }

}

.reading-theme,
.literacy-theme {
    .color-block .color-bg-darkblue,
    .color-block .color-bg {
        background-color: $color-brand-emerald;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-emerald;
            &:hover,
            &:focus {
                color: $color-brand-emerald-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-emerald, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-emerald-dark;
            }
        }
    }
}
.writing-theme {
    .color-block .color-bg-darkblue,
    .color-block .color-bg {
        background-color: $color-brand-blueberry;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-blueberry;
            &:hover,
            &:focus {
                color: $color-brand-blueberry-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-blueberry, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-blueberry-dark;
            }
        }
    }
}
.math-theme {
    .color-block .color-bg-darkblue,
    .color-block .color-bg {
        background-color: $color-brand-pomegranate;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-pomegranate;
            &:hover,
            &:focus {
                color: $color-brand-pomegranate-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-pomegranate, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-pomegranate-dark;
            }
        }
    }
}
.assessment-theme {
    .color-block .color-bg-darkblue,
    .color-block .color-bg {
        background-color: $color-brand-blue-sky;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-blue-sky;
            &:hover,
            &:focus {
                color: $color-brand-blue-sky-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-blue-sky, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-blue-sky-dark;
            }
        }
    }
}
.cs-theme {
    .color-block .color-bg-darkblue,
    .color-block .color-bg {
        background-color: $color-brand-tangerine;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-tangerine;
            &:hover,
            &:focus {
                color: $color-brand-tangerine-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-tangerine, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-tangerine-dark;
            }
        }
    }
}
.neutral-theme {
    .color-block .color-bg-darkblue,
    .color-block .color-bg {
        background-color:  $color-neutral-gray-60;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color:  $color-neutral-gray-60;
            &:hover,
            &:focus {
                color:  $color-neutral-gray-93;
                box-shadow: inset 0 -3.25em 0 0 rgba( $color-neutral-gray-60, 0.15);
            }
            &:active,
            &.active {
                background-color:  $color-neutral-gray-93;
            }
        }
    }
}
.default-theme {
    .color-block .color-bg-darkblue,
    .color-block .color-bg {
        background-color: $color-brand-blue;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-blue;
            &:hover,
            &:focus {
                color: $color-brand-blue-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-blue, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-blue-dark;
            }
        }
    }
}