﻿$light-gray: #F8F8F8;

$filter-dropdown-border: 1px solid $color-neutral-gray-13;
$filter-dropdown-border-radius: 0;
$filter-dropdown-max-height: 25vh;

$disabled-opacity: 0.4;

.grey-box {
    background-color: $main-web-lt-blue;
    p {
        font-size: 1rem;
    }
}

.featured-resources {
    border-top: 0 none;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;

    .featured-resources-label {
        text-transform: uppercase;
        position: relative;
        color: $color-brand-font;
        font-size: 1rem;
        font-weight: bold;
        display: block;
        padding-right: 0;
        background-color: transparent;
        top: 0;
        margin-bottom: 1.5rem;
    }
}

.featured-resource-list {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;

    .featured-resource {
        width: 30%;
        background-size: cover;
        background-position: 50%;
        position: relative;
        color: #fff;
        border-radius: 0;

        .featured-resource-tags {
            margin-bottom: 20px;
            color: $white;
            font-size: 0.75rem;
            font-weight: 200;
        }

        a {
            color: #fff;
            z-index: 2;
        }

        h3 {
            color: #fff;
            z-index: 2;
            height: 110px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.1;
            margin-bottom: 20px;
            font-size: 1.25rem;
        }

        .color-layer {
            width: 100%;
            height: 100%;
            border-radius: 0;
            z-index: 1;
            padding: 30px 20px;
        }

        a.link-type {
            text-decoration: none;
        }

        &:nth-of-type(1) .color-layer {
            background: rgba(59,165,226, .80);
        }

        &:nth-of-type(2) .color-layer {
            background: rgba(43,97,144, .80);
        }

        &:nth-of-type(3) .color-layer {
            background: rgba(117,156,184, .80);
        }
    }
}

.resource-cta {
    display: flex;
    padding: 50px 0;
    flex-flow: row wrap;

@mixin resource-filter-dropdown-transition() {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.5s ease-in-out;
}

@mixin resource-filter-open-dropdown() {
    transform: scaleY(1);
}

.top-level-filter-wrapper {
    background-color: $light-gray;
}
    .cta-image {
        margin-right: 50px;
    }

    .cta-content {
        flex: 1;

        i {
            margin-left: 30px;
        }

        .fas {
            font-family: FontAwesome;
            font-style: normal
        }

        a:hover {
            text-decoration: none;
            cursor: pointer;
        }

        a:hover h3 {
            color: #000;
        }

        h3 {
            font-weight: bold;
            color: #2963AF;
        }

        p {
            margin-top: 10px;
            font-size: 13px;
            color: #a7a7a7;
            width: 85%;
            line-height: 1.35;
        }
    }
}

.site-footer .col-md-6:nth-of-type(1) .cta-content .caption {
    border-right: 1px solid #9a9a9a;

    @media (max-width: 991px) {
        border-right: none;
    }
}

.site-footer .col-md-6:nth-of-type(2) .cta-image {
    padding-left: 50px;

    @media (max-width: 991px) {
        padding-left: 0;
    }
}

@mixin resource-filter-dropdown-transition() {
    transform: scaleY(0);
    transform-origin: top;
    visibility: hidden;
    transition: visibility 0s 0.5s, transform 0.5s 0s ease-in-out;
}

@mixin resource-filter-open-dropdown() {
    transform: scaleY(1);
    visibility: visible;
    transition: visibility 0s 0s, transform 0.5s 0s ease-in-out;
}

.top-level-filter {
    background: white;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    position: relative;

    .item {
        cursor: pointer;
        white-space: nowrap;
        padding: 10px 30px;
        color: gray;
        font-size: 1rem;
        font-family: $font-family-header;

        &:hover {
            color: $voyager-dark-blue;
        }

        &.active {
            border-bottom: 3px solid $voyager-light-blue;
            color: $voyager-light-blue;
        }

        button {
            border: none;
            background: white;
            transition: all .25s ease-in-out;
            border-radius: 0;

            &:hover {
                background: gray;
                color: white;
                transition: all .25s ease-in-out
            }
        }
    }
}

.arrow-top-level-filter-wrapper {
    position: relative;
    background-color: white;

    .prev-arrow, .next-arrow {
        position: absolute;
        transition: opacity .25s ease-in-out;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        border-radius: 50%;
        color: $voyager-light-blue;
        background-color: white;
        font-weight: bold;

        &:hover {
            background: $voyager-dark-blue;
            color: white;
        }

        &:focus {
            outline: 0;
        }
    }

    .prev-arrow {
        left: 5px;
    }

    .next-arrow {
        right: 5px;
    }
}

.slick-slide {
    outline: none;
}

.resources-multi-select-filters {
    background: white;
    box-shadow: none !important;
    padding: 20px;

    .filter-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:before, &:after {
            content: none; //So flexbox doesn't pick up the pseudo-classes
        }
    }

    .tag-search {
        color: black;
    }

    .demo-section {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .filter-dropdown {
            margin-right: 20px;
        }
    }
}

.resource-card-filter-button {
    background: $voyager-light-blue;
    color: white; //ensure correct white
    border: none;
    border-radius: 0;
    padding: 5px 20px;
    font-family: $font-family-header;
    font-size: 1rem;
    margin: 0 auto;
}


.filter-dropdown {
    .filter-content {
        list-style-type: none;
        padding: 0;
        position: absolute;
        z-index: 9999;
        background: white;
        display: flex;
        flex-direction: column;
        border-radius: $filter-dropdown-border-radius;
        border: $filter-dropdown-border;
        padding: 15px;
        margin-top: 5px;
        max-height: $filter-dropdown-max-height;
        overflow: auto;
        @include resource-filter-dropdown-transition();

        &.clicked {
            transform: scaleX(1);
            @include resource-filter-open-dropdown();
        }

        li {
            padding-top: 5px;
        }

        li:first-child {
            padding-top: 0;
        }

        label > span, label > input {
            height: 20px;
            width: 20px;
            min-width: 20px;
            margin-right: 10px;
        }

        label {
            color: black;
            font-weight: normal;
            display: flex;
            align-items: center;
            cursor: pointer;

            > input {
                display: none;
            }

            > span {
                border: 1px solid grey;
                display: inline-block;
                position: relative;
                border-radius: $filter-dropdown-border-radius;
            }

            > [type=checkbox]:checked + span:before {
                content: '\2714';
                color: $voyager-light-blue;
                font-size: 20px;
                position: absolute;
                top: -1px;
            }
        }
    }

    .dropdown-button {
        color: $voyager-light-blue;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: $font-family-header;
        font-size: 1rem;

        &:after {
            content: ' ';
            background-image: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-down" class="svg-inline--fa fa-sort-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{url-color-with-hash($voyager-light-blue)}" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>');
            background-repeat: no-repeat;
            background-position: 1px -2px;
            border-radius: 50%;
            border: $filter-dropdown-border;
            margin-left: 5px;
            background-size: 21px 20px;
            width: 25px;
            height: 25px;
            display: block;
        }

        &.clicked {
            &:after {
                content: ' ';
                background: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-up" class="svg-inline--fa fa-sort-up fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="white" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>');
                background-repeat: no-repeat;
                background-position: 1px 6px;
                background-size: 21px 20px;
                background-color: rgb(117, 156, 184);
            }
        }

        &.empty {
            opacity: $disabled-opacity;
            pointer-events: none;
        }
    }
}

.tag-search {
    min-width: 30%;
    position: relative;

    label {
        width: 100%;
        position: relative;
        margin-bottom: 0;

        &:before {
            content: ' ';
            color: $voyager-light-blue;
            background: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{url-color-with-hash($voyager-light-blue)}" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>') center / contain no-repeat;
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            width: 20px;
        }

        input {
            width: 100%;
            padding: 10px 35px;
            border: none;
            border: $filter-dropdown-border;
            border-radius: $filter-dropdown-border-radius;
            font-weight: normal;
            font-size: 1rem;
            font-family: $font-family-header;
        }
    }

    ul {
        position: absolute;
        list-style-type: none;
        padding: 0;
        z-index: 9999;
        background: white;
        max-height: $filter-dropdown-max-height;
        overflow: auto;
        padding: 15px;
        border-radius: 0 0 $filter-dropdown-border-radius $filter-dropdown-border-radius;
        border: $filter-dropdown-border;
        border-top: none;
        width: 100%;
        @include resource-filter-dropdown-transition();

        &.show {
            display: block;
            @include resource-filter-open-dropdown();
        }

        li {
            height: 0;
            display: none;
            cursor: pointer;

            &.show {
                display: block;
                padding: 5px;
                height: 100%;
            }
        }
    }
}

#resource-filter-pills {
    list-style-type: none;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0;

    li {
        display: flex;
        align-items: center;
        margin: 5px;
        padding: 7px 10px;
        border-radius: 20px;
        background-color: $light-gray;
        cursor: pointer;

        &:not(.clear-all-pill):after {
            content: ' ';
            background: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg>');
            background-repeat: no-repeat;
            background-size: 15px;
            width: 15px;
            height: 15px;
            display: inline-block;
            margin-left: 5px;
        }

        &.clear-all-pill {
            display: none;
            background: none;
            cursor: unset;

            a {
                text-decoration: underline;
                color: $voyager-light-blue;
            }

            &.show {
                display: block;
            }
        }
    }
}

.responsive-filter-button {
    display: none;
}

@media (max-width: 767px) {
    .featured-resource-list .featured-resource{
        width:100%;
        margin-bottom:30px;
    }

    .responsive-filter-button-container {
        display: flex;
        justify-content: flex-end;

        .responsive-filter-button {
            display: flex;
            align-items: center;
            border-radius: 0;
            border: $voyager-light-blue 1px solid;
            background-color: white;
            color: $voyager-light-blue;
            padding: 5px 15px;
            font-size: 1rem;
            transition: background-color 0.5s ease-in-out;

            &:before {
                content: ' ';
                background-image: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="filter" class="svg-inline--fa fa-filter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{url-color-with-hash($voyager-light-blue)}" d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"></path></svg>');
                background-repeat: no-repeat;
                background-size: 15px;
                width: 15px;
                height: 15px;
                display: inline-block;
                margin-right: 5px;
            }

            &.clicked {
                background-color: #B5D7F4;
            }
        }
    }

    .low-level-filters {
        .padding-container {
            padding: 20px;
        }

        .resources-multi-select-filters, .container {
            padding: 0;
        }

        .pill-filter-wrapper {
            position: absolute;
            left: 0;
            border-bottom: 2px solid gray;
            z-index: 9999;
            background: white;
            @include resource-filter-dropdown-transition();

            &.open {
                @include resource-filter-open-dropdown();
            }

            .filter-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;


                #cap-view, .filter-content, .tag-search {
                    width: 100%;
                }

                #cap-view {
                    display: flex;
                    flex-direction: column;
                }

                .filter-dropdown, .filter-button-container, .tag-search {
                    margin: 10px 0;
                }

                .filter-dropdown {
                    position: relative;
                    margin-right: 0;
                }

                .dropdown-button {
                    display: flex;
                    justify-content: space-between;
                }

                .tag-search:before {
                    content: 'Tags';
                    display: block;
                    font-family: $font-family-header;
                    font-size: 1rem;
                    color: $voyager-light-blue;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

.pages {
    padding: 0;

    .arrow {
        content: ' ';
        padding: 8px 10px;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-color: $light-gray;
        margin-left: 5px;
        background-size: 20px 20px;
        display: inline;
        border: none;
        color: grey;

        &.disabled {
            opacity: $disabled-opacity;
            pointer-events: none;
        }

        &:hover, &:active, &:focus {
            background-color: $voyager-dark-blue;
        }

        &:hover, &:focus {
            color: white;

            &.disabled {
                background-color: $light-gray;
                color: gray;
            }
        }
    }

    .page-item {
        border: none;
        color: grey;

        &.current-page {
            color: $voyager-dark-blue;
            font-weight: 600;
        }

        &:hover, &:active, &:focus {
            background: none !important;
        }
    }
}

