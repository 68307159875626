﻿.vsl-podcasts-list{
    list-style: none;
    margin: 0;
    padding: 0;
    ul {
        list-style: disc;
    }
}

li.vsl-podcast {
    padding-top: 3rem;
    margin-bottom: 3rem;
    border-top: 1px solid $color-neutral-gray-13;
    &:first-child {
        padding-top: 0;
        border-top: 0 none;
    }
}

.podcast-status {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;
    .badge {
        position: relative;
        top:7px;
    }
}

.podcast-container {}

.podcast-title,
h1.podcast-title,
h3.podcast-title {
    font-size: 1.8rem;
    font-style: normal;
    line-height: 1.3;
    text-transform: none;
    margin-bottom: 2rem;
}

.podcast-date,
h2.podcast-date,
h4.podcast-date {
    font-size: 1rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.3;
    text-transform: none;
    margin-bottom: 1rem;
}

.podcast-button {
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.podcast-presenter-image {
    text-align: center;
    margin-bottom: 1rem;
    img {
        max-width: 160px;
    }
}

.podcast-presenter-name {
    text-align: center;
    font-weight: 700;
    margin-bottom:0.4rem;
}

.podcast-presenter-title {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
}

.podcast-presenter-bio {
    margin-bottom: 1.5rem;
}

.podcast-embed  {
    margin-bottom: 1.5rem;
}

.podcast-content {

}

.podcast-details {

}

.podcast-transcript {
    border-top: 1px solid $color-neutral-gray-13;
    padding-top: 40px;
    .h3 {
        margin-bottom: 40px;
    }
}
