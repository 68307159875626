﻿#toc {
    position: relative;
    list-style: none;
    padding-left: 0.5rem;
    margin: 0 0 40px;
    font-size: 1rem;
    //Hides the H1
    > li:first-child > a {
        display: none;
    }
    li {
        position: relative;
        margin-bottom: 0;
        a {
            display: block;
            margin-bottom: 0.5rem;
        }
    }
    ul {
        list-style: none;
        padding-left: 1.75rem;
        li {
            &::before {
                position: absolute;
                left: -1.65em;
                display: block;
                content: '\f33b';
                font-family: "Font Awesome 6 Pro";
                color: $color-neutral-gray-27;
                font-size: 0.875rem;
                font-weight: normal;

            }
        }
    }
    li > ul {
        //font-weight: bold;
        font-size: 0.875rem;
        li::before {
            // content: '\e257'; // 2
            font-weight: bold;
            content: '\f33b';
        }
        li > ul {
            //font-weight: normal;
            font-size: 0.8125rem;
            li::before {
                font-weight: normal;
                // content: '\e0f0'; // 3
            }
            li > ul {
                li::before {
                   // content: '\e0f1'; // 4
                }
                li > ul {
                    li::before {
                        // content: '\e0f2'; // 5
                    }
                    li > ul {
                        li::before {
                           // content: '\e0f3'; // 6
                        }
                    }
                }
            }
        }
    }
}