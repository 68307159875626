﻿.table-layout {
    @include respond-to($custom-m-2) {
        // display: table;
        // vertical-align: middle;
        // height: 400px;
        position: relative;
        height: 200px;
        .table-container {
            // display: table-cell;
            // vertical-align: middle;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.vsl-flex-container {
    display: flex;
}

.vsl-grid-container {
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    @include respond-to($tweak-s-1) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to($custom-m-2) {
        grid-template-columns: repeat(3, 1fr);
    }
    .vsl-grid-item {
        position: relative;
    }
}

.vsl-grid-container.vsl-grid-container--pairs {
    @include respond-to($tweak-s-1) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to($custom-m-2) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.vsl-grid-container.vsl-grid-container--fourths {
    @include respond-to($tweak-s-1) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to($custom-m-2) {
        grid-template-columns: repeat(4, 1fr);
    }
}