﻿.row-flex,
.row-flex > div[class*='col-'] {
    display: flex;
    flex: 1 1 auto;
    padding: 0;
}

.row-flex {
    align-content: flex-start;
    flex-wrap: wrap;
    flex: 0;
}

.row-flex > div[class*='col-'],
.container-flex > div[class*='col-'] {
    margin: -.2px;
    /* hack adjust for wrapping */
}
