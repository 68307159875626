﻿/*Navigation Bar Search*/
.search-site {
    overflow: hidden;
    max-width: 400px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    .search-input {
        margin-bottom: 15px;
        float: left;
        width: calc(100% - 40px);

        input {
            width: 100%;
            font-size: 0.8571rem;
            background-color: $color-brand-gradient-blue;
            color: $white;
            padding: 7px 15px;
            border: 0;
            border-radius: 0;
            font-weight: 400;
            border: 1px solid $color-brand-gradient-blue-dark;

            &::-webkit-input-placeholder {
                font-weight: 400;
                color: $white;
                font-size: 0.8571rem;
            }
        }
    }

    .submit {
        float: left;

        button {
            font-size: 1.5rem;
            color: $white;
            font-family: fontAwesome;
            width: 40px;
            padding: 2px 0 2px 15px;
            background: transparent;
            border: 0;
        }
    }
}

.dropdown-menu-search {
    max-width: 400px;
    position:absolute;
    left: calc(50% - 175px);
    padding-bottom: 0 !important; //need to override padding on .dropdown-menu
    padding-top: 15px !important; //need to override padding on .dropdown-menu
}

/*Search Results Standard*/
.vsl-search-results {
    h1 {
        font-size: 1.8rem;
    }
}
.sf-search-results {
    .media {
        margin: 0;
        padding: 1.5rem 0;
        border-bottom: 1px solid $color-neutral-gray-13;
        h3, h3 a, h3 a:last-child  {
            // color: $color-brand-font;
            font-size: 1.25rem;
        }
        p {
            font-size: 0.875rem;
        }
        a:last-child {
            font-size: 0.875rem;
        }
    }
    margin-bottom: 2rem;
}

/*Search Results Custom*/
.search-listing {
    .header {
        text-align: center;
        height: 190px;
        background-color: $main-web-lt-blue;
        position: relative;

        .title {
            font-size: 1.5rem;
            color: $color-brand-font;
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            max-height: 190px;
            overflow: hidden;
        }
        h1 {
            font-size: 2rem;
            margin: 0;
        }
    }

    .search-results {
        padding-top: 50px;
        overflow: hidden;
        background-image: none;
        background-position: center top;
        background-repeat: repeat-x;
        background-size: auto;


        .filter {
            margin-bottom: 25px;

            .k-content {
                box-sizing: border-box;
                background-color: transparent;
                margin: 0;


                label {
                    padding: 16px 10px 16px 0;
                    color: #000;
                    font-size: 1rem;
                    text-align: center;
                    display: block;
                }

                .select-wrapper {
                    padding: 10px;
                    width: 100%;
                    max-width: 275px;
                    margin: 0 auto;

                    select {
                        display: none;
                    }
                }

                .k-dropdown-wrap.k-state-default,
                .k-autocomplete.k-state-default,
                .k-numeric-wrap.k-state-default,
                .k-picker-wrap.k-state-default {
                    border-radius: 0;
                    background-color: #88d0e5;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 0.9286rem;
                    font-family: $font-family-header;
                    padding: 5px 10px;
                    box-shadow: 0 0 5px #333;
                    position: relative;
                }

                .k-dropdown .k-input,
                .k-dropdown .k-state-focused .k-input,
                .k-menu .k-popup,
                .k-multiselect .k-button,
                .k-multiselect .k-button:hover {
                    color: $color-brand-blue;
                    height: auto !important;
                }

                .k-icon {
                    left: -5px;
                }

                .k-icon:before {
                    content: "\f078";
                    font-family: FontAwesome;
                    color: $color-brand-blue;
                }

                .k-button.k-primary {
                    float: none !important;
                    margin-top: 9px !important;
                    margin-left: 10px;
                    margin-bottom: 10px;
                    background-color: #566575;
                    border: 1px solid #fff;
                    color: #fff;
                    border-radius: 0;
                    padding: 6px 25px;
                    font-size: 0.9286rem;
                    text-transform: uppercase;
                    float: left;
                }
            }
        }

        .search-items {
            margin-bottom: 125px;

            .item {
                padding: 40px 0 25px;
                border-bottom: 1px solid $color-neutral-gray-13;

                .title {
                    font-size: 1.7857rem;
                    color: $color-brand-font;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                }

                p {
                    font-size: 1.0714rem;
                }

                .read-more {
                    a {
                        color: $color-brand-blue;
                        font-size: 1rem;
                        font-weight: 700;
                        position: relative;
                        text-decoration: none !important;

                        &:after {
                            display: none;
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: -1px;
                            width: 100%;
                            height: 1px;
                            background-color: $color-neutral-gray-13;
                        }

                        &:hover:after {
                            height: 0;
                        }
                    }
                }
            }

            .no-results {
                padding-top: 40px;
            }
        }
    }
}

@media only screen and (max-width : 767px) {
    .search-site {
        padding-left: 0px;
        padding-right: 0px;
    }

}

@media only screen and (min-width : 768px) {

    .search-listing {
        .header {
            text-align: center;
            height: 190px;
            background-color: $main-web-lt-blue;
            position: relative;

            .title {
                font-size: 1.9286rem;
            }
        }

        .search-results {

            .filter {

                .k-content {
                    overflow: hidden;

                    label {
                        float: left;
                    }

                    .select-wrapper {
                        float: left;
                        width: calc(100% - 115px);
                    }
                }
            }
        }
    }
}


.vsl-pop-search {
    opacity: 0;
}
.search-icon,
.exit-icon {
    color: $white;
}
.vsl-pop-search + .search-label .exit-icon {
    display: none;
}
.vsl-pop-search:checked + .search-label {
    .search-icon {
      display: none;
    }
    .exit-icon {
      display: block;
    }
  }
  .search-section {
    position: absolute;
    opacity: 0;
    z-index: -1;
    transition: 0.5s all ease-in-out;
    &.open {
        opacity: 1;
        z-index: 99;
        transition: 0.5s all ease-in-out;
    }
  }
  .search-section-container  {
    position: relative;
    .search-site .submit {
        margin-top: -2px;
    }
    @include respond-to(0,$break-m) {
        .search-section.open {
            display: none;
            opacity: 0;
        }
        display: none;
    }
  }
  .search-box {
    @include vsl-gradient-nav;
    // width: 100vw;
    // height: 100vh;
    width: 400px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    .search-site .search-input {
        margin-bottom: 0;
    }
  }