﻿.site-footer {
    &-wrapper {
        background: $color-brand-blue;
        background-image: url("https://www.voyagersopris.com/images/default-source/rebrand/triangle-footer9d50ea9c-a1f5-4055-9159-ca6784b14405.jpg?Status=Master&sfvrsn=7018a745_3");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        text-align: left;
        margin: 0;
        padding: 2.5rem 1rem;
        color: $white;
        .site-footer-contact-button p.section-heading,
        .section-heading {
            font-size: 1rem;
            margin: 0 0 1rem 0;
            font-weight: bold;
            color: $white;
            text-transform: uppercase;
        }
        ul {
            font-size: 0.875rem;
            list-style: none;
            padding-left: 0;
            a, a:link {
                color: $white;
            }
        }
        .site-footer-company {

        }
        .site-footer-connect {
            @include respond-to(0,$custom-m-2) {
                // margin-top: 1.5rem;
            }
        }
        .site-footer-contact {
            font-size: 0.875rem;
            margin: 0;
        }
        .site-footer-contact-button {
            p {
                font-size: 0.875rem;
            }
            @include respond-to(0,$custom-m-2) {
                margin-top: 1.5rem;
            }
        }
        .btn-outline {
            font-weight: bold;
            padding: 15px 10px;
            @include respond-to($break-to-m) {
                font-size: 0.875rem;
                width: 100%;
                // width: 45%;
            }
        }
        .btn-outline + .btn-outline {
            margin-left: 0.25rem;
        }
        .site-footer-logo {
            display: block;
            margin-top: 5rem;
            img {
                max-width: 200px;
            }
            @include respond-to(0,$custom-m-2) {
                display: none;
            }
        }
        @include respond-to(0,$break-to-m) {
            text-align: center;
            .btn-outline {
                display: block;
                &:first-child {
                    margin-bottom: 0.5rem;
                }
            }
            .btn-outline + .btn-outline {
                margin-left: 0;
            }
        }
        .site-footer-social {
            li {
                padding: 0;
            }
            .fa-inverse {
                color: $color-brand-blue;
            }
        }
    }
    .cambium-footer {
        text-align:center;
        color:#fff;
        padding:1.5rem 0;
        background-color: $color-brand-cambium;
        * {
            line-height: 40px;
            font-size: 0.75rem;
        }
        .copyright {
            color: $color-neutral-gray-27;
            text-align: left;
            a {
                color: $color-neutral-gray-27;
                margin-left: 1.5rem;
            }
        }
        p {
            margin: 0;
            padding: 0;
        }
        a {
            display: inline-block;
            color: $white;
        }
        .cambium-footer-copy {
            img {
                margin-left: 1.5rem;
            }
        }
        @include respond-to(0,$custom-m-2) {
            .copyright {
                text-align: center;
                a {
                    display: block;
                }
            }
            a.cambium-footer-logo {
                display: block;
            }
            img {
                margin: 0.5rem auto 0;
            }
        }
    }
}