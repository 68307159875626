﻿$inner-banner-height: 400px;

// Hero banner home
.hero-banner-custom-bg-img h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 46px;
    line-height: 60px;
    color: $white;
    margin-bottom: 15px;
}

.blue-overlay {
    @include vsl-gradient-rev;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: $inner-banner-height;
    p {
        margin-bottom: 0;;
    }
}
.hero-banner-custom-bg-img {
    height: $inner-banner-height;
}
.hero-banner-custom-bg-img-cta {
    display: table-cell;
    vertical-align: middle;
    height: $inner-banner-height;
}

.hero-banner-custom-bg-img-cta img {
    width: 80%;
}

.hero-banner-custom-bg-img-cta p {
    color: #ffffff;
}

.btn-default {
    color: $color-brand-blue;
}
