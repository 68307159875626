﻿.vsl-pillars-container {}

.vsl-pillars {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    @include respond-to($tweak-s-1) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to($custom-m-2) {
        grid-template-columns: repeat(4, 1fr);
        .vsl-pillar {
            transform: skewX(-10deg);
            &-text {
                transform: skewX(10deg);
            }
        }
    }
    .vsl-pillar {
        display: grid;
        grid-auto-rows: 1fr;
        position: relative;
        color: $white;
        padding: 2rem 3rem;
        &-text {
            color: $white;
            text-align: center;
            .vsl-pillar-title,
            h1.vsl-pillar-title
            h2.vsl-pillar-title
            h3.vsl-pillar-title
            h4.vsl-pillar-title
            h5.vsl-pillar-title
            h6.vsl-pillar-title {
                font-size: 1rem;
                font-weight: bold;
                color: $white;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
            }
            .vsl-pillar-description {
                font-size: 0.875rem;
                p {
                    font-size: 0.875rem;
                    &:only-child,
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.vsl-pillars-no-slant {
    @include respond-to($tweak-s-1) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to($custom-m-2) {
        grid-template-columns: repeat(3, 1fr);
        .vsl-pillar {
            transform: none;
            &-text {
                transform: none;
            }
        }
    }
}

.vsl-pillar {
    &--blue {
        background-color:  $color-brand-blue;
    }
    &--lullaby-blue {
        background-color:   $color-brand-blue-lullaby;
    }
    &--teal {
        background-color:   $color-brand-teal;
    }
    &--reading,
    &--literacy,
    &--emerald {
        background-color:  $color-brand-emerald;
    }
    &--blueberry,
    &--writing {
        background-color:  $color-brand-blueberry;
    }
    &--math {
        background-color:  $color-brand-pomegranate;
    }
    &--sky-blue,
    &--assessment {
        background-color:  $color-brand-blue-sky;
    }
    &--cs {
        background-color:  $color-brand-tangerine;
    }
}