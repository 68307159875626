.form {
    position: relative;
    &--compact {
        margin-top: 0.5em;
    }
    &--inline {
        margin-top: 1em;
        @include clearfix;
    }
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;

    // Form group is used to group checkboxes and radios
    &.form__group {
        margin-bottom: 1em;
    }
}
.form__set {
    @include clearfix;
    &--50 {
        @include respond-to($tweak-m-1) {
            width: 49%;
        }
    }
}

legend {
    // form group is used to group checkboxes and radios
    .form__group & {
        font-size: 1rem;
        display: block;
        width: 100%;
        margin: 0 0 1rem;
    }
    .form--compact & {
        padding-top: .75em;
    }
}

// Form element labels
.form__label {
    display: block;
    font-weight: bold;
}

.form__label.disabled,
legend.disabled {
    color: $color-neutral-gray-13;
}
    // Form labels inside the compact form variant
.form--compact .form__label,
.form--compact .form__group--inline legend {
    line-height: 2em;
    @include respond-to($break-s, $break-to-m) {
        clear: left;
        float: left;
        min-height: 1.75em;
        padding-right: 0.5em;
        width: 35%;
        padding-top: .375em;
    }
    @include respond-to($tweak-m-1) {
        clear: left;
        float: left;
        min-height: 1.75em;
        padding-right: 0.5em;
        width: 35%;
        padding-top: .375em;
        margin-top: 0;
    }
}
.content__section--aside .form--compact .form__label {
    @include respond-to($break-s, $break-to-m) {
        width: 40%;
    }
    @include respond-to($tweak-m-1) {
        width: 40%;
    }
}
.content__section--aside .form--compact .form__label--wide {
    @include respond-to($break-s, $break-to-m) {
        width: 60%;
    }
    @include respond-to($tweak-m-1) {
        width: 60%;
    }
}

// Remvoe bold weight from individual radio/checkbox labels
// Checkbox/radio groups that are displayed vertically
.form__group .form__label {
    display: block;
    font-weight: normal;
}

.form--compact .form__group .form__label {
    line-height: 1.25em;
    width: auto;
    padding-top: .375em;
}

.form--compact .form__label--textarea {
    line-height: 1.25em;
}

// Checkbox/radio groups that are displayed horizontally
.form__group--inline .form__label {
    display: inline-block;
    padding-right: 2em;
}
.form__group--inline-compact .form__label {
    padding-right: .5em;
}
.form__group--2col .form__label {
    display: inline-block;
    padding-right: 2em;
    width: 49%;
}
.form__group--3col .form__label {
    display: inline-block;
    padding-right: 2em;
    width: 32%;
}

.form__full-group--2col .form__set {
    @include respond-to($break-s) {
        display: inline-block;
        padding-right: .5em;
        width: 49%;
        &:last-child {
            padding-right: 0;
        }
    }
}

.form__label.form__label--is-disabled {
    // color: $color-neutral-gray-13;
}

 .form__optin .form__label {
    font-size: 0.875rem;
 }

// Inline error labels
input + label.form__error,
textarea + label.form__error,
select + label.form__error,
legend ~ label.form__error,
.select + label.form__error,
span.form__error {
    font-size: 0.875rem;
    color: $color-brand-pomegranate;
}
span.form__error {
    float: right;
}
.page--bidders-form span.form__error {
    float: none;
    display: block;
    margin-left: 0;
    padding:.5em 0 1em 0;
}
span.select.form__error {
    float:none;
}
// Inline validation error messages
label.form__error {
    display: block;
    position: absolute;
    right: 0;
    margin-top: -1em;

    .form .content__block &,
    legend ~ & {
        position:relative;
        text-align: right;
    }
    .form__group--2col & {
        display: inline-block;
    }
    .form .content__block .form__input--inline + & {
        position:absolute;
    }
    .form .content__block .form__input--inline-left + &,
    .form .form__input--left + & {
        left: 0;
        text-align: left;
    }
    .form .form__input--left + & {
        margin-top: -.1em;
    }
    .form .content__block .form__input--inline-desktop-left + & {
        @include respond-to($break-m) {
            left: 0;
            text-align: left;
        }
    }
    .form .content__block .form__input--inline-mobile-left + & {
        @include respond-to(0, $break-m) {
            left: 0;
            text-align: left;
        }
    }
    .form--compact & {
        position: static;
        margin: -.5em 0 .5em;
        text-align: right;
    }

    // Errorbox for compact form validation messages
    .form__errorbox & {
        position: static;
        margin:0;
        text-align: left;
    }
    // Fix for Adopt a stop form checkbox validation
    &#AGREEMENT-error {
        left: 0;
        margin-top: -.8em;
        @include respond-to(0, 23.750em) {
            margin-top: -1.5em;
        }
        & + span {
            display: inline-block;
            width: 90%;
            margin-bottom: 1em;
            @include respond-to(0, 23.750em) {
                margin-bottom: 1.5em
            }
        }
    }
}

// The required form marker ie. the red asterisk
.form__required {
    color: transparent;
    font-size: 0px;
    position:absolute;
    &:before {
        content: '*';
        display: inline-block;
        font-size: 18px;
        line-height: 16px;
        padding: .25em 0 0 .125em;
        color: $color-brand-pomegranate;

        .disabled & {
            color: lighten($red, 25);
        }
    }
}


// Normal input boxes
.form__input,
.select {
    line-height: 2.5em;
    min-height:2.5em;
    padding: 0 0.25em 0 0.75em;
    margin-bottom: 1em;
    background: $white;
    border: 1px solid $color-neutral-gray-13;
    border-radius: $global-radius;
    -webkit-appearance: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    @include respond-to(0, $break-to-m) {
        font-size: 16px; // make sure that small screen browsers don't autozoom inputs.
    }

    .form &{
        width: 100%;
    }

    .form--aside.form--compact & {
        border-width: 1px;
    }
    // Variations for Compact form
    .form--compact & {
        line-height: 2em;
        margin-bottom: 0.5em;
        min-height: 2em;

        @include respond-to($break-s, $break-to-m) {
            vertical-align: middle;
            width: 65%;
        }
        @include respond-to($tweak-m-1) {
            vertical-align: middle;
            width: 65%;
        }
    }
    .content__section--aside .form--compact & {
        @include respond-to($break-s, $break-to-m) {
            width: 60%;
        }
        @include respond-to($tweak-m-1) {
            width: 60%;
        }
    }
    .content__section--aside .form--compact .form__label--wide + & {
        @include respond-to($break-s, $break-to-m) {
            width: 40%;
        }
        @include respond-to($tweak-m-1) {
            width: 40%;
        }
    }

    .form--inline & {
        line-height: 2em;
        margin: 0;
        min-height: 2em;
        vertical-align: middle;
        width: 68%;
        float: left;
    }

    // the field error style for form validation
    &.form__error {
        // border-top-left-radius: 0;
        // border-bottom-left-radius: 0;
        // box-shadow: inset 6px 0 0 0 $red;
        border-width: 1px;
        border-color: $red;
    }
}
.select {
    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, $color-neutral-gray-13, $color-neutral-gray-13);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
    &:focus {
        background-image:
          linear-gradient(45deg, gray 50%, transparent 50%),
          linear-gradient(135deg, transparent 50%, gray 50%),
          linear-gradient(to right, $color-neutral-gray-13, $color-neutral-gray-13);
        background-position:
          calc(100% - 15px) 1em,
          calc(100% - 20px) 1em,
          calc(100% - 2.5em) 0.5em;
        background-size:
          5px 5px,
          5px 5px,
          1px 1.5em;
        background-repeat: no-repeat;
        // border-color: $color-neutral-gray-13;
        // outline: 0;
    }

    select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
    }
}
.form .form__input--inline,
.form--compact .form__input--inline,
.content__section--aside .form--compact .form__input--inline {
    display: inline;
    width: auto;
    margin-right: .25em;
}
textarea.form__input,
.form--compact textarea.form__input {
    padding: 0.5em;
    min-height: 6em;
    line-height: 1.2em;
}

// Disabled inputs
input:disabled,
.selectDisabled,
input.disabled,
.select.disabled {
        border-color: $color-neutral-gray-13;
        background-color: $color-neutral-gray-07;
        color: $color-neutral-gray-13;

        .form--aside & {
            border-color: $color-neutral-gray-13;
            background-color: $color-neutral-gray-07;
            color: $color-neutral-gray-13;
        }
}

// Styles for radios and checkboxes
.form [type="radio"],
.form [type="checkbox"],
[type="radio"],
[type="checkbox"] {
    display: inline-block;
    vertical-align: baseline;
    margin-right: 0.5rem;
    width: 1em;
    height: 1em;
}

// Style for fineprint opt-in
.form__optin {
    @include clearfix();
    padding-top: .5em;

    input[type=checkbox] {
        margin-right: 0;
        float: left;
        vertical-align: top;
        width: 10%;
    }
    .form__label {
        margin: 0;
        width: 90%;
        float: left;
        clear: none;
        padding: 0;

        .form--compact & {
            width: 90%;
        }
    }
}

// Alternate style for a terms and condition section
.form__terms {
    border-top: 6px solid $color-neutral-gray-13;
    background-color: $color-neutral-gray-07;
    padding: 1em;
}

// fineprint provided at the bottom of a form
.form__fineprint {
    font-size: 0.875rem;
    padding-top: 2em;
}

// Custom layouts for form submits
input[type="submit"],
button[type="submit"] {
    .form & {
        display: block;
        margin-top: 2em;
        min-width: 35%;
    }

    .form--aside & {
        margin-top: 1em;
        width: 100%;
    }

    .form--compact & {
        margin: 1em auto 0;
        width: 100%;
        @include respond-to($break-s) {
            width: 60%;
        }
    }
    .form--inline & {
        margin: 0;
        min-width: 30%;
        width: 30%;
        float: right;
        line-height: 2em;
        min-height: 2em;
        padding: 0;
    }
}

// Fix for mobile zoom on IOS
main .select-one__select,
main input.select-multi__input {
    font-size: 0.875rem;
    @include respond-to(0, $break-m) {
        font-size: 1rem;
    }
}


.materials-form {
    background-color: $main-web-lt-blue;
    box-shadow: none;
    padding: 1rem 0;

    .form-title {
        font-size: 1.5714rem;
        font-weight: 700;
        color: $color-brand-font;
        display: block;
        padding: 0;

        small {
            display: block;
            font-size: 1.0714rem;
            font-style: italic;
            padding: 5px 0;
        }
    }
}