@use "sass:color";
/*------------------------------------*\
     #COLORS
 \*------------------------------------*/

/**
 * Primary Brand Colors
 * 1) Brand=specific colors
 */
 $color-brand-blue: #005587;
 $color-brand-blue-sky: #0099CC;
 $color-brand-assessment:  $color-brand-blue-sky;
 $color-brand-blue-lullaby: #00BFDC;
 $color-brand-blueberry: #333399;
 $color-brand-writing: $color-brand-blueberry;
 $color-brand-teal: #33CCCC;
 $color-brand-teal-light: #AFDFE3;
 $color-brand-gradient-blue: #0076bb;

 $color-brand-blue-dark: color.scale($color-brand-blue, $lightness: -15%);
 $color-brand-blue-sky-dark: color.scale($color-brand-blue-sky, $lightness: -8%);
 $color-brand-blue-lullaby-dark: color.scale($color-brand-blue-lullaby, $lightness: -8%);
 $color-brand-blueberry-dark: color.scale($color-brand-blueberry, $lightness: -8%);
 $color-brand-teal-dark: color.scale($color-brand-teal, $lightness: -8%);
 $color-brand-teal-light-dark: color.scale($color-brand-teal-light, $lightness: -8%);
 $color-brand-gradient-blue-dark: color.scale($color-brand-gradient-blue, $lightness: -4%);

 $color-brand-blue-light: color.scale($color-brand-blue, $lightness: 57%);
 $color-brand-blue-sky-light: color.scale($color-brand-blue-sky, $lightness: 57%);
 $color-brand-blue-lullaby-light: color.scale($color-brand-blue-lullaby, $lightness: 8%);
 $color-brand-blueberry-light: color.scale($color-brand-blueberry, $lightness: 57%);
 $color-brand-teal-light: color.scale($color-brand-teal, $lightness: 8%);
 $color-brand-teal-light-light: color.scale($color-brand-teal-light, $lightness: 8%);
 $color-brand-gradient-blue-light: color.scale($color-brand-gradient-blue, $lightness: 4%);

 $color-brand-cambium: #2b2a2a;

 /**
* Secondary Brand Colors
* 1) Brand=specific colors
*/
$color-brand-lime: #CDDE00;
$color-brand-emerald: #00956E;
$color-brand-reading: $color-brand-emerald;
$color-brand-green: #76BC21;
$color-brand-green-wcag: #67bd4b;
$color-brand-pomegranate: #D52158;
$color-brand-math: $color-brand-pomegranate;
$color-brand-tangerine: #FF9015;
$color-brand-cs: $color-brand-tangerine;

$color-brand-lime-dark: darken($color-brand-lime, 8%);
$color-brand-emerald-dark: darken($color-brand-emerald, 8%);
$color-brand-green-dark: darken($color-brand-green, 8%);
$color-brand-green-wcag-dark: darken($color-brand-green-wcag, 8%);
$color-brand-pomegranate-dark: darken($color-brand-pomegranate, 8%);
$color-brand-tangerine-dark: darken($color-brand-tangerine, 8%);

$color-brand-lime-light: lighten($color-brand-lime, 8%);
$color-brand-emerald-light: #eef7ea;
$color-brand-green-light: lighten($color-brand-green, 8%);
$color-brand-green-wcag-light: lighten($color-brand-green-wcag, 8%);
$color-brand-pomegranate-light: #fff4f6;
$color-brand-tangerine-light: #fef8f6;


 /**
  * Neutral Colors
  * 1) Neutral colors are grayscale values used throughout the UI
  */
 $white: #fff;
 $color-neutral-white: #fff;
 $color-neutral-gray-02: #f9f9f9;
 $color-neutral-gray-07: #eee;
 $color-neutral-gray-13: #ddd;
 $color-neutral-gray-27: #bbb;
 $color-neutral-gray-50: #808080;
 $color-neutral-gray-60: #666;
 $color-neutral-gray-73: #444;
 $color-neutral-gray-93: #131313;
 $color-neutral-black: #000;
 $color-brand-font: $color-neutral-gray-73;

 /**
  * Utility Colors
  * 1) Utility colors are colors used to provide feedback, such as alert messages,
  *    form validation, etc.
  */
 $color-utility-info: $color-brand-blue;
 $color-utility-info-light:  $color-brand-blue-light;
 $color-utility-error:  $color-brand-pomegranate;
 $color-utility-error-light: $color-brand-pomegranate-light;
 $color-utility-success:  $color-brand-emerald;
 $color-utility-success-light: $color-brand-emerald-light;
 $color-utility-warning:  $color-brand-tangerine;
 $color-utility-warning-light: $color-brand-tangerine-light;

 $color-brand-orange:$color-brand-tangerine;


 /**
 * Highlight colors
 */
$color-text-highlight: $color-neutral-gray-93;
$color-text-highlight-bg: $color-neutral-gray-13;

/**
 * Old Brand Colors
 * 1) Brand=specific colors
 */
// $blue: #005587;
// $lt-blue:#009bdf;
// $gray: #4a4f55;
// $red: #ac0b3d;
// $yellow: #ffbf3d;
// $orange: #ff6d10;
// $white: #ffffff;
// $lt-gray: #E6E7E9;
// $dk-gray: #222;

$blue: $color-brand-blue;
$lt-blue: $color-brand-blue-sky;
$gray: $color-brand-font;
$red: $color-brand-pomegranate;
$yellow: $color-brand-lime;
$orange: $color-brand-tangerine;
$white: #ffffff;
$lt-gray: $color-neutral-gray-07;
$dk-gray: $color-neutral-gray-93;

/**
 * Acadience Colors
 * 1) Brand=specific colors
 */
$acadience-purple: #572c81;
$acadience-lt-purple: #9078b7;
$acadience-green: #93d500;
$acadience-dk-green: #487629;
$acadience-lt-bg: #e0dae2;
$acadience-gray: #51534a;

$main-web-blue: #004c7f;
$main-web-navy-blue: #1c5da6;
$main-web-lt-blue: #eef1f8;

/* Templating */
$template-width: 1140px;
$template-gutter: 30px;
$template-gutter-sm: 5px;
$base-padding: 4rem;
$base-mobile-padding: 2rem;
$base-icon-size: 25px;
$global-radius: 8px;
$global-radius-lg: 30px;
$global_transition: .5s;

///.primary-color
$primary-color: $color-brand-blue;

///.primary-color-light
$primary-color-light: lighten($primary-color, 20%);

///.primary-color-dark
$primary-color-dark: darken($primary-color, 10%);

///.secondary-color
$secondary-color: $color-brand-blue;

///.secondary-color-light
$secondary-color-light: lighten($secondary-color, 20%);

///.secondary-color-dark
$secondary-color-dark: darken($secondary-color, 10%);

///.accent-color
$accent-color: #4a4f55;

///.accent-color-light
$accent-color-light: lighten($accent-color, 20%);

///.accent-color-dark
$accent-color-dark: darken($accent-color, 10%);

// vsl colors

// .vsl-blue-primary
$vsl-blue-primary: $primary-color;

// .vsl-blue-secondary
$vsl-blue-secondary: $secondary-color;

$vsl-blue-dark: #1b3664;

// .i-am-more-red
$i-am-more-red: #991b1e;

$letrs-red: #991b1e;


// .i-am-more-purple
$i-am-more-purple: #351c55;

// .vsl-math-green
$vsl-math-green: #76bd22;
$vsl-math-green_hover-background-color: darken($vsl-math-green, 20%);

// .vsl-literacy-yellow
$vsl-literacy-yellow: #ffbf3d;
$vsl-literacy-yellow_hover-background-color: darken($vsl-literacy-yellow, 20%);

// .vsl-pd-orange
$vsl-pd-orange: #ff6d10;
$vsl-pd-orange_hover-background-color: darken($vsl-pd-orange, 20%);

///:root
$root_font-size: 16px;

// $font-family-body: 'Lato', avenir next, avenir, helvetica neue, helvetica, arial, sans-serif;
$font-family-header: 'Montserrat', avenir next, avenir, helvetica neue, helvetica, arial, sans-serif;
$font-family-code: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
// $font-family-primary: $font-family-body;
// $font-family-secondary: $font-family-body;


///body, .body-text
$body_color:  $color-brand-font;
$body_font-family: $font-family-header;
$body_font-size: 1rem;
$body_font-weight: normal;
$body_transition: $global_transition;

///.container
$container_margin: 0 auto;
$container_max-width: 1140px;
$container_padding-left: 15px;
$container_padding-right: 15px;
$container_width: 100%;

///.accent-font
$accent_font-family: $font-family-header;
$accent_font-style: normal;
$accent_font-weight: normal;
$accent_font-size: 1rem;
$accent_text-transform: none;


///img
$img_max-width: 100%;

//ul
$ul_list-style: inherit;
/*$ul_padding: inherit; <- This was causing nested uls to lose padding*/

$li_line-height:1.3rem;
$li_margin-bottom: .5rem;

///p, .p, article, .article
$p_font-size: 1rem;
$p_line-height: 1.5rem;
$p_margin-bottom:$p_line-height;

//.em
$em_font-style: italic;

///a
$a_color: $primary-color;
$a_text-decoration: none;

///a:hover, a:focus
$a-hover_color: $secondary-color;
$a-hover_text-decoration: underline;

///h1, .h1
$h1_font-family: $accent_font-family;
$h1_font-weight: 700;
$h1_line-height: 1.3;
$h1_color: $color-brand-font;
$h1_margin-top: 0;
$h1_margin-bottom: 1rem;
$h1_font-size: 2.6rem;
$h1_font-style: normal;
$h1_transition: .5s;

///h1 small, h1 .small, .h1 small, .h1 .small
$h1-small_font-size: 90%;
$h1-small_font-weight: normal;
$h1-small_line-height: 1;
$h1-small_color: $color-brand-font;

///h2, .h2
$h2_font-family: $accent_font-family;
$h2_font-weight: bold;
$h2_line-height: 1.3;
$h2_color: $color-brand-font;
$h2_margin-top: 0;
$h2_margin-bottom: 1rem;
$h2_font-size: 2rem;
$h2_font-style: normal;
$h2_transition: .5s;


///h2 small, h2 .small, .h2 small, .h2 .small
$h2-small_font-size: 90%;
$h2-small_font-weight: normal;
$h2-small_line-height: 1;
$h2-small_color: $color-brand-font;

///h3, .h3
$h3_font-family: $accent_font-family;
$h3_font-weight: bold;
$h3_line-height: 1.3;
$h3_color: $color-brand-font;
$h3_margin-top: 0;
$h3_margin-bottom: 1rem;
$h3_font-size: 1.8rem;
$h3_font-style: normal;
$h3_transition: .5s;
$h3_text-transform:none;



///h3 small, h3 .small, .h3 small, .h3 .small
$h3-small_font-size: 90%;
$h3-small_font-weight: normal;
$h3-small_line-height: 1;
$h3-small_color: $color-brand-font;

///h4, .h4
$h4_font-family: $accent_font-family;
$h4_font-weight: bold;
$h4_line-height: 1.3;
$h4_color: $color-brand-font;
$h4_margin-top: 0;
$h4_margin-bottom: 1rem;
$h4_font-size: 1.45rem;
$h4_font-style: normal;
$h4_transition: .5s;



///h4 small, h4 .small, .h4 small, .h4 .small
$h4-small_font-size: 90%;
$h4-small_font-weight: normal;
$h4-small_line-height: 1;
$h4-small_color: $color-brand-font;

///h5, .h5
$h5_font-family: $accent_font-family;
$h5_font-weight: bold;
$h5_line-height: 1.3;
$h5_color: $color-brand-font;
$h5_margin-top: 0;
$h5_margin-bottom: 1rem;
$h5_font-size: 1.2rem;
$h5_font-style: normal;
$h5_transition: .5s;



///h5 small, h5 .small, .h5 small, .h5 .small
$h5-small_font-size: 90%;
$h5-small_font-weight: normal;
$h5-small_line-height: 1;
$h5-small_color: $color-brand-font;

///h6, .h6
$h6_font-family: $accent_font-family;
$h6_font-weight: bold;
$h6_line-height: 1.3;
$h6_color: $color-brand-font;
$h6_margin-top: 0;
$h6_margin-bottom: 1rem;
$h6_font-size: 1rem;
$h6_font-style: normal;
$h6_transition: .5s;



///h6 small, h6 .small, .h6 small, .h6 .small
$h6-small_font-size: 90%;
$h6-small_font-weight: normal;
$h6-small_line-height: 1;
$h6-small_color: $color-brand-font;


///.btn
$btn_display: inline-block;
$btn_margin-bottom: 0;
$btn_font-weight: normal;
$btn_text-align: center;
$btn_vertical-align: middle;
$btn_touch-action: manipulation;
$btn_cursor: pointer;
$btn_background-image: none;
$btn_border: 2px solid #fff;
$btn_white-space: nowrap;
$btn_padding: 15px 20px;
$btn_padding-slanted: 15px 30px;
$btn_font-size: 1.0769rem;
$btn_line-height: 1.061;
$btn_border-radius: 0px;
$btn_user-select: none;
$btn_margin: 10px;
$btn_width: auto;
$btn_max-width: none;
$btn_text-decoration: none;
$btn_transition: .25s;
$btn_text-transform: none;
$btn_font-family: $accent_font-family;
$btn_box-shadow: none;

///.btn:hover,
///.btn:focus, .btn.focus
$btn-hover_color: #333;
$btn-hover_text-decoration: none;

// BUTTON PRIMARY

///.btn-primary
$btn-primary_color: #ffffff;
$btn-primary_background-color: $color-brand-blue;
$btn-primary_border-color: transparent;

///.btn-primary:focus, .btn-primary.focus
$btn-primary-focus_color: #fff;
$btn-primary-focus_background-color: $color-brand-blue;
$btn-primary-focus_border-color: transparent;

///.btn-primary:hover, .btn-primary.hover
$btn-primary-hover_color: #fff;
$btn-primary-hover_background-color: $color-brand-blue;
$btn-primary-hover_border-color: transparent;
$btn-primary-hover_text-decoration: none;


///.btn-primary:active, .btn-primary.active,
///.open > .btn-primary.dropdown-toggle
$btn-primary-active_color: #fff;
$btn-primary-active_background-color: $color-brand-blue;
$btn-primary-active_border-color: $color-brand-blue;


///.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus,
///fieldset[disabled] .btn-primary:hover,
///fieldset[disabled] .btn-primary:focus,
///fieldset[disabled] .btn-primary.focus
$btn-primary-disabled_background-color: #fff;
$btn-primary-disabled_border-color: transparent;

///.btn-primary .badge
$btn-primary-badge_color: #fff;
$btn-primary-badge_background-color: #333;

// BUTTON SECONDARY

///.btn-secondary
$btn-secondary_color: #fff;
$btn-secondary_background-color: transparent;
$btn-secondary_border-color: #fff;

///.btn-secondary:focus, .btn-secondary.focus
$btn-secondary-focus_color: #333;
$btn-secondary-focus_background-color: #e6e6e6;
$btn-secondary-focus_border-color: #8c8c8c;

///.btn-secondary:hover
$btn-secondary-hover_color: #fff;
$btn-secondary-hover_background-color:inherit;
$btn-secondary-hover_border-color:inherit;

///.btn-secondary:active, .btn-secondary.active,
///.open > .btn-secondary.dropdown-toggle
$btn-secondary-active_color:inherit;
$btn-secondary-active_background-color:inherit;
$btn-secondary-active_border-color:inherit;


///.btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled].focus,
///fieldset[disabled] .btn-secondary:hover,
///fieldset[disabled] .btn-secondary:focus,
///fieldset[disabled] .btn-secondary.focus
$btn-secondary-disabled_background-color: #fff;
$btn-secondary-disabled_border-color: #ccc;

///.btn-secondary .badge
$btn-secondary-badge_color: #fff;
$btn-secondary-badge_background-color: #333;

// BUTTON LITERACY

///.btn-literacy
$btn-literacy_color: #ffffff;
$btn-literacy_background-color: #ffbf3d;
$btn-literacy_border-color: transparent;

///.btn-literacy:focus, .btn-literacy.focus
$btn-literacy-focus_color: #fff;
$btn-literacy-focus_background-color: #ffbf3d;
$btn-literacy-focus_border-color: transparent;

///.btn-literacy:hover, .btn-literacy.hover
$btn-literacy-hover_color: #fff;
$btn-literacy-hover_background-color: #ffbf3d;
$btn-literacy-hover_border-color: transparent;
$btn-literacy-hover_text-decoration: none;


///.btn-literacy:active, .btn-literacy.active,
///.open > .btn-literacy.dropdown-toggle
$btn-literacy-active_color: #fff;
$btn-literacy-active_background-color: #ffbf3d;
$btn-literacy-active_border-color: #ffbf3d;

// BUTTON MATH

///.btn-math
$btn-math_color: #ffffff;
$btn-math_background-color: #76bd22;
$btn-math_border-color: transparent;

///.btn-math:focus, .btn-math.focus
$btn-math-focus_color: #fff;
$btn-math-focus_background-color: #76bd22;
$btn-math-focus_border-color: transparent;

///.btn-math:hover, .btn-math.hover
$btn-math-hover_color: #fff;
$btn-math-hover_background-color: #76bd22;
$btn-math-hover_border-color: transparent;
$btn-math-hover_text-decoration: none;


///.btn-math:active, .btn-math.active,
///.open > .btn-math.dropdown-toggle
$btn-math-active_color: #fff;
$btn-math-active_background-color: #76bd22;
$btn-math-active_border-color: #76bd22;

// BUTTON PROFESSIONAL DEVELOPMENT

///.btn-pd
$btn-pd_color: #ffffff;
$btn-pd_background-color: #ff6d10;
$btn-pd_border-color: transparent;

///.btn-pd:focus, .btn-pd.focus
$btn-pd-focus_color: #fff;
$btn-pd-focus_background-color: #ff6d10;
$btn-pd-focus_border-color: transparent;

///.btn-pd:hover, .btn-pd.hover
$btn-pd-hover_color: #fff;
$btn-pd-hover_background-color: #ff6d10;
$btn-pd-hover_border-color: transparent;
$btn-pd-hover_text-decoration: none;


///.btn-pd:active, .btn-pd.active,
///.open > .btn-pd.dropdown-toggle
$btn-pd-active_color: #fff;
$btn-pd-active_background-color: #ff6d10;
$btn-pd-active_border-color: #ff6d10;

$voyager-dark-blue: $color-brand-blue-dark;
$voyager-light-blue: $color-brand-blue-sky;

@function url-color-with-hash($color) {
    @return '%23' + str-slice('#{$color}', 2, -1)
}