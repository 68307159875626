﻿.blog {
    padding-top: 60px;
    overflow: hidden;
    z-index: 0;
    @include respond-to (0, $break-to-m) {
        padding-top: 20px;
    }
    .image-as-background {
        background-position: center top;
        background-repeat: no-repeat;
    }
    .section-name {
        position: relative;
        margin-bottom: 40px;
    }
    /*List & Detail*/
    .blog-post,
    .recent-blog-posts .post {
        .title {
            font-size: 1.25rem;
            background-color: transparent;
            font-weight: 700;
            color: $color-brand-font;
            font-family: $font-family-header;
        }
        .date {
            font-size: 0.875rem;
            font-style: italic;
            color: $color-neutral-gray-50;
            margin-bottom: 1rem;
        }
        .tags {
            height: 3.2143rem;
            overflow: hidden;
            .icon {
                float: left;
                display: inline-block;
                padding: 8px 8px 8px 0;
            }
            ul {
                float: left;
                width: calc(100% - 25px);
                list-style: none;
                padding: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    margin-bottom: 0;
                    a, span {
                        font-size: 0.75rem;
                        display: inline-block;
                        text-decoration: none;
                        color: $color-neutral-gray-50;
                    }
                }
                li:not(:last-child) {
                    a:after, span:after {
                        content: ",";
                    }
                }
            }
        }
    }
    /*List Page 2022/23*/
    .recent-blog-posts {
        overflow: hidden;
        .col-sm-6 {
            max-width: 330px;
            @include respond-to (0, $break-m) {
                margin: 0 auto;
            }
        }
        .post {
            position: relative;
            background-color: $main-web-lt-blue;
            margin-bottom: 30px;
            box-shadow: none;
            .front {
                .date {
                    margin-bottom: 15px;
                }
            }
            .back {
                position: absolute;
                width: 100%;
                background-color: $color-brand-gradient-blue;
                @include vsl-gradient-nav-mobile;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                z-index: -10;
                -moz-transition: .5s;
                -o-transition: .5s;
                -webkit-transition: .5s;
                transition: .5s;
                color: #fff;
                padding: 15px;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                z-index: 10;
                .content {
                    height: calc(100% - 60px);
                    .title {
                        color: #fff;
                    }
                    .text {
                        margin-top: 15px;
                        height: 195px;
                        overflow: hidden;
                        p {
                            font-size: 0.875rem;
                        }
                    }
                }
                .btn {
                    width: 100%;
                }
            }
            &:active .back,
            &:hover .back {
                opacity: 1;
                visibility: visible;
            }
            .image {
                width: 100%;
                background-color: $color-brand-gradient-blue;
                height: 200px;
            }
            .content {
                padding: 25px 10px;
                .title {
                    font-size: 1rem;
                    height: 80px;
                    overflow: hidden;
                    margin-bottom: 5px;
                    color: $color-brand-blue;
                }
                .author {
                    height: 24px;
                    overflow: hidden;
                    margin-bottom: 10px;
                    ul {
                        list-style: none;
                        padding: 0;
                        li {
                            color: $color-brand-blue;
                            a, span {
                                text-decoration: none;
                                font-size: 0.875rem;
                                display: inline-block;
                                &:before {
                                    content: '\f007';
                                    font-family: fontAwesome;
                                    position: relative;
                                    display: none;
                                    margin-right: 5px;
                                    color: $color-brand-font;
                                    padding-right: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /*List Page 23*/
    .vsl-blog-posts {
        display: grid;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            @include respond-to($tweak-s-1) {
                grid-auto-rows: 1fr;
                grid-template-columns: repeat(2, 1fr);
            }
        .col-sm-6 {
            @include respond-to (0, $break-m) {
                margin: 0 auto;
            }
        }
        .post,
        a.post {
            display: grid;
            grid-auto-rows: 1fr;
            position: relative;
            background-color: $color-brand-gradient-blue;
            @include vsl-gradient-nav-mobile;
            color: #fff;
            padding: 30px;
            box-shadow: none;
            transition: all .15s;
            border-radius: $global-radius-lg;
            &:hover,
            &:focus {
                color: #fff;
                text-decoration: none;
                transition: all .15s;
                .overlay {
                    opacity: 0.95;
                }
                .post-container .link-text {
                    margin-right: 20px;
                    .vsl-arrow {
                        padding-right: 3px;
                    }
                }
            }
            .overlay {
                background-color: $color-brand-blue-sky;
                @include vsl-gradient-full-rev;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0.5;
                transition: all .15s;
                border-radius: $global-radius-lg;
            }
            .date {
                margin-bottom: 15px;
            }
            .post-container {
                display: grid;
                position: relative;
                .link-text {
                    margin-top: 15px;
                    align-self: flex-end;
                    margin-right: 15px;
                    text-align: right;
                    font-weight: bold;
                    font-size: 0.925rem;
                    text-transform: uppercase;
                    transition: all .15s;
                    .vsl-arrow {
                        transition: all .15s;
                    }
                }
                .content {
                    .image {
                        margin-bottom: 15px;
                        text-align: center;
                        img {
                            display: inline-block;
                        }
                    }
                    .author {
                        margin-bottom: 15px;
                        ul {
                            list-style: none;
                            padding: 0;
                            li {
                                color: $white;
                                .vsl-arrow {
                                    padding-right: 15px;
                                }
                                a, span {
                                    text-decoration: none;
                                    font-size: 0.875rem;
                                    display: inline-block;
                                    color: $white;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    .title {
                        font-size: 1.25rem;
                        color: $white;
                        font-weight: bold;
                        margin-bottom: 0.5rem;
                        a {
                            color: $white;
                            text-decoration: none;
                        }
                    }
                    .date {
                        font-size: 0.75rem;
                        font-style: italic;
                        color: $white;
                        margin-bottom: 1rem;
                    }
                    .text {
                        margin-top: 15px;
                        p {
                            // font-size: 0.875rem;
                            margin-bottom: 15px;
                        }
                    }
                }
                .tags {
                    width: 100%;
                    font-size: .75rem;
                    .icon {
                        float: left;
                        display: inline-block;
                        padding: 0 8px 0 0;
                    }
                    ul {
                        float: left;
                        width: calc(100% - 25px);
                        list-style: none;
                        padding: 0;
                        margin-bottom: 0;
                        li {
                            display: inline-block;
                            margin-bottom: 0;
                            a, span {
                                font-size: 0.75rem;
                                display: inline-block;
                                text-decoration: none;
                                color: $white;
                            }
                        }
                        li:not(:last-child) {
                            a:after, span:after {
                                content: ",";
                            }
                        }
                    }
                }
            }
        }
    }
    /*Detail Page*/
    .blog-post {
        margin-bottom: 60px;
        .post {
            .title {
                margin-bottom: 30px;
                font-size: 1.9286rem;
                color: $color-brand-font;
            }
            .blog-featured-image-container {
                display: block;
                margin-bottom: 20px;
                .blog-featured-image {
                    height: 300px;
                    background-size: cover;
                    background-position: center;
                    position: relative;
                    border-radius: 8px;
                    .title {
                        position: absolute;
                        margin-bottom: 0;
                        background-color: rgba($white, 0.9);
                        border-left: 8px solid $color-brand-blue-sky;
                        padding: 15px 30px;
                        font-size: 1.5rem;
                        bottom: 30px;
                        left: 30px;
                        right: 30px;
                        @include respond-to ($tweak-d-2, $break-s) {
                            font-size: 1.125rem;
                            bottom: 20px;
                            left: 20px;
                            right: 20px;
                        }
                        @include respond-to (0, $tweak-d-2) {
                            font-size: 1rem;
                            bottom: 10px;
                            left: 10px;
                            right: 10px;
                        }
                    }
                }
            }
            .date p {
                margin: 0;
            }
            .tags {
                color: $color-brand-blue;
            }
            .content {
                line-height: 1.5;
                font-family: $body_font-family;
                font-size: $body_font-size;
                font-weight: $body_font-weight;
                color: $body_color;
                margin-bottom: 40px;
            }
            .image {
                margin: 0 auto 30px;
                float: none;
                text-align: center;
                @include respond-to ($tweak-s-1) {
                    float: right;
                    margin-bottom: 30px;
                    margin-left: 20px;
                }
            }
        }
    }
    /*Search and filters*/
    .search-blog {
        overflow: hidden;
        .search-input {
            margin-bottom: 25px;
            float: left;
            width: calc(100% - 40px);
            input {
                width: 100%;
                font-size: 1rem;
                background-color: $white;
                color: $color-brand-font;
                padding: 7px 15px;
                border: 1px solid $color-neutral-gray-13;
                border-radius: 0;
                box-shadow: none;

                &::-webkit-input-placeholder {
                    font-weight: 400;
                    color: $color-brand-font;
                    font-size: 0.8571rem;
                }
            }
        }
        .submit {
            float: left;
            button {
                font-size: 1.5rem;
                color: $color-brand-blue-sky;
                font-family: fontAwesome;
                width: 40px;
                padding: 2px 0 2px 15px;
                background: transparent;
                border: 0;
            }
        }
    }
    .search-blog.search-seo-blog {
        .search-input input {
            border-radius: 30px;
        }
    }
    .comments {
        display: none;
        .section-name {
            .title {
                a {
                    font-size: 1.3rem;
                    font-weight: 400;
                    margin-bottom: 15px;
                    display: inline-block;
                    text-decoration: none;
                }
            }
        }
        .media-list {
            .comment-item {
                padding: 30px 0;
                border-top: 1px solid $color-neutral-gray-13;
                &:last-child {
                    border-bottom: 1px solid $color-neutral-gray-13;
                }
                .author {
                    font-family: $body_font-family;
                    font-size: $body_font-size;
                    font-weight: $body_font-weight;
                    color: $body_color;
                    &:after {
                        content: "says";
                        position: relative;
                        font-size: 1.0714rem;
                        font-weight: 400;
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
                .date {
                    display: block;
                    font-family: $body_font-family;
                    &:before {
                        content: "Posted On";
                        position: relative;
                        display: inline-block;
                        padding-right: 3px;
                    }
                }
                .message {
                    display: block;
                    font-size: 1.0714rem;
                    line-height: 1.933rem;
                }
            }
        }
        .comment-form {
            margin-bottom: 100px;
            label {
                color: $color-brand-blue;
                font-size: 0.85714rem;
                font-weight: 700;
            }
            input, textarea {
                border-radius: 0;
                border: 1px solid #879aaf;
            }
            textarea {
                height: 190px;
            }
        }
    }
}
.recent-blog-posts .title
{
    line-height: 1.2;
}
.blog .recent-blog-posts .section-name .title:after,
.blog .search-blog .section-name .title:after,
.blog .comments .section-name .title:after,
.blog .authors .section-name .title:after,
.blog .topics .section-name .title:after,
.blog .years .section-name .title:after {
  display:none;
}
.blog.image-as-background {
    background-image: none !important;
}
.blog-related-items {
    font-size: 0.875rem;
}
.blog {
    .quick-answer {

    }
    .product-tiles .product-tile-link {
        align-items: center;
    }
    .product-tiles .product-tile-link .product-image {
        max-width: 125px;
    }
}
.sidebar {
    .h5.widget-header {
        color: $color-neutral-gray-50;
    }
}
.search-seo-blog {

}
