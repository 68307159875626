.vjs-theme-vsl,
.video-js.vjs-theme-vsl {
    .vjs-big-play-button {
        width: 4em;
        height: 79px;
        object-fit: contain;
        background-color: rgba($color-brand-blue-sky, 0.75);
        border: none;
        margin: 0 auto;
        line-height: 79px;
        top: 50%;
        left: 50%;
        border-radius: 0px;
        margin-top: -51.5px;
        margin-left: -2em;
    }
    &:hover {
        .vjs-big-play-button,
        .vjs-big-play-button:focus {
            background-color: rgba($color-brand-blue-sky, 0.25);
            transition: all 0.4s;
        }
    }
    .vjs-control-bar {
        height: 4em;
        background-color: rgba(255, 255, 255, 0.4);
    }
    .vjs-button:hover {
        color: $color-brand-blue-sky;
        background: #fff;
    }
    .vjs-button > .vjs-icon-placeholder::before {
        line-height: 2.2;
    }
    .vjs-time-control {
        line-height: 4em;
    }
    .vjs-volume-panel,
    .vjs-picture-in-picture-control {
        // display: none;
    }
    .vjs-volume-bar {
        .vjs-slider-horizontal {
            height: 4em;
            margin: 0;
            .vjs-volume-level {
                // top:2em;
            }
        }
        margin: 1.85em 1em;
    }
    .vjs-progress-control {
        .vjs-play-progress {
            background-color: rgba($color-brand-blue-sky, 0.9);
            &::before{
                // display: none;
            }
        }
        .vjs-slider {
            background-color: rgba($color-brand-blue-sky, 0.1);
        }
        .vjs-load-progress div {
            background: rgba(255, 255, 255, 0.5);
        }
        .vjs-progress-holder {
            margin: 0;
            height: 100%;
        }
        .vjs-time-tooltip {
            background-color: rgba($color-brand-blue-sky, 0.5);
            color: #fff;
        }
        .vjs-mouse-display .vjs-time-tooltip {
            background-color: rgba(255, 255, 255, 0.7);
            color: $color-brand-blue;
        }
    }
}
.btn-video {
    display: inline-block;
    position: relative;
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: radial-gradient(rgba($white,0.5), $color-brand-blue);
        opacity: .5;
        transition: all .5s;
    }
    &:hover:before{
        opacity: .3;
    }
    &::after {
        content: "\f144";
        position: absolute;
        display: block;
        font-family: "Font Awesome 6 Pro";
        font-size: 50px;
        font-weight: 900;
        line-height: 50px;
        text-align: center;
        text-shadow: 0px 0px 30px $white;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        opacity: .7;
        transition: all .5s;
        color: $color-brand-blue-sky;
    }
    &:hover:after{
        opacity: 1;
        transform: scale(1.5);
    }
}
