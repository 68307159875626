/**
 * Blockquote base styles
 */

 .reading-theme,
 .literacy-theme {
    .blockquote,
    blockquote {
        box-shadow: 10px 10px $color-brand-emerald;
        &--line {
            border-left: 3px solid $color-brand-emerald;
            box-shadow: none;
        }
    }
}
.writing-theme {
    .blockquote,
    blockquote {
        box-shadow: 10px 10px $color-brand-blueberry;
        &--line {
            border-left: 3px solid $color-brand-blueberry;
            box-shadow: none;
        }
    }
}
.math-theme {
    .blockquote,
    blockquote {
        box-shadow: 10px 10px $color-brand-pomegranate;
        &--line {
            border-left: 3px solid $color-brand-pomegranate;
            box-shadow: none;
        }
    }
}
.assessment-theme {
    .blockquote,
    blockquote {
        box-shadow: 10px 10px $color-brand-blue-sky;
        &--line {
            border-left: 3px solid $color-brand-blue-sky;
            box-shadow: none;
        }
    }
}
.cs-theme {
    .blockquote,
    blockquote {
        box-shadow: 10px 10px $color-brand-tangerine;
        &--line {
            border-left: 3px solid $color-brand-tangerine;
            box-shadow: none;
        }
    }
}
 blockquote,
 .blockquote {
    font-size: $p_font-size;
    font-style:italic;
    color: $color-neutral-gray-73;
    padding: 1rem;
    background-color: $main-web-lt-blue;
    transition: $global_transition;
    border-left: 0;
    // box-shadow: 10px 10px $color-brand-blue-sky;
    small {
        font-size: .75rem;
    }
    &--line {
        border-left: 3px solid $color-brand-blue-sky;
        box-shadow: none;
    }
    &--green {
        box-shadow: 10px 10px $color-brand-green;
    }
    &--emerald {
        box-shadow: 10px 10px $color-brand-emerald;
    }
    &--blue {
        box-shadow: 10px 10px $color-brand-blue;
    }
    &--red {
        box-shadow: 10px 10px $color-brand-pomegranate;
    }
    &--purple {
        box-shadow: 10px 10px $color-brand-blueberry;
    }
    &--lt-blue {
        box-shadow: 10px 10px $color-brand-blue-sky;
    }
    &--orange {
        box-shadow: 10px 10px $color-brand-tangerine;
    }
}