@use "sass:math";

@mixin vsl-gradient {
  background-color: $color-brand-blue;
  background: linear-gradient(90deg, rgba($color-brand-blue, .9) 50%, rgba($color-brand-blue-sky, .8) 85%);
}

@mixin vsl-gradient-rev {
  background-color: $color-brand-blue-sky;
  background: linear-gradient(90deg, rgba($color-brand-blue, .9) 0%, rgba($color-brand-blue-sky, .8) 70%);
}

@mixin vsl-gradient-full {
  background-color: $color-brand-blue;
  background: linear-gradient(90deg, $color-brand-blue 0%, $color-brand-blue-sky 50%);
}

@mixin vsl-gradient-full-rev {
  background-color: $color-brand-blue-sky;
  background: linear-gradient(90deg, $color-brand-blue-sky 0%, $color-brand-blue 70%);
}

@mixin vsl-gradient-bg {
    background-color: $color-brand-green;
    background: linear-gradient(90deg, rgba($color-brand-gradient-blue, .9) 50%, rgba($color-brand-green, .5) 85%);
}

@mixin vsl-gradient-gb-full {
  background-color: $color-brand-green;
  background: linear-gradient(90deg, $color-brand-green 0%, $color-brand-gradient-blue 50%);
}

@mixin vsl-gradient-gb-full-rev {
  background-color: $color-brand-gradient-blue;
  background: linear-gradient(90deg, $color-brand-gradient-blue 0%, $color-brand-green 50%);
}

@mixin vsl-gradient-nav-mobile {
  background-color: $color-brand-green;
  background: linear-gradient(85deg, $color-brand-green 0%, $color-brand-gradient-blue 80%);
}

@mixin vsl-gradient-nav {
  background-color: $color-brand-green;
  background: linear-gradient(65deg, $color-brand-green 0%, $color-brand-gradient-blue 50%);
}

@mixin font-size($font-size, $line-height:true) {
  font-size: $font-size;
  font-size: math.div($font-size, $base-font-size)*1rem;
  @if $line-height == true {
    line-height: ceil(math.div($font-size, $base-line-height)) * math.div($base-line-height, $font-size);
  }
}

@mixin respond-to($min-width, $max-width: false) {
  @media all and (min-width: $min-width) {
    @if $max-width {
      @media (max-width: $max-width) {
        @content
      }
    } @else {
      @content;
    }
  }
}

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

@mixin rem($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value*16}px;

    @if $i < $max {
      $pxValues: #{$pxValues + " "};
    }
  }

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + " "};
    }
  }

  #{$property}: $pxValues;
  #{$property}: $remValues;
}

@mixin clearfix {
  *zoom: 1;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}
