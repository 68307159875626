.btn {
    position: relative;
    display: $btn_display;
    margin-bottom: $btn_margin-bottom;
    font-weight: $btn_font-weight;
    text-align: $btn_text-align;
    vertical-align: $btn_vertical-align;
    touch-action: $btn_touch-action;
    cursor: $btn_cursor;
    background-image: $btn_background-image;
    border: $btn_border;
    white-space: $btn_white-space;
    padding: $btn_padding;
    font-family: $btn_font-family;
    font-size: $btn_font-size;
    line-height: $btn_line-height;
    border-radius: $btn_border-radius;
    text-decoration: $btn_text-decoration;
    text-transform: $btn_text-transform;
    width: $btn_width;
    max-width: $btn_max-width;
    transition: $btn_transition;
    -webkit-user-select: $btn_user-select;
    -moz-user-select: $btn_user-select;
    -ms-user-select: $btn_user-select;
    user-select: $btn_user-select;
    box-shadow: $btn_box-shadow;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
    outline: thin dotted;
    outline: 1px auto $color-brand-blue-sky;
    outline-offset: 1px;
    border-radius: 0;
}

.btn:hover,
.btn:focus,
.btn.focus {
    color: $btn-hover_color;
    text-decoration: $btn-hover_text-decoration;
}

.btn:active,
.btn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none;
}

.btn-primary,
.btn-blue {
    color: $btn-primary_color;
    background-color: $btn-primary_background-color;
    border-color: $btn-primary_border-color;
    &:hover,
    &:focus {
        box-shadow: inset 0 -3.25em 0 0 $color-brand-blue-dark;
    }
}

p a.btn,
p a.btn-primary {
    color: $btn-primary_color;
}

.btn-primary:focus,
.btn-primary.focus,
.btn-blue:focus,
.btn-blue.focus {
    color: $btn-primary-focus_color;
    background-color: $btn-primary-focus_background-color;
    border-color: $btn-primary-focus_border-color;
}

.btn-primary:hover,
.btn-primary.hover,
.btn-blue:hover,
.btn-blue.hover {
    color: $btn-primary-hover_color;
    background-color: $btn-primary-hover_background-color;
    border-color: $btn-primary-hover_border-color;
    text-decoration: underline;
}

.btn-primary:active,
.btn-primary.active,
.btn-blue:active,
.btn-blue.active,
.open>.btn-primary.dropdown-toggle {
    color: $btn-primary-active_color;
    background-color: $color-brand-blue-dark;
    border-color: $color-brand-blue-dark;
}

.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open>.btn-primary.dropdown-toggle:hover,
.open>.btn-primary.dropdown-toggle:focus,
.open>.btn-primary.dropdown-toggle.focus {
    color: $btn-primary-active_color;
    background-color: $color-brand-blue-dark;
    border-color: $color-brand-blue-dark;
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    background-color: $color-brand-blue;
    border-color: $color-brand-blue;
}

.btn-primary .badge {
    color: $btn-primary-badge_color;
    background-color: $btn-primary-badge_background-color;
}


p,
.p {
    a.btn-secondary {
        color: $btn-secondary_color;
    }
}

.btn-secondary {
    color: $btn-secondary_color;
    background-color: $btn-secondary_background-color;
    border-color: $btn-secondary_border-color;
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: $btn-secondary-focus_color;
    background-color: $btn-secondary-focus_background-color;
    border-color: $btn-secondary-focus_border-color;
}

.btn-secondary:hover,
.btn-secondary.hover {
    color: $btn-secondary-hover_color;
    background-color: $btn-secondary-hover_background-color;
    border-color: $btn-secondary-hover_border-color;
}

.btn-secondary:active,
.btn-secondary.active,
.open>.btn-secondary.dropdown-toggle {
    color: $btn-secondary-active_color;
    background-color: $btn-secondary-active_background-color;
    border-color: $btn-secondary-active_border-color;
}

.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary:active.focus,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.btn-secondary.active.focus,
.open>.btn-secondary.dropdown-toggle:hover,
.open>.btn-secondary.dropdown-toggle:focus,
.open>.btn-secondary.dropdown-toggle.focus {
    color: $btn-secondary-active_color;
    background-color: $btn-secondary-active_background-color;
    border-color: $btn-secondary-active_border-color;
}

.btn-secondary .badge {
    color: $btn-secondary-badge_color;
    background-color: $btn-secondary-badge_background-color;
}

.btn-default {
    color: $btn-primary_background-color;
    border-color: transparent;
}

.btn-default:hover,
.btn-default:active,
.btn-default:visited {
    background-color: $white;
    border-color: transparent;
}

.btn-learn-more {
    border: 3px solid $body_color;
    color: $body_color;
    padding: 0.8rem 2.5rem;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.btn-literacy,
.btn-literacy:active,
.btn-literacy:visited {
    color: $white;
    background-color: $vsl-literacy-yellow;
    border-color: transparent;
}

.btn-literacy:focus,
.btn-literacy:hover {
    background-color: $vsl-literacy-yellow_hover-background-color;
}

.btn-math,
.btn-math:active,
.btn-math:visited {
    color: $white;
    background-color: $vsl-math-green;
    border-color: transparent;
}

.btn-math:hover,
.btn-math:focus {
    background-color: $vsl-math-green_hover-background-color;
}

.btn-pd,
.btn-pd:active,
.btn-pd:visited {
    color: $white;
    background-color: $vsl-pd-orange;
    border-color: transparent;
}

.btn-orange,
.btn-orange:visited {
    background-color: $color-brand-tangerine;
    &:active,
    &.active {
        background-color: $color-brand-tangerine-dark;
    }
    &:hover,
    &:focus {
        box-shadow: inset 0 -3.25em 0 0 $color-brand-tangerine-dark;
    }
}

.btn-red,
.btn-red:visited {
    background-color: $color-brand-pomegranate;
    &:active,
    &.active {
        background-color: $color-brand-pomegranate-dark;
    }
    &:hover,
    &:focus {
        box-shadow: inset 0 -3.25em 0 0 $color-brand-pomegranate-dark;
    }
}

.btn-purple,
.btn-purple:visited {
    background-color: $color-brand-blueberry;
    &:active,
    &.active {
        background-color: $color-brand-blueberry-dark;
    }
    &:hover,
    &:focus {
        box-shadow: inset 0 -3.25em 0 0 $color-brand-blueberry-dark;
    }
}

.btn-green,
.btn-green:visited {
    background-color: $color-brand-green-wcag;
    &:active,
    &.active {
        background-color: $color-brand-green-wcag-dark;
    }
    &:hover,
    &:focus {
        box-shadow: inset 0 -3.25em 0 0 $color-brand-green-wcag-dark;
    }
}

.btn-emerald,
.btn-emerald:visited {
    background-color: $color-brand-emerald;
    &:active,
    &.active {
        background-color: $color-brand-emerald-dark;
    }
    &:hover,
    &:focus {
        box-shadow: inset 0 -3.25em 0 0 $color-brand-emerald-dark;
    }
}

.btn-lt-blue,
.btn-info,
.btn-lt-blue:visited,
.btn-info:visited {
    background-color: $color-brand-blue-sky;
    &:active,
    &.active {
        background-color: $color-brand-blue;
    }
    &:hover,
    &:focus {
        box-shadow: inset 0 -3.25em 0 0 $color-brand-blue;
    }
}

.btn-primary,
.btn-blue,
.btn-orange,
.btn-red,
.btn-purple,
.btn-green,
.btn-emerald,
.btn-lt-blue,
.btn-info {
    color: $white;
    border-color: transparent;
    &.active {
        border-color: transparent;
    }
    &:hover,
    &:focus {
        color: $white;
        text-decoration: underline;
        border-color: transparent;
    }
    &:hover.disabled,
    &:hover.active {
        box-shadow: none;
    }
}

li {
    a.btn-primary,
    a.btn-blue,
    a.btn-orange,
    a.btn-red,
    a.btn-purple,
    a.btn-green,
    a.btn-emerald,
    a.btn-lt-blue,
    a.btn-info {
        color: $white;
        &:link,
        &:active,
        &:visited {
            color: $white;
        }
    }
}

p a.btn.btn-white,
a.btn-white,
.btn-white {
    background-color: $white;
    color: $color-brand-blue;
    border-color: transparent;
    &:hover,
    &:focus {
        color: $color-brand-blue;
        text-decoration: underline;
        box-shadow: inset 0 -3.25em 0 0 $color-neutral-gray-13;
    }
    &:active,
    &.active {
        background-color: $color-neutral-gray-13;
    }
}

p a.btn.btn-outline,
a.btn-outline,
.btn-outline,
.btn.try-it,
.btn.contact-sales {
    background-color: transparent;
    color: $white;
    border-color: $white;
    &:hover,
    &:focus {
        color: $color-brand-blue;
        box-shadow: inset 0 -3.25em 0 0 $white;
    }
    &:active,
    &.active {
        border-color: $color-neutral-gray-13;
    }
}

.btn-pd:focus,
.btn-pd:hover {
    background-color: $vsl-pd-orange_hover-background-color;
}

.btn-product-intro,
.btn-product-intro:active,
.btn-product-intro:visited {
    border: 2px solid $color-brand-blue;
}

.btn-side-by-side,
.btn-side-by-side:active,
.btn-side-by-side:visited {
    border: 2px solid $color-brand-blue;
    color: $color-brand-blue;
}

a.btn.btn-side-by-side,
a.btn.btn-side-by-side:active,
a.btn.btn-side-by-side:visited {
    color: $color-brand-blue;
}

.btn-text,
a.btn-text,
p a.btn-text {
    color: $color-brand-blue;
    font-weight: bold;
    font-size: 1rem;
    line-height: inherit;
    padding: 0;
    white-space: unset;
    border: 0;
    outline: 0;
    background: none;
    text-align: left;
    @include respond-to(0,$break-m) {
        font-size: 0.875rem;
    }
    &:active,
    &.active,
    &.active:focus {
        box-shadow: none;
        outline: 0;
    }
    &:hover,
    &:focus {
        color: $color-brand-blue;
        outline: 0;
        text-decoration: underline;
    }
    &.t--center,
    &.text-center {
        text-align: center;
    }
}


.btn-slanted {
    transform: skewX(-25deg);
    padding: $btn_padding-slanted;
    transition: all .25s;
    span {
        display: block;
        transform: skewX(25deg);
    }
    @include respond-to(0,$break-m) {
        padding: 10px 20px;
    }
    &:hover,
    &:focus {
        span {
            // transition: all 0.25s;
            // transform: skewX(0deg);
            text-decoration: underline;
        }
        // transform: skewX(0deg);
    }
}

.btn-caps {
    text-transform: uppercase;
    font-weight: bold;
    @include respond-to(0,$break-m) {
        font-size: 0.875rem;
    }
}

.btn-compact {
    padding: 10px 20px;
}

.btn-small,
.btn-sm {
    padding: 5px 8px 4px;
    //font-size: 0.688rem; //11px
    font-size: 0.75rem; //12px
}

.btn.try-it,
.btn.contact-sales {
    span {
        margin-left: 0;
    }
    padding: 5px 8px;
    font-size: 0.75rem; //12px
    box-shadow: none;
}

.btn-full {
    display: block;
    width: 100%;
}

.btn-wide {
    padding-left: 60px;
    padding-right: 60px;
}

.btn-centered {
    display: table;
    margin-left: auto;
    margin-right: auto;
}

@include respond-to(0, $template-width) {
    .btn-stack-mobile {
        white-space: normal;
        line-height: 1.5;
    }
    .btn-mobile-full {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
}

.vsl-slide:hover,
.vsl-slide:focus {
    transition: 0.25s;
    box-shadow: inset 20em 0 0 0 $color-brand-blue;
}
.vsl-fill:hover,
.vsl-fill:focus {
    box-shadow: inset 0 0 0 2em $color-brand-blue;
}

.vsl-up:hover,
.vsl-up:focus {
  box-shadow: inset 0 -3.25em 0 0 $color-brand-blue;
}

.vsl-bubble {
    // background-color: transparent;
    // border: none;
    overflow: hidden;
    box-shadow: none;
    &:hover {
        color: $white;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.3s, border 0.3s;
    }
    &:hover::before {
        opacity: 0;
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 400px;
        height: 200px;
        background-color: lighten($color-brand-blue, 10%);
        border-color: transparent;
        border-radius: 50%;
        transform: translate(-10px, -70px) scale(0.1);
        opacity: 0;
        z-index: -1;
        transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
    }
    &:hover::after {
        opacity: 1;
        transform-origin: 100px 100px;
        transform: scale(1) translate(-10px, -70px);
    }
}

.btn-pill {
    border-radius: 15px;
    &:hover,
    &:focus {
        border-radius: 15px;
    }
    &.btn-small {
        padding: 3px 8px 2px;
        font-size: 0.625rem;
    }
}

.cambium-button {
    border-radius: 10px 0;
    border-width: 1px;
    padding: 10px 15px;
    font-size: 0.75rem;
    border-color: $color-brand-blue-sky;
    background-color: $color-brand-blue-sky;
}
