﻿$contributor-dimensions: 160px;
$contributor-blog-dimensions: 60px;

/*------------------------------------*\
    #Full List View
\*------------------------------------*/
.contributor-list-container {
    .contributor-name {

    }
    .contributor-title,
    .contributor-website {
        text-align: center;
        font-size: 0.75rem;
    }
    .contributor-title {
        font-weight: bold;
    }
    .contributor-website {

    }
    .contributor-bio {

    }
    .contributor-detail {

    }
}

/*------------------------------------*\
    #Compact/Simple List View
\*------------------------------------*/
.contributor-compact-container,
.contributor-simple-container {
    .contributor-photo {
        img {
            @include vsl-gradient-gb-full-rev;
            margin: 0 auto;
            padding: 8px;
        }
    }
    .contributor-name,
    .contributor-title {
        text-align: center;
    }
    .contributor-name {
        font-weight: bold;
        font-size: 0.75rem;
    }
    .contributor-title {
        font-size: 0.688rem;
    }
    .contributor-bio {

    }
    .btn-text {
        font-size: 0.75rem;
    }
}

.contributor-compact-container {
    .contributor-items {
        .contributor-item {
            border-top: 1px solid $color-neutral-gray-13;
            margin-top: 2rem;
            padding-top: 1rem;
            @include respond-to($break-to-m) {
                width: 96%;
            }
            &:first-child,
            &:only-child {
                border-top: 0 none;
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}

/*------------------------------------*\
    #Modal/Featured List View
\*------------------------------------*/
.contributor-featured-container {
    .contributor-header {
        .contributor-heading {
            padding: 2rem 1rem;
            text-align: center;
            h1 {
                font-size: 2rem;
            }
        }
    }
    @include respond-to($tweak-s-2) {
        .contributor-items {
            display: flex;
            flex-flow: row wrap;
            .contributor-modal-container {
                flex: 1 1 0px;
                flex-basis: 50%;
            }
        }
        .contributor-featured-container-padding {
            padding: 0 0.75rem;
        }
    }
    @include respond-to($custom-m-1, $tweak-m-2) {
        .contributor-items {
            .contributor-modal-container {
                flex-basis: 33%;
            }
        }
    }
    @include respond-to($tweak-m-2) {
        .contributor-items {
            .contributor-modal-container {
                flex-basis: 20%;
            }
        }
    }
    .contributor-modal-container {
        .contributor-photo {
            img {
                background: #fff;
                padding: 8px
            }
        }
    }
}
.contributor-modal-container {
    .contributor-photo {
        img {
            @include vsl-gradient-gb-full-rev;
            margin: 0 auto;
            padding: 8px;
        }
    }
    .contributor-name,
    .contributor-title {
        text-align: center;
    }
    .contributor-name {
        font-weight: bold;
    }
    .contributor-title {
        font-size: 0.75rem;
    }
    .contributor-bio {

    }
    .contributor-bio-modal-btn {
        text-align: center;
    }
    .btn-text {
        font-size: 0.875rem;
    }
}
.contributor-bio-modal {
    font-size: 0.875rem;
    line-height: 1.3rem;
    max-width: 500px;
    p {
        font-size: 0.875rem;
        line-height: 1.3rem;
        margin-bottom: 10px;
    }
    .bio-modal-content {
        padding: 1.5rem;
        background: $white;
        .contributor-photo img {
            width: 100px;
            padding: 0;
        }
        .contributor-name {
            font-weight: bold;
            text-align: center;
        }
        .contributor-bio {
            max-height: 200px;
            padding-right: 20px;
            overflow-y: scroll;
            scrollbar-width: thin;
            scrollbar-color: $color-brand-blue-sky $color-neutral-gray-07;
            &::-webkit-scrollbar {
                width: 11px;
            }
            &::-webkit-scrollbar-track {
                background: $color-neutral-gray-07;
                border-radius: 11px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $color-brand-blue-sky;
                border-radius: 6px;
                border: 3px solid $color-neutral-gray-07;
            }
        }
    }
    .bio-modal-btn {
        display: block;
        font-weight: bold;
        font-size: 0.75rem;
        text-align: center;
        width: 100%;
        color: $btn-primary_color;
        background-color: $btn-primary_background-color;
        border-color: $btn-primary_border-color;
        padding: 1rem 1.5rem;
        &:hover,
        &:focus {
         box-shadow: inset 0 -4.25em 0 0 $color-brand-blue-dark;
        }
    }
}
/*------------------------------------*\
    #Detail View
\*------------------------------------*/
.contributor-container {
    .contributor-header {
        padding: 60px 0;
        .contributor-photo {
            img {
                border: 8px solid $white;
            }
        }
        .contributor-center {
            @include respond-to($break-to-m) {
                display: table-cell;
                height: $contributor-dimensions;
                vertical-align: middle;
            }
        }
    }
    .contributor-content {

    }
    .contributor-name {
        font-size: 1.75rem;
        margin-bottom: 0.5rem;
    }
    .contributor-title {
        font-size: 0.875rem;
        font-weight: normal;
        @include respond-to($tweak-s-2, $break-to-m) {
            margin: 0 auto;
            width: 70%;
        }
    }
    .contributor-social {
        &-item {
            font-size: 0.875rem;
            margin-bottom: 0.5rem;
            &:only-child,
            &:last-child {
                margin-bottom: 30px;
            }
            .fa-stack {
                color: $color-brand-green-wcag;
            }
        }
        .contributor-website {

        }
        .contributor-linkedin {

        }
        .contributor-instagram {

        }
        .contributor-twitter {

        }
    }
    .contributor-bio {

    }
    .contributor-detail {

    }
    .contributor-products {

    }
    .contributor-featured {

    }
    .contributor-products,
    .contributor-featured {
        font-size: 0.875rem;
        h3,
        h3.h6 {
            font-size: 0.875rem;
        }
        ul.contributor-list {
            margin-bottom: 0;
            padding: 0;
            list-style: none;
            li {
                .fa-li {
                    color: $color-brand-green-wcag;
                }
            }

        }
    }
}
/*------------------------------------*\
    #Blog View
\*------------------------------------*/
.blog-post,
.vsl-blog-posts {
    .contributor-compact-container {
        .contributor-items {
            .contributor-item {
                border-top: 1px solid $color-neutral-gray-13;
                margin-top: 2rem;
                padding-top: 3.5rem;
                @include respond-to($break-to-m) {
                    width: 98%;
                }
                &:first-child,
                &:only-child {
                    border-top: 0 none;
                    margin-top: 0;
                    padding-top: 0;
                }
                .btn-text {
                    font-size: 0.938rem;
                }
            }
        }
    }
    .contributor-blog-container {
        .contributor-items-header {
            flex-flow: row wrap;
            .contributor-item-header {
                margin-bottom: 10px;
                .contributor-item-header-photo {
                    width: calc($contributor-blog-dimensions + 20px);
                }
                .contributor-item-header-title {
                    width: calc(100% - $contributor-blog-dimensions + 20px);
                }
                .contributor-name {
                    margin-bottom: 0.25rem;
                }
                .contributor-photo {
                    img {
                        width: $contributor-blog-dimensions;
                    }
                }
            }
        }
        @include respond-to($break-to-m) {
            .contributor-items-header {
                flex-basis: 60%;
                .contributor-item-header {
                    margin-bottom: 0px;
                    width: 97%;
                    &:only-child {
                        width: 97%;
                    }
                }
            }
            .contributor-blog-details {
                flex-basis: 40%;
            }
        }
    }
    .contributor-seo-blog-container {
        .contributor-items-header {
            flex-flow: row wrap;
            .contributor-item-header {
                margin-bottom: 10px;
                span.contributor-support {
                    white-space: nowrap;
                }
                .contributor-item-header-photo {
                    width: calc($contributor-blog-dimensions - 15px);
                }
                .contributor-item-header-title {
                    width: calc(100% - $contributor-blog-dimensions - 10px);
                }
                .contributor-photo {
                    img {
                        width: calc($contributor-blog-dimensions - 25px)
                    }
                }
            }
        }
        @include respond-to($break-to-m) {
            .contributor-items-header {
                flex-flow: row nowrap;
                .contributor-item-header {
                    margin-bottom: 0px;
                }
            }
            .contributor-items-header {
                flex-basis: 70%;
            }
            .contributor-blog-details {
                flex-basis: 30%;
            }
        }
    }
    .contributor-blog-container,
    .contributor-seo-blog-container {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-neutral-gray-13;
        align-items: center;
        width: 100%;
    }
    .contributor-items-header {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        .contributor-item-header {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;
            @include respond-to($break-to-m) {
                &:only-child {
                    margin-bottom: 0;
                }
            }
            .contributor-photo {
                text-align: left;
            }
            .contributor-name {
                font-size: 0.75rem;
                a {
                    font-weight: bold;
                }
            }
            .contributor-title {
                font-size: 0.75rem;
                font-weight: normal;
            }
        }
    }
    .contributor-blog-details {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }
    .contributor-date {
        font-size: 0.75rem;
        width: 100%;
        margin-bottom: 5px;
        em {
            color: $color-brand-blue;
        }
        span {
            color: $color-neutral-gray-50;
        }
        strong {

        }
    }
    .contributor-tags {
        width: 100%;
        font-size: 0.75rem;
        .icon {
            color: $color-brand-blue;
            float: left;
            display: inline-block;
            padding: 0 8px 0 0;
        }
        ul {
            float: left;
            width: calc(100% - 25px);
            list-style: none;
            padding: 0;
            margin-bottom: 0;
            span {
                color: $color-neutral-gray-50;
            }
            li {
                display: inline-block;
                margin-bottom: 0;
                &:not(:last-child) a:after,
                &:not(:last-child) span:after,
                &:not(:last-child) a:after,
                &:not(:last-child) span:after {
                    content: ","
                }
            }
        }
    }
}
.vsl-blog-posts {
    .contributor-blog-container {
        border-bottom: 0 none;
        margin-bottom: 15px;
        padding-bottom: 0;
        .contributor-items-header {
            flex-basis: 100%;
            .contributor-name {
                font-weight: bold;
            }
            .contributor-item-header {
                padding-top: 15px;
                @include respond-to($break-to-m) {
                    width: 98%;
                }
                &:first-child,
                &:only-child {
                    border-top: 0 none;
                    margin-top: 0;
                    padding-top: 0;
                }
                .contributor-item-header-photo {
                    width: calc($contributor-blog-dimensions + 35px);
                    align-self: flex-start;
                }
                .contributor-item-header-title {
                    width: calc(100% - 55px);
                }
                .contributor-photo {
                    img {
                        width: calc($contributor-blog-dimensions + 10px)
                    }
                }
            }
        }
    }
}
/*------------------------------------*\
    #Global
\*------------------------------------*/
.contributor-header {
    @include vsl-gradient-gb-full;
    color: $white;
    h1,h2,h3,h4,h5,p,a {
        color: $white;
    }
    .btn-text,
    .btn-text:focus,
    .btn-text:hover,
    a.btn-text:focus,
    a.btn-text:hover,
    p a.btn-text:focus,
    p a.btn-text:hover {
        color: $white;
    }
}
.contributor-photo {
    text-align: center;
    a {
        display: block;
        margin: 0 auto;
        text-align: center;
    }
    img {
        width: $contributor-dimensions;
        border-radius: 50%;
    }
}

