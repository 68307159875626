﻿.products-grid {
    .product-card {
        background-color: $white;
        padding-top: 15px;
        padding-bottom: 15px;
        box-shadow: none;
        margin-bottom: 35px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .product-image {
        text-align: center;
        margin-bottom: 15px;
    }

    .product-description {
        color: #000;

        .title {
            font-size: 1.5714rem;
            font-weight: 700;
        }
    }

    .k-widget {
        border: 0;
    }

    .productProps {
        tr:first-child td {
            padding-top: 8px;
        }

        tr:last-child td {
            padding-bottom: 12px;
        }

        td {
            padding: 0 0 6px;

            &.productProp__label, &.productProp__values {
                vertical-align: top;
                line-height: 1.4;
            }

            &.productProp__values {
                padding-left: 4px;
                font-weight: bold;
            }
        }
    }

    .related-products{
        margin:15px auto;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 992px) {
    .products-grid {
        margin: 25px 0 75px;

        .product-card {
            padding-top: 35px;
            padding-bottom: 35px;
        }
    }
}
