﻿@import "base/_variables";
@import "base/_mediaquery";
@import "base/_mixins";


.header-top-nav-wrapper ul li a {
    font-size: 0.95rem;
    text-transform:upprcase;
  }
  .navborder {
    display: block;
    padding: 15px;
    border: none;
  }
  .product-description .title {
      font-style: italic;
  }
  .mtneglg{
    margin-top: -40px;
  }
  .mtnegmd{
    margin-top: -20px;
  }
  .mtlg{
    margin-top: 40px;
  }
  .mtmd{
    margin-top: 20px;
  }

  .flist p {
    line-height: 0.7rem;
  }

  .news .news-post .tags, .news .recent-news .post .tags {
      display: none;
  }
  .resources .resource-list .resource-item .front .content .title {
    text-transform: none;
  }
  .dropdown-menu-search {
      left: calc(50% - -80px);
  }

  .dropdown-menu-about {
    max-width: 600px;
    left: calc(50% - 240px);
    ul {
        padding-top: 0;
    }
  }

  .dropdown-menu-solutions {
    max-width: 400px;
    left: calc(50% - 240px);
    ul {
        padding-top: 0;
    }
  }

  .dropdown-menu-products {
    max-width: 760px;
    // position:absolute;
    // left: calc(50% - 240px);
    // ul {
    //     padding-top: 0;
    // }
  }

  .dropdown-menu-cs {
    max-width: 700px;
  }

  @media only screen and (min-width : 1200px) {
    .feature-info-band .h2,
    .feature-info-band .feature-info-band-header
         {
            max-width: inherit;
        }

       .feature-info-band p {
            max-width: inherit;
        }
}

.button-nav li a.start-interactive-tour span {
    margin-left: 25px;
}

a.start-interactive-tour span:before {
    background-image: url('/ResourcePackages/Voyager/assets/dist/images/icons/tryit_idle.png');
    height: 20px;
   width: 15px;
    top: 1px;
}

a.start-interactive-tour:hover span:before{
    background-image: url('/ResourcePackages/Voyager/assets/dist/images/icons/tryit_active.png');
}

.main-content h1,
.page-summary h1,
.main-content .main-content-header,
.page-summary .page-summary-header {
    color: $color-brand-font;
}

.social-media-icons img {
    width: 40px;
    height: 40px;
    margin: 8px;
}


.desktop-nav .dropdown-menu {
    background-color: $color-brand-blue !important;
}

@media only screen and (min-width: 768px) {
    .desktop-nav .dropdown-menu {
        background-color: $color-brand-blue !important;
    }
}

@media only screen and (max-width: 768px) {
    .desktop-nav .dropdown-menu {
        background-color: $color-brand-blue !important;
    }
}

.dropdown-menu-featured-program,
.dropdown-menu-more-programs {
    .h2.category-title
    h2.category-title,
    .category-title {
        font-size: 12px;
        color: $white;
        text-transform: uppercase;
        padding: 0;
        text-align: left;
        font-weight: bold;
    }
    .product-category {
        font-size: 12px;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: bold;
        a {
            font-weight: bold !important;
        }
    }
    a h3.product-name,
    h3.product-name,
    .h3.product-name,
    .product-name {
        font-size: 18px;
        font-weight: 400;
        color: $white;
        font-style: italic;
        .grade,
        .sub {
            font-size: 0.75rem;
        }
    }
    .product-list {
        li {
            margin-bottom: 0.5rem;
        }
        & + .product-category {
            margin-top: 1rem;
        }
    }
    sup {
        display: inline-block;
        font-size: 0.688rem;
    }
    a.btn-outline {
        padding: 10px 20px !important;
        font-weight: 400 !important;
        &:hover,
        &:focus {
            color: $color-brand-blue !important;
        }
    }
}

.dropdown-menu-featured-program {
    h3.product-name,
    .h3.product-name
    .product-name {
        margin: 0 0 10px;
    }
    p.product-description {
        font-size: 14px;
        padding-right: 50px
    }
    p>a,
    p>a:visited,
    p>a:active {
        color: #fff;
        font-size: 14px !important;
    }
}

.dropdown-menu-more-programs {
    a h3.product-name,
    a .h3.product-name,
    a .product-name {
        margin: 0;
    }
    p.product-description {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 20px
    }
    a h4,
    a .h4 {
        font-size: 14px;
        text-decoration: underline;
        font-weight: 400;
        color: #fff
    }
}

@media only screen and (min-width:768px) {
    .desktop-nav .dropdown-menu a {
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        padding: 0
    }

    .desktop-nav .dropdown-menu a:hover,
    .desktop-nav .dropdown-menu a:active {
        color: #fff;
        text-decoration: underline;
        padding-left: 3px;
    }
}

@media(max-width:767px) {
    .dropdown-menu-featured-program p.product-description {
        padding-right: 0
    }
}

.also-published {
    font-weight: normal;
    font-style: normal;
}

/*---------------------------------------------------------------------------------------------------------------------*\
     #New Navigation
 \*--------------------------------------------------------------------------------------------------------------------*/

.mobile-nav-arrow {
    display: none !important;
    font-size: 20px;
    height: 20px;
    width: 20px;
    margin-right: 20px;
    transition: all .3s;
}

.navbar-nav > li:nth-last-child(3), .navbar-nav.nav .mobile-nav-item {
    display: none;
}

@media (max-width: 767px) {
    .slide-menu-wrapper {
        -webkit-transform: none !important;
        margin-top: 96px;
    }
    .navbar.navbar-default, .header-top-nav-container {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 3;
        background-color: rgba(255,255,255,.9);
    }
    .navbar.navbar-default {
        z-index: 2;
        top: 30px;
        box-shadow: 0px -5px 15px #000;
    }
    #navbar {
        background-color: #005587cc;
        font-size: 20px;
    }
    .navbar-header {
        background-color: #fff;
    }
    .navbar-brand a {
        display: inline-block;
    }
    .vsl-mobile-header.visible-xs {
        padding: 5px;
    }
    .nav.hidden-xs {
        display: none;
        height: calc(100vh - 94px);
        padding: 60px 0px 0px;
        margin: 0px;
    }
    .slide-menu-wrapper.open .nav.hidden-xs {
        display: flex !important;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        padding: 60px 30px;
    }
    .nav.navbar-nav>li {
        width: 100%;
    }
    .nav li.dropdown .dropdown-toggle {
        display: block;
        color: #fff;
        transform: skewX(-25deg);
        padding: 10px 15px;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
    }
    .nav li.dropdown .dropdown-toggle:hover {
        text-decoration: none;
    }
    .nav li.dropdown .dropdown-toggle:focus {
        color: #fff;
    }
    .nav li.dropdown.open .dropdown-toggle:hover {
        color: #fff !important;
        text-decoration: none;
        transition: none;
    }
    .dropdown.open .dropdown-toggle {
        background-color: #67bd4b !important;
        color: #fff !important;
        margin-right: -10px;
        margin-left: 10px;
    }
    .slide-menu-wrapper.open .dropdown-menu a:hover {
        background-color: #fff;
        color: #333;
        text-decoration: none;
        transition: none;
    }
    .mobile-nav-arrow {
        display: inline-block !important;
    }
    .nav li.dropdown .dropdown-toggle:hover .mobile-nav-arrow, .nav li.dropdown.open .mobile-nav-arrow {
        transform: rotate(90deg);
    }
    .nav li.dropdown .dropdown-toggle:hover, .nav .no-dropdown:hover a {
        color: #333 !important;
        background-color: #fff;
        transition: none;
    }
    .nav li.dropdown .dropdown-toggle > *, .nav .no-dropdown a span {
        display: inline-block;
        transform: skewX(25deg);
    }
    .dropdown-menu {
        max-width: 100%;
        margin-top: 4px !important;
        border-radius: 0px;
    }
    .dropdown-menu a, .dropdown-menu a:active, .dropdown-menu a:link, .dropdown-menu a:visited {
        color: #fff;
        display: inline-block;
        width: 100%;
        padding: 0px 5px;
    }
    .dropdown-menu em {
        display: none !important;
    }
    .dropdown-menu-featured-program a.btn {
        margin-top: 10px;
    }
    .dropdown-menu-more-programs ul, .dropdown-menu-featured-program ul {
        padding: 0px;
    }
    .dropdown-menu-more-programs li, .dropdown-menu-featured-program li {
        display: block;
        margin: 10px auto;
        text-align: left;
    }
    .dropdown-menu-more-programs, .dropdown-menu-featured-program {
        font-size: 18px;
    }
    .slide-menu-wrapper.open .slide-content {
        left: 0px !important;
    }
    .nav .no-dropdown {
        margin: 0px;
        line-height: 20px;
    }
    .nav .no-dropdown a {
        display: inline-block;
        transform: skewX(-25deg);
        color: #fff !important;
        width: 100%;
        padding: 10px 15px;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }
    .nav>li:last-of-type {
        padding: 0px 0px 60px;
    }
    .navbar-nav > li:nth-last-child(3), .navbar-nav.nav .mobile-nav-item {
        display: block;
    }
}


