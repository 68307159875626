﻿$title-height: 110px;
$tag-height: 50px;
$image-height: 125px;
$fade-height: calc(#{$title-height} / 4);

.resources {
    .product-navigation-layout {
        height: auto;
        overflow: hidden;
        color: #fff;

        .title {
            padding: 17px 10px;
            width: 100%;
        }

        p,
        .k-content {
            box-sizing: border-box;
            margin: 0;
        }

        [class*='col-'] {
            padding: 12px 0;
            padding-left: 12px;
            padding-right: 12px;
        }

        .k-content {
            background-color: transparent;
            overflow: hidden;
        }

        .select-wrapper {
            width: calc(100%);
            float: left;
            padding: 10px;

            select {
                display: none;
            }
        }

        .k-dropdown-wrap.k-state-default,
        .k-autocomplete.k-state-default,
        .k-numeric-wrap.k-state-default,
        .k-picker-wrap.k-state-default {
            background-color: #88d0e5;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 0.9286rem;
            font-family: $font-family-header;
            padding: 5px 10px;
            box-shadow: 0 0 5px #333;
            position: relative;
        }

        .k-dropdown .k-input,
        .k-dropdown .k-state-focused .k-input,
        .k-menu .k-popup,
        .k-multiselect .k-button,
        .k-multiselect .k-button:hover {
            color: #1e63af;
            height: auto !important;
        }

        .k-icon {
            left: -5px;
        }

        .k-icon:before {
            content: "\f078";
            font-family: FontAwesome;
            color: #1e63af;
        }

        .k-button.k-primary {
            float: none !important;
            margin-top: 9px !important;
            margin-left: 10px;
            margin-bottom: 10px;
            background-color: #566575;
            border: 1px solid #fff;
            color: #fff;
            padding: 6px 25px;
            font-size: 0.9286rem;
            text-transform: uppercase;
            float: left;
        }

        .loading-overlay {
            background: #e9e9e9;
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.5;
            z-index: 100;

            #loading-img {
                position: fixed;
                top: 350px;
                left: 50%;
                height: 64px;
                width: 64px;
                transform: translate(-50%, -50%);
                background-image: url('/ResourcePackages/Voyager/assets/src/sitefinity/images/circle-loader.gif');
                background-repeat: no-repeat;
            }
        }
    }

    .resource-list {
        padding-top: 100px;
        overflow: hidden;
        background-image: none;
        // background-image: url('../../images/background-pattern-white.png');
        // background-position: center top;
        // background-repeat: repeat-x;
        // background-size: auto;

        .resource-item {
            position: relative;
            background-color: $main-web-lt-blue;
            margin-bottom: 30px;
            border: 1px solid $main-web-lt-blue;
            border-radius: 0;

            .text {
                height: 315px;
                overflow: hidden;
            }

            .tags {
                height: $tag-height;
                div {
                    color: $color-neutral-gray-73;
                    font-size: 0.75rem;
                    font-weight: 200;
                }
            }

            .image {
                width: 100%;
                background-color: $color-brand-blue-dark;
                height: $image-height;
                border-radius: 0;
            }

            .content {
                padding: 10px 20px 20px;
                background-color: transparent;
                border-radius: 0;

                .title {
                    font-size: 0.875rem;
                    height: $title-height;
                    overflow: hidden;
                    margin-bottom: 10px;
                    background-color: transparent;
                    font-weight: 400;
                    color: $color-brand-font;
                    font-family: $font-family-header;
                    &::after {
                        display: none;
                    }
                }

                .type {
                    .display-link {
                        font-size: .9rem;
                        color: $color-brand-blue;
                        display: flex;
                        text-decoration: none;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .resources {
        .product-navigation-layout {
            .title {
                width: auto;
            }

            .select-wrapper {
                width: calc(33% - 10px);
                float: left;
                padding: 10px;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .resources {
        .product-navigation-layout {
            .title {
                float: left;
            }

            .select-wrapper {
                width: calc(33% - 33px);
                float: left;
                padding: 10px;
            }
        }
    }
}

@media (max-width: 479px) {
    .resources {
        .col-xxs-12 {
            width: 100%;
        }
    }
}
