.side-by-side {

    h2 {
        margin-top: 40px 0 25px 0;
    }

    .btn-side-by-side {
        border: 2px solid $color-brand-blue;
    }

}

.identifier h1 {
    font-family: monospace;
    background: #ffffcc;
}

@media(max-width:992px) {
   .side-by-side h2 {
        margin: 0 0 20px 0;
    }
}
