﻿.vsl-breadcrumbs {
    background-color: $color-brand-blue;
    .breadcrumb {
        max-width: $template-width;
        padding-left: 15px;
        padding-right: 15px;
        padding: 8px 15px;
        margin: 0 auto;
        list-style: none;
        background-color: $color-brand-blue;
        border-radius: 0;
        color: $white;
        font-size: 0.75rem;
        li a, li a:link, li a:active, li a:visited {
            color: $white;
        }
        &>.active {
            color: $color-neutral-gray-27;
        }
        li {
            margin-bottom: 0;
            font-size: 0.75rem;
        }
    }
    @include respond-to(0,$break-m) {
        display: none;
    }
    & + .container-fluid > h2,
    & + .container-fluid .container:first-child h2 {
        margin-top: 2rem;
    }
    & + .container-fluid .container:first-child.mt-60 h2,
    & + .container-fluid .container:first-child .callout h2 {
        margin-top: 0;
    }
}

