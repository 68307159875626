﻿$home-banner-height: 450px;

.blue-triangles {
    // @include vsl-gradient;
    background-image: url('https://www.voyagersopris.com/images/default-source/rebrand/blue-triangles-overlay5d6e0266-38b6-4b2d-b06a-55f41368458e.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: $home-banner-height;
    p {
        margin-bottom: 0;;
    }
    .hero-banner-custom-bg-img-cta {

        height: $home-banner-height;
    }
}

.home-overlay {
    .hero-banner-custom-bg-img {
        background-image: url('https://www.voyagersopris.com/images/default-source/banners/home-page-banner.jpg');
        height: $home-banner-height;
        h1 {
            font-size: 3.1rem;
        }
        .col-md-6 {
            @include respond-to($break-m) {
                display: table;
                width: 40%;
            }
            @include respond-to(0, $break-m) {
                width: 60%;
            }
        }
    }
    .blue-overlay {
        height: $home-banner-height;
    }
    .hero-banner-custom-bg-img-cta {
        height: $home-banner-height;
    }
}

.hero-banner-custom-bg-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    h1 {
        font-size: 2rem;
    }
}

// @include respond-to($break-l) {
//     .hero-banner-custom-bg-img,
//     .blue-overlay,
//     .blue-triangles,
//     .hero-banner-custom-bg-img-cta {
//         height: $home-banner-height;
//         background-position: top;
//     }
// }

@include respond-to(0,$break-l) {
    .home-overlay .hero-banner-custom-bg-img h1,
    .hero-banner-custom-bg-img h1,
    .hero-banner-custom-bg-img .h1,
    .hero-banner-custom-bg-img .accent-title1 {
        font-size: 2rem;
    }
}

@include respond-to(0,$tweak-m-2) {
    // .hero-banner-custom-bg-img,
    // .blue-overlay,
    .blue-triangles,
    // .hero-banner-custom-bg-img-cta,
    .home-overlay .blue-overlay,
    .home-overlay .blue-triangles,
    .home-overlay .hero-banner-custom-bg-img,
    .home-overlay .hero-banner-custom-bg-img-cta {
        height: 300px;
    }
    .home-overlay .hero-banner-custom-bg-img h1,
    .hero-banner-custom-bg-img h1,
    .hero-banner-custom-bg-img .h1,
    .hero-banner-custom-bg-img .accent-title1 {
        font-size: 28px;
    }
}

@include respond-to(0,$break-m) {
    // .hero-banner-custom-bg-img,
    // .blue-overlay,
    .blue-triangles,
    // .hero-banner-custom-bg-img-cta,
    .home-overlay .blue-overlay,
    .home-overlay .blue-triangles,
    .home-overlay .hero-banner-custom-bg-img,
    .home-overlay .hero-banner-custom-bg-img-cta {
        height: 200px;
    }
    .home-overlay .hero-banner-custom-bg-img h1,
    .hero-banner-custom-bg-img h1,
    .hero-banner-custom-bg-img .h1,
    .hero-banner-custom-bg-img .accent-title1 {
        font-size: 20px;
    }
    .col-md-6 {
        width: 100%;
    }
}

.banner-sub-section {
    text-align: center;
    h2,h3 {
        font-size: 2rem;
        font-weight:700;
        color: $color-brand-blue;
        margin-bottom: 20px;
        @include respond-to(0,$break-m) {
            font-size: 20px;
        }
    }
    p {
        color: #666;
        font-size: 1.0769rem;
        @include respond-to($break-m) {
            display: block;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    p:last-child {
        margin-bottom: 0;
    }
}

.product-overview h2 {
    font-size: 24px;
    font-weight: bold;
    margin: .5em 0 .25em 0;
}

.product-type-icon {
    width: 100px;
}

.product-overview p, .content-block p {
    margin-right: 60px;
}

.product-overview a {
    text-decoration: underline;
}

.content-block .quantity {
    font-size: 34px;
}

.content-block .category {
    font-size: 14px;
    text-transform: uppercase;
    color: $color-brand-tangerine;
}


.icon-list-mobile {
    margin-bottom: 2em;

    @include respond-to(0, $break-m) {
        img {
            display: block;
            margin: 0 auto 20px;
            width: 150px;
        }

        h3 {
            display: block;
            text-align: center;
            margin-bottom: 10px;
        }

        .icon-list-mobile-link {
            text-align: center;
        }
    }
}

.content--split {
    width: 100%;
    @include clearfix;
    background-color: $color-neutral-gray-07;
    &--blue,
    &--dk-blue,
    &--reading,
    &--literacy,
    &--emerald,
    &--writing,
    &--purple,
    &--math,
    &--red,
    &--assessment,
    &--lt-blue,
    &--cs,
    &--orange,
    &--dark-gray {
        color: $white;
        h1,h2,h3,h4,h5,h6 {
            color: $white;
        }
        a {
            color: $white;
            text-decoration: underline;
            &.btn-callout-solid {
                &:hover,
                &:focus {
                    color: $white;
                    text-decoration: underline !important;
                }
            }
        }
    }
    h2 {
        @include respond-to(0,$break-l) {
        font-size: 1.5rem;
        }
    }
    &--dark-gray {
        background-color: $color-brand-font;
    }
    &--blue {
        background-color: $color-brand-blue;
    }
    &--dk-blue {
        background-color: $color-brand-blue-dark;
    }
    &--white {
        background-color: $white;
    }
    &--reading,
    &--literacy,
    &--emerald {
        background-color:  $color-brand-emerald;
    }
    &--writing,
    &--purple {
        background-color:  $color-brand-blueberry;
    }
    &--math,
    &--red {
        background-color:  $color-brand-pomegranate;
    }
    &--assessment,
    &--lt-blue {
        background-color:  $color-brand-blue-sky;
    }
    &--cs,
    &--orange {
        background-color:  $color-brand-tangerine;
    }
    .content__section {
        width: 100%;
        @include respond-to($custom-m-2) {
            margin:0;
            padding:0;
            width: 50%;
            *width: 49.9%;
            min-height: $home-banner-height;
            @include clearfix;
        }
        &--img {
            overflow: hidden;
            img {
                display: block;
                height: auto;
                @include respond-to($custom-m-2) {
                    max-width: none;
                }
                @include respond-to(0,$custom-m-2) {
                    width: 100%;
                    margin: 0;
                }
                @include respond-to(125.000em) {
                    width: 100%;
                }
            }
        }
        &+.content__section {
            margin: 0;
        }
        // Instead of using :first-child/:last-child this ensures the proper stacking for mobile devices
        &--right {
            float: right;
            .content--split-container {
                @include respond-to($custom-m-2) {
                    float: left;
                    left: 0;
                    .content--split-margin {
                        margin-left: 2em;
                    }
                }
            }
            &.content__section--img {
                text-align: right;
                img {
                    display: inline-block;
                    text-align: right;
                }
            }
        }
        &--left {
            float: left;
            .content--split-container {
                @include respond-to($custom-m-2) {
                    float: right;
                    right: 0;
                    .content--split-margin {
                        margin-right: 2em;
                    }
                }
            }
            &.content__section--img {
                text-align: left;
                img {
                    display: inline-block;
                    text-align: left;
                }
            }
        }
    }
    &-margin {
        margin-top: 40px;
        margin-bottom: 40px;
        @include respond-to($custom-m-2) {
            margin-top: 40px;
            margin-bottom: 0px;
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    &-container {
        p {
            // font-size: 0.925rem;
        }
        @include respond-to(0,$break-l) {
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }
        @include respond-to($break-m,$tweak-m-2) {

        }
        @include respond-to($custom-m-2) {
            @include rem(max-width, 32em);
        }
    }
    @include respond-to(0, $template-width) {
        img.product-logo {
            display: block;
            margin: 0 auto 20px;
        }

        h3 {
            display: block;
            text-align: center;
            margin-bottom: 10px;
        }

        .btn-mobile-link {
            display: block;
            margin: 0 auto;
            text-align: center;
        }
    }
}

.home-letters-img {
    @include respond-to($break-m,$template-width) {
        margin-left: -190px;
    }
    @include respond-to($template-width) {
        margin-left: -90px;
    }
}
.home-ll-img {
    @include respond-to($break-m,$template-width) {
        margin-left: -280px;
    }
    @include respond-to($template-width) {
        margin-left: -185px;
    }
}
.home-vp-img {
    @include respond-to($break-m,$template-width) {
        margin-left: -140px;
    }
    @include respond-to($template-width) {
        margin-left: -30px;
    }
}
.home-rr-img {
    @include respond-to($break-m,$template-width) {
        margin-left: -120px;
    }
    @include respond-to($template-width) {
        margin-left: -85px;
    }
}
.home-ar-img {
    @include respond-to($break-m,$template-width) {
        margin-left: -240px;
    }
    @include respond-to($template-width) {
        margin-left: -210px;
    }
}
.home-letters-img,
.home-ll-img,
.home-rr-img,
.home-vp-img,
.home-ar-img {
    @include respond-to(1550px) {
        // width:100%;
    }
}


.remote
{
  border: 2px #009bdf;
  border-style: solid;
  border-radius: 5px;
  padding:20px;
  text-align: left;
  background-color:#f4f4f4;
  margin-bottom:20px;
}
// @media all and (max-width: 48em) {
//   .edview-360 {
//       margin-bottom:40px;
//     }
// }