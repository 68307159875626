﻿.resource-icon {
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    &:before {
        font-family: FontAwesome;
        margin-right: 5px;
    }

    &:after {
        font-family: $font-family-header;
    }

    &.pdf-icon.display-link:after {
        content: ' View PDF';
    }

    &.brochure-icon.display-link:after {
        content: ' View Brochure';
    }

    &.video-icon.display-link:after {
        content: ' Play Video';
    }

    &.white-paper-icon.display-link:after {
        content: ' Read White Paper';
    }

    &.link-icon.display-link:after {
        content: ' Open Link';
    }

    &.webinar-icon.display-link:after {
        content: ' View Webinar';
    }
}
