
.reading-theme,
.literacy-theme {
    .callout {
        background: $color-brand-emerald;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-emerald;
            &:hover,
            &:focus {
                color: $color-brand-emerald-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-emerald, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-emerald-dark;
            }
        }
    }
}
.writing-theme {
    .callout {
        background: $color-brand-blueberry;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-blueberry;
            &:hover,
            &:focus {
                color: $color-brand-blueberry-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-blueberry, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-blueberry-dark;
            }
        }
    }
}
.math-theme {
    .callout {
        background: $color-brand-pomegranate;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-pomegranate;
            &:hover,
            &:focus {
                color: $color-brand-pomegranate-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-pomegranate, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-pomegranate-dark;
            }
        }
    }
}
.assessment-theme {
    .callout {
        background: $color-brand-blue-sky;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-blue-sky;
            &:hover,
            &:focus {
                color: $color-brand-blue-sky-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-blue-sky, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-blue-sky-dark;
            }
        }
    }
}
.cs-theme {
    .callout {
        background: $color-brand-tangerine;
        a.btn,
        p a.btn.btn-white,
        a.btn-white,
        .btn-white {
            color: $color-brand-tangerine;
            &:hover,
            &:focus {
                color: $color-brand-tangerine-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-tangerine, 0.15);
            }
            &:active,
            &.active {
                background-color: $color-brand-tangerine-dark;
            }
        }
    }
}
.default-theme {
    .callout {
        background-color: $color-brand-blue;
    }
}
.callout {
    position: relative;
    width: 100%;
    background-color: $color-brand-blue;
    &.row {
        margin-left: 0;
        margin-right: 0;
    }
    .mt-25 {
        margin-top: 0;
    }
    .col-sm-9 {
        padding: 2rem 1rem;
    }
    .col-sm-3 {
        padding: 0 1rem 2rem;
    }
    .col-sm-9, .col-sm-3 {
        @include respond-to($break-m) {
            padding: 2rem 1rem;
        }
    }
    h1,h2,h3,h4,h5,h6,
    .h1,.h2,.h3,.h4,.h5,.h6 {
        font-size: 1.5rem;
        color: $white;
        margin-bottom: 0;
        & + p {
            margin-top: 1rem;
        }
    }
    p {
        color: $white;
        margin-bottom: 0;
    }
    a.btn,
    p a.btn.btn-white,
    a.btn-white,
    .btn-white {
        background-color: $white;
        color: $color-brand-blue;
        border-color: transparent;
        box-shadow: none;
        &:hover,
        &:focus {
            color: $color-brand-blue-dark;
            text-decoration: underline;
            box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-blue, 0.15);
        }
        &:active,
        &.active {
            background-color: $color-brand-blue-dark;
        }
        & + .btn {
            margin-top: 1rem;
        }
    }
    &--green {
        background: $color-brand-green-wcag;
        .callout {
            background: $color-brand-green-wcag;
        }
        a.btn {
            color: $color-brand-green-wcag;
            &:hover,
            &:focus {
                color: $color-brand-green-wcag-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-green-wcag, 0.15);
            }
        }
    }
    &--emerald,
    &--reading,
    &--literacy {
        background: $color-brand-emerald;
        .callout {
            background: $color-brand-emerald;
        }
        a.btn {
            color: $color-brand-emerald;
            &:hover,
            &:focus {
                color: $color-brand-emerald-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-emerald, 0.15);
            }
        }
    }
    &--red,
    &--math {
        background: $color-brand-pomegranate;
        .callout {
            background: $color-brand-pomegranate;
        }
        a.btn {
            color: $color-brand-pomegranate;
            &:hover,
            &:focus {
                color: $color-brand-pomegranate-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-pomegranate, 0.15);
            }
        }
    }
    &--purple,
    &--writing {
        background: $color-brand-blueberry;
        .callout {
            background: $color-brand-blueberry;
        }
        a.btn {
            color: $color-brand-blueberry;
            &:hover,
            &:focus {
                color: $color-brand-blueberry-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-blueberry, 0.15);
            }
        }
    }
    &--lt-blue,
    &--assessment {
        background: $color-brand-blue-sky;
        .callout {
            background: $color-brand-blue-sky;
        }
        a.btn {
            color: $color-brand-blue-sky;
            &:hover,
            &:focus {
                color: $color-brand-blue-sky-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-blue-sky, 0.15);
            }
        }
    }
    &--orange,
    &--cs {
        background: $color-brand-tangerine;
        .callout {
            background: $color-brand-tangerine;
        }
        a.btn {
            color: $color-brand-tangerine;
            &:hover,
            &:focus {
                color: $color-brand-tangerine-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-tangerine, 0.15);
            }
        }
    }
    &--blue {
        background: $color-brand-blue;
        background-color: $color-brand-blue;
        .callout {
            background: $color-brand-blue;
            background-color: $color-brand-blue;
        }
        a.btn {
            color: $color-brand-blue;
            &:hover,
            &:focus {
                color: $color-brand-blue-dark;
                box-shadow: inset 0 -3.25em 0 0 rgba($color-brand-blue, 0.15);
            }
        }
    }
}