$utility-radius: 8px;
$utility-radius-fix : calc($utility-radius - 1px);

.utility {
    border: 1px solid $color-neutral-gray-60;
    background-color: $color-neutral-gray-60;
    border-radius: $utility-radius;
    .utility-heading,
    h1.utility-heading,
    h2.utility-heading,
    h3.utility-heading,
    h4.utility-heading,
    h5.utility-heading,
    h6.utility-heading {
        color: $white;
        border-radius: $utility-radius $utility-radius 0 0;
        font-family: $font-family-header;
        padding: 0.75rem 1rem;
        margin: 0;
        font-size: 1rem;
        font-weight: bold;
        text-transform: none;
        line-height: 1.5;
    }
    .utility-details {
        color: $color-neutral-gray-60;
        background-color: $color-neutral-gray-07;
        border-radius: $utility-radius-fix;
        font-size: 0.875rem;
        white-space: wrap;
        padding: 1rem;
        p {
            font-size: 0.875rem;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
    .utility-heading + .utility-details {
        border-radius: 0 0 $utility-radius-fix $utility-radius-fix;
    }

    &--info {
        border-color: $color-utility-info;
        background: $color-utility-info;
        .utility-details {
            color: $color-utility-info;
            background-color: $color-utility-info-light;
            a {
                color: $color-utility-info;
            }
        }
    }
    &--error {
        border-color: $color-utility-error;
        background: $color-utility-error;
        .utility-details {
            color: $color-utility-error;
            background-color: $color-utility-error-light;
            a {
                color: $color-utility-error;
            }
        }
    }
    &--success {
        border-color: $color-utility-success;
        background: $color-utility-success;
        .utility-details {
            color:  $color-utility-success;
            background-color: $color-utility-success-light;
            a {
                color: $color-utility-success;
            }
        }
    }
    &--warning {
        border-color: $color-utility-warning;
        background: $color-utility-warning;
        .utility-details {
            color: $color-brand-tangerine-dark;
            background-color: $color-utility-warning-light;
            a {
                color: $color-brand-tangerine-dark;
            }
        }
    }
    &--reading {
        border-color: $color-brand-reading;
        background: $color-brand-reading;
        .utility-details {
            color: $color-brand-reading;
            background-color: $color-brand-emerald-light;
            a {
                color: $color-brand-reading;
            }
        }
    }
    &--writing{
        border-color: $color-brand-writing;
        background: $color-brand-writing;
        .utility-details {
            color: $color-brand-writing;
            background-color: $color-brand-blueberry-light;
            a {
                color: $color-brand-writing;
            }
        }
    }
    &--math{
        border-color: $color-brand-math;
        background: $color-brand-math;
        .utility-details {
            color: $color-brand-math;
            background-color: $color-brand-pomegranate-light;
            a {
                color: $color-brand-math;
            }
        }
    }
    &--assessment {
        border-color: $color-brand-assessment;
        background: $color-brand-assessment;
        .utility-details {
            color: $color-brand-blue-sky-dark;
            background-color: $color-brand-blue-sky-light;
            a {
                color: $color-brand-blue-sky-dark;
            }
        }
    }
    &--cs {
        border-color: $color-brand-cs;
        background: $color-brand-cs;
        .utility-details {
            color: $color-brand-tangerine-dark;
            background-color: $color-brand-tangerine-light;
            a {
                color: $color-brand-tangerine-dark;
            }
        }
    }
}
