.panel-group.panel-group-vsl {
    .panel {
        .panel-heading {
            display: block;
            width: 100%;
            background-color: transparent;
            border: 0 none;
            padding: 0;
            text-align: left;
            color: $white;
            .panel-title,
            h1.panel-title,
            h2.panel-title,
            h3.panel-title,
            h4.panel-title,
            h5.panel-title {
                position: relative;
                color: $white;
                font-weight: 400;
                @include respond-to($tweak-s-2) {
                    font-weight: 200;
                }
            }
            a {
                display: block;
                width: 100%;
                background-color: $color-brand-blue;
                border: 0 none;
                padding: 1rem 0 1rem 2.5em;
                text-align: left;
                color: $white;
                border-radius: 3px;
                @include rem(font-size, 1);
                line-height: 1.3em;
                margin: 0;
                &:hover, &:focus {
                    background-color: darken($color-brand-blue, 8%);
                    outline: none;
                    text-decoration: none;
                }
                &:focus {
                     color: $white;
                }
                &:before {
                    font-family: FontAwesome;
                    display: block;
                    position: absolute;
                    font-weight: 700;
                    font-style: normal;
                    color: $color-brand-blue-light;
                    text-align: center;
                    @include rem(font-size, 1.25);
                    width: 1.5em;
                    height: 1.5em;
                    line-height: 1.5em;
                    margin-left: -1.75em;
                    top: 50%;
                    transform: translateY(-50%);
                    content:"\f0fe";
                    transition: 0.5s;
                }
                &[aria-expanded="true"]{
                    border-radius: 3px 3px 0 0;
                    &:before {
                        content:"\f146";
                    }
                }
                @include respond-to($tweak-s-2) {
                    @include rem(font-size, 1.25);
                    padding-left: 2.75em;
                    &:before {
                        margin-left: -2em;
                    }
                }
                &::after {
                    display: none;
                }
            }
        }
    }
}