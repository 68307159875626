@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,700;0,800;1,200;1,400;1,700;1,800&display=swap');
@import "base/_variables";
@import "base/_mediaquery";
@import "base/_mixins";
@import "product-banner";
@import "all-products";
@import "base";
@import "blog";
@import "bordered-card";
@import "breadcrumb";
@import "buttons";
@import "card-caption";
@import "carousel";
@import "check-list-card";
@import "contributors";
@import "editor-classes";
@import "events";
@import "feature-card";
@import "feature-info-band";
@import "footer";
@import "forms";
@import "general-content-page";
@import "ham-stack";
@import "header";
@import "hero-banner-custom-bg-img";
@import "hero-banner-editable";
@import "home";
@import "images";
@import "layouts";
@import "lead-text-with-icons";
@import "navigation";
@import "news";
@import "order-swapper";
@import "page-summary";
@import "products-grid";
@import "podcasts";
@import "product-bar";
@import "product-navigation";
@import "product-overview";
@import "product-summary";
@import "product-tiles";
@import "products-grid";
@import "products/voyager-passport";
@import "research-results-layout";
@import "resources";
@import "resource-icon";
@import "resource-card-filter";
@import "row-flex";
@import "search";
@import "sfPageEditor";
@import "site-map-listing";
@import "social-nav";
@import "success-stories";
@import "themes";
@import "video-theme";
@import "edview360";
@import "components/accordions";
@import "components/badges";
@import "components/blockquotes";
@import "components/callouts";
@import "components/color-blocks";
@import "components/lists";
@import "components/pillars";
@import "components/tables";
@import "components/toc";
@import "components/utilities";
@import "components/webstore-features";
@import "template-blocks/side-by-side";
@import "template-blocks/testimonials";
@import "base/_helper";

html {
    scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.section {
    margin-top: 25px;
    margin-bottom: 25px;
}

.section-title {
    padding: 20px;
    font-size: 1.7857rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.comments {
    .section-name {
        .title {
            a {
                display: inline-block !important;
            }
        }
    }
}

.title-light {
    background-color: #f3f2eb;
    margin-bottom: 2px;
}

.title-blue {
    background-color: $accent-color;
    color: #fff;
}

.col-20-percent {
    width: 20%;
    float: left;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
}

.option-link,
.option-link:hover,
.option-link:focus {
    text-decoration: none;
}

.option-link {
    position: relative;
    color: $accent-color;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 24px;
    cursor: pointer;
}

.option-link:before {
    content: "";
    position: absolute;
    background-color: $accent-color;
    display: inline-block;
    border-radius: 8px;
    width: 16px;
    height: 16px;
    top: 0;
    left: -24px;
}

.option-link:after {
    content: "";
    position: absolute;
    border-radius: 4px;
    height: 8px;
    width: 8px;
    top: 4px;
    left: -20px;
    background-color: #88d0e5;
}

.option-link:hover:after {
    background-color: $secondary-color;
    border: 1px solid #88d0e5;
}

.k-animation-container, .k-animation-container *, .k-animation-container :after, .k-block .k-header, .k-list-container, .k-widget, .k-widget *, .k-widget :before, div.k-window-content {
    box-sizing: border-box;
}

.product-icon {
    max-width: 250px;
}

.white-text {
    color: #ffffff;
}

.img-rounded {
    border-radius: 8px;
}

.video-rounded {
    border-radius: 8px;
    * {
        border-radius: 8px;
    }
}

.img-rounded-lg {
    border-radius: $global-radius-lg;
}

.video-rounded-lg {
    border-radius: $global-radius-lg;
    * {
        border-radius: $global-radius-lg;
    }
    &.btn-video:before {
        border-radius: $global-radius-lg;
    }
}

.thumbnail {
    border: none;
    padding: 0;
}

.ui-menu.ui-autocomplete.sf-autocomplete {
    display: none !important;
}

#ot-sdk-btn.ot-sdk-show-settings, 
#ot-sdk-btn.optanon-show-settings {
    color: $white;
    border: 1px solid transparent;
    height: auto;
    white-space: normal;
    word-wrap: break-word;
    padding: 0.8em 2em;
    font-size: 1em;
    line-height: 1.4;
    cursor: pointer;
}

.lity.lity-opened {
    @include vsl-gradient-bg;
}