﻿.product-banner-bg {
    @include vsl-gradient-full-rev;
    padding: 2rem 0;
    color: $white;
    h1, .h1, h2, .h2 {
        color: $white;
        margin: 0;
        font-size: 2.6rem;
        font-style: normal;
    }
}