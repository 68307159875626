﻿$vsl-bar-icon-size: 200px;

div.vsl-product-bar-container {
    &:hover,
    &:focus {
        box-shadow: none;
        .vsl-product-bar-content--right .vsl-product-bar-content-icon {
            transform: rotate(15deg);
            transition: all 0.5s;
        }
        .vsl-product-bar-content--left .vsl-product-bar-content-icon {
            transform: rotate(-15deg);
            transition: all 0.5s;
        }
        .vsl-product-bar-content-text {
            text-decoration: none;
        }
    }
    &:not(:hover) {
        box-shadow: 0 0 none;
        .vsl-product-bar-content--right .vsl-product-bar-content-icon {
            transform: rotate(15deg);
            transition: all 0.5s;
        }
        .vsl-product-bar-content--left .vsl-product-bar-content-icon {
            transform: rotate(-15deg);
            transition: all 0.5s;
        }
    }
}
.vsl-product-bar-container {
    display: block;
    @include vsl-gradient-full;
    @include clearfix;
    overflow: hidden;
    &:hover,
    &:focus {
        transition: 0.25s;
        box-shadow: inset 0 0 0 4em rgba($color-brand-blue, 0.2);
        .vsl-product-bar-content .vsl-product-bar-content-icon {
            transform: rotate(0deg);
            transition: all 0.5s;
        }
        .vsl-product-bar-content-text {
            text-decoration: underline;
        }
    }
    &:focus {
        outline: 1px auto $color-brand-blue-sky;
        outline-offset: 1px;
    }
    &:not(:hover) {
        box-shadow: 0 0 none;
        .vsl-product-bar-content--right .vsl-product-bar-content-icon {
            transform: rotate(15deg);
            transition: all 0.5s;
        }
        .vsl-product-bar-content--left .vsl-product-bar-content-icon {
            transform: rotate(-15deg);
            transition: all 0.5s;
        }
    }
    &--reading,
    &--literacy {
        background: $color-brand-emerald;
    }
    &--writing {
        background: $color-brand-blueberry;
    }
    &--math {
        background: $color-brand-pomegranate;
    }
    &--assessment {
        background: $color-brand-blue-sky;
    }
    &--cs {
        background: $color-brand-tangerine;
    }
    .vsl-product-bar-content {
        position: relative;
        @include clearfix;
        color: $white;
        font-weight: bold;
        padding: 2rem 0;
        @include respond-to(0,$break-s) {
           font-size: 0.875rem;
        }
        &--right {
            float: right;
            .vsl-product-bar-content-icon {
                right: 0;
                transform: rotate(15deg);
            }
            .vsl-product-bar-content-text {
                margin-right: $vsl-bar-icon-size;
                @include respond-to($break-s,$tweak-s-1) {
                    margin-right: $vsl-bar-icon-size - 50px;
                }
                @include respond-to(0,$break-s) {
                    margin-right: 0;
                }
            }
        }
        &--left {
            float: left;
            .vsl-product-bar-content-icon {
                left: 0;
                transform: rotate(-15deg);
            }
            .vsl-product-bar-content-text  {
                margin-left: $vsl-bar-icon-size;
                @include respond-to($break-s,$tweak-s-1) {
                    margin-left: $vsl-bar-icon-size - 50px;
                }
                @include respond-to(0,$break-s) {
                    margin-left: 0;
                }
            }
        }
        &--right,
        &--left {
            @include respond-to(0,$break-m) {
                float: none;
            }
        }
        &-icon {
            position: absolute;
            top: -25px;
            width: $vsl-bar-icon-size;
            height: $vsl-bar-icon-size;
            background-repeat: no-repeat;
            @include respond-to($break-s,$tweak-s-1) {
                top: -15px;
                width: $vsl-bar-icon-size - 30px;
                height: $vsl-bar-icon-size - 30px;
            }
            @include respond-to(0,$break-s) {
                display: none;
            }
            &--reading,
            &--literacy {
                background-image: url( 'https://vsl.widen.net/content/xwc7rydchb/svg/VSL_Badges_Reading.svg' );
            }
            &--writing {
                background-image: url( 'https://vsl.widen.net/content/kp1qouxqnp/svg/VSL_Badges_Writing.svg' );
            }
            &--math {
                background-image: url( 'https://vsl.widen.net/content/xp4tihxmv0/svg/VSL_Badges_Math.svg' );
            }
            &--assessment {
                background-image: url( 'https://vsl.widen.net/content/l7acpr8qfv/svg/VSL_Badges_Assessment.svg' );
            }
            &--cs{
                background-image: url( 'https://vsl.widen.net/content/0gxa0qtmrv/svg/VSL_Badges_Customer_Success.svg' );
            }
        }
    }
}
