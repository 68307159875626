﻿.left-floated-image img {
    padding-bottom: 30px;
}

span.h1,
span.h2,
span.h3,
span.h4 {
    display: block;
}


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

    .left-floated-image {
        float: left;
        padding-right: 30px;
    }
}
