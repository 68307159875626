.ed-view-360-header {
    @include vsl-gradient-gb-full-rev;
    background-image: url('https://www.voyagersopris.com/images/default-source/rebrand/edview_bg_rebrand_reverse22981deb-7168-4f98-83c3-e72575e84b8b.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 320px;
    color: $white;
    .table-layout {
        height: 320px;
    }
    @include respond-to(0,$custom-m-2) {
        height: auto;
        padding: 4rem 1rem;
        .table-layout {
            height: auto;
            & + .table-layout {
                margin-top: 2rem;
            }
        }
        text-align: center;
    }
    a, p, .accent-title1 {
        color: $white;
    }
    img {
        max-width: 320px;
    }
}

.ed-view-360-latest {
    h4 {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 10px;
        color: $color-brand-font;
    }

    p.title {
        font-weight: bold;
        margin: 0;
        font-size: 16px;
        line-height: 20px;
    }

    .col-xs-9 {
        padding-left: 0;
    }

    .col-md-12 p {
        margin-top: 10px;
    }
}

.edview-360 {
    .edview-logo {
        display: block;
        img {
            width: 250px;
            @include respond-to(0,$custom-m-2) {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .edview-360-header {
        color: $color-brand-blue;
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: 0.25rem;
        @include respond-to(0,$custom-m-2) {
            margin-top: 1.5rem;
        }
    }
    h3 {
        color: $color-brand-green-wcag;
        font-size: 1.5rem;
        font-weight: 800;
        margin-bottom: 1rem;
        &.edview-360-box-header {
            text-transform: uppercase;
        }
    }
    h4 {
        color: $color-brand-blue;
        font-size: 1.5rem;
        font-weight: 800;
        margin-bottom: 1rem;
    }
    .edview-360-box-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        div:not([class]) {
            display: grid;
        }
        @include respond-to(0,$break-m) {
            display: block;
            div:not([class]) {
                display: block;
            }
        }
    }
    .edview-360-box-container {
        display: grid;
        grid-auto-rows: 1fr 60px;
    }
    .edview-360-box {
        display: grid;
        background-color: $main-web-lt-blue;
        // margin: 0.5rem;
        padding: 2rem;
        text-align: center;
        color: $color-brand-blue;
        h4,
        .edview-360-title,
        .title {
            font-size: 1.1em;
            font-weight: 700;
            margin-bottom: 0.25em;
        }
        .edview-360-presenter {
            font-size: 0.925em;
        }
        .edview-360-link {
            align-self: end;
            margin-bottom: 0;
            .btn-full {
                width: 90%;
                margin: 0 auto;
            }
        }
    }
    .edview-360-box--light {
        background-color: $white;
    }
    .edview-360-view-all {
        margin-top: 1.1rem;
        text-align: center;
        a {
            color: $color-brand-green-wcag;
        }

    }
}

.edview-360--light .edview-360-box {
    background-color: $white;
}

.edview-360--interior {
    .edview-360-view-all {
        display: none;
    }
}


.webinar-series {
    li {
        margin-bottom: 15px;
    }
    .fa-li em {
        margin-right: 10px;
        font-size: 20px;
    }
    margin-bottom: 20px;
    @include respond-to($custom-m-2) {
        margin-bottom: 40px;
    }
}

.vsl-blog-archive,
.vsl-podcast-archive,
.vsl-webinar-archive {
    list-style: none;
    padding: 0;
    em {
        color: $color-brand-green;
    }
    .vsl-blog-archive-listing,
    .vsl-podcast-archive-listing,
    .vsl-webinar-archive-listing {
        padding-left: 1rem;
        li {
            font-size: .925rem;
            margin-bottom: 1rem;
        }
    }
}


.rep-info {
    display: block;
    padding-bottom: 30px;
    border-bottom: 1px $color-neutral-gray-13 solid;
    clear: both;
    width: 96%;
    margin-bottom: 20px;
  }
  .rep-info p {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .rep-info img {
    border: 2px solid $color-brand-blue;
    margin-bottom: 10px;
  }
  .rep-bio {
    clear: both;
    width: 96%;
  }
  .rep-bio h3 {
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .edview-360-recent {
    ul {
        list-style: none;
        padding-left: 0;
        li {
            font-size:  0.925rem;
            margin-bottom: 1rem;
        }
    }
  }