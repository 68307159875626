﻿// Hero banner home

.hero-banner-editable {
    background-image: url('https://www.voyagersopris.com/docs/default-source/background-images-and-icons/home.jpg?sfvrsn=b5c67b44_2');
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-banner-editable h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 46px;
    line-height: 60px;
    color: #ffffff;
    margin-bottom: 15px;
}

.hero-banner-editable-cta img {
    width: 80%;
}

.hero-banner-editable-cta p {
    color: #ffffff;
}

.btn-default {
    color: #004C7F;
}



@media screen and (max-width: 992px) {
    .hero-banner-editable-cta img {
        max-width: 400px;
    }

    .hero-banner-editable h2 {
        font-size: 36px;
        line-height: 40px;
    }
}