// hide the empty elements that are created by Card.Simple.cshtml

.caption p:empty {
    display: none;
}

.caption h3:empty {
    display: none;
}

.card-row {
    h3 {
        font-size: 24px;
    }

    img {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: .75rem;
    }
}