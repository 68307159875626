$webstore-tile-gutter : 30px;
.webstore-product-container {
    .webstore-product-list {
        list-style: none;
        display: grid;
        grid-column-gap: $webstore-tile-gutter;
        grid-row-gap: $webstore-tile-gutter;
        margin: 0;
        padding: 0;
        @include respond-to($tweak-s-1) {
            grid-auto-rows: 1fr;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include respond-to($custom-m-2) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            // grid-auto-columns: minmax(0, 1fr);
            // grid-auto-flow: column;
        }
        li,
        &-item {
            display: grid;
            grid-auto-rows: 1fr;
            position: relative;
            text-align: center;
            margin-bottom: 0;
        }
        a {
            display: grid;
            // grid-template-rows: 1fr 60px;           
            background-color: $main-web-lt-blue;
            transition: $global_transition;
            padding: $webstore-tile-gutter;
        }
        a:hover,
        a:focus {
            text-decoration: none;
            .webstore-product-title {
                text-decoration: underline;
            }
            .webstore-product-sku,
            .webstore-product-price {
                text-decoration: none;
            }
            .webstore-product-button {
                box-shadow: inset 0 -3.25em 0 0 $color-brand-blue;
            }
        }
    }
    .webstore-product-button {
        align-self: end;
        margin-top: 20px;
    }
    .webstore-product-image {
        margin-bottom: 30px;
        display: grid;
        justify-items: center;
        align-items: end;
        height: 160px;
        img {
            max-width: 160px;
            max-height: 170px;
        }
    }
    .webstore-product-sku,
    .webstore-product-price {
        color: $body_color;
    }
    .webstore-product-sku {
        font-size: .825rem;
        margin-bottom: 10px;
        display: none;
    }
    .webstore-product-price {
        font-size: .925rem;
        align-self: end;
        strong {
            color: $color-brand-blue-sky;
        }
    }
}

.webstore-product-rounded .webstore-product-container .webstore-product-list a {
    border-radius: 30px;
}
.webstore-product-thirds .webstore-product-container .webstore-product-list {
    @include respond-to($tweak-s-1) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include respond-to($custom-m-2) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
.webstore-product-halves .webstore-product-container .webstore-product-list {
    @include respond-to($tweak-s-1) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include respond-to($custom-m-2) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
.webstore-product-full .webstore-product-container .webstore-product-list {
    @include respond-to($tweak-s-1) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @include respond-to($custom-m-2) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}