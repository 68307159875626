﻿/*DEFAULT THEME*/
$default-theme-color: $color-brand-blue;

.banner-content {
    @include vsl-gradient-full-rev;
    padding: 1rem 0;
    h1, .h1, h2, .h2 {
        color: $white;
        margin: 0;
        font-size: 2.6rem;
        font-style: normal;
    }

    .product-banner-text {
        background-color: rgba($default-theme-color, .9);
    }
}

.stat-number,
.bordered-card .card-link {
    color: $default-theme-color;
}

.bordered-card,
.card-link:before {
    border-color: $default-theme-color;
}


.card-link:after {
    border-top-color: $default-theme-color;
}


.hero-banner-basic {
    @include vsl-gradient-full-rev;
    padding: 1rem 0;
    margin: 0;
    h1 {
        color: #ffffff;
        margin: 0;
        font-size: 2.6rem;
    }
}

.hero-banner-basic + .vsl-breadcrumbs {
    margin-bottom: 60px;
}

.default-theme {
    .banner-content {
        @include vsl-gradient-full-rev;
        h1 {
            color: $white;
            margin: 0;
        }
    }

    .stat-number,
    .bordered-card .card-link {
        color: $default-theme-color;
    }

    .bordered-card,
    .card-link:before {
        border-color: $default-theme-color;
    }


    .card-link:after {
        border-top-color: $default-theme-color;
    }
}

.reading-theme,
.literacy-theme {
    .banner-content,
    .product-banner-title,
    .product-navigation-layout {
        @include vsl-gradient-full-rev;
        h1 {
            color: $white;
            margin: 0;
        }
    }
    .carousel,
    .color-block .color-bg-darkblue {
        background-color: $color-brand-emerald;
    }
    .btn.btn-primary,
    .hero-banner-custom-bg-img .btn.btn-orange,
    .webstore-product-container .btn-lt-blue {
        background-color: $color-brand-emerald;
        &:active,
        &.active {
            background-color: $color-brand-emerald-dark;
        }
        &:hover,
        &:focus {
            box-shadow: inset 0 -3.25em 0 0 $color-brand-emerald-dark;
        }
    }
    .btn-side-by-side,
    .btn-side-by-side:active,
    .btn-side-by-side:visited {
        border: 2px solid $color-brand-emerald-dark;
        color: $color-brand-emerald-dark;
    }

    a.btn.btn-side-by-side,
    a.btn.btn-side-by-side:active,
    a.btn.btn-side-by-side:visited {
        color: $color-brand-emerald-dark;
    }
}
.writing-theme {
    .banner-content,
    .product-banner-title,
    .product-navigation-layout {
        @include vsl-gradient-full-rev;
        h1 {
            color: $white;
            margin: 0;
        }
    }
    .carousel,
    .color-block .color-bg-darkblue {
        background-color: $color-brand-blueberry;
    }
    .btn.btn-primary,
    .hero-banner-custom-bg-img .btn.btn-orange,
    .webstore-product-container .btn-lt-blue  {
        background-color: $color-brand-blueberry;
        &:active,
        &.active {
            background-color: $color-brand-blueberry-dark;
        }
        &:hover,
        &:focus {
            box-shadow: inset 0 -3.25em 0 0 $color-brand-blueberry-dark;
        }
    }
    .btn-side-by-side,
    .btn-side-by-side:active,
    .btn-side-by-side:visited {
        border: 2px solid $color-brand-blueberry;
        color: $color-brand-blueberry;
    }

    a.btn.btn-side-by-side,
    a.btn.btn-side-by-side:active,
    a.btn.btn-side-by-side:visited {
        color: $color-brand-blueberry;
    }
}
.math-theme {
    .banner-content,
    .product-banner-title,
    .product-navigation-layout {
        @include vsl-gradient-full-rev;
        h1 {
            color: $white;
            margin: 0;
        }
    }
    .carousel,
    .color-block .color-bg-darkblue {
        background-color: $color-brand-pomegranate;
    }
    .btn.btn-primary,
    .hero-banner-custom-bg-img .btn.btn-orange,
    .webstore-product-container .btn-lt-blue {
        background-color: $color-brand-pomegranate;
        &:active,
        &.active {
            background-color: $color-brand-pomegranate-dark;
        }
        &:hover,
        &:focus {
            box-shadow: inset 0 -3.25em 0 0 $color-brand-pomegranate-dark;
        }
    }
    .btn-side-by-side,
    .btn-side-by-side:active,
    .btn-side-by-side:visited {
        border: 2px solid $color-brand-pomegranate;
        color: $color-brand-pomegranate;
    }

    a.btn.btn-side-by-side,
    a.btn.btn-side-by-side:active,
    a.btn.btn-side-by-side:visited {
        color: $color-brand-pomegranate;
    }
}
.assessment-theme {
    .banner-content,
    .product-banner-title,
    .product-navigation-layout {
        @include vsl-gradient-full-rev;
        h1 {
            color: $white;
            margin: 0;
        }
    }
    .carousel,
    .color-block .color-bg-darkblue {
        background-color: $color-brand-blue-sky;
    }
    .btn.btn-primary,
    .hero-banner-custom-bg-img .btn.btn-orange,
    .webstore-product-container .btn-lt-blue {
        background-color: $color-brand-blue-sky;
        &:active,
        &.active {
            background-color: $color-brand-blue;
        }
        &:hover,
        &:focus {
            box-shadow: inset 0 -3.25em 0 0 $color-brand-blue;
        }
    }
    .btn-side-by-side,
    .btn-side-by-side:active,
    .btn-side-by-side:visited {
        border: 2px solid $color-brand-blue-sky;
        color: $color-brand-blue-sky;
    }

    a.btn.btn-side-by-side,
    a.btn.btn-side-by-side:active,
    a.btn.btn-side-by-side:visited {
        color: $color-brand-blue-sky;
    }
}
.customer-success-theme,
.product-banner-title,
.product-navigation-layout {
    .banner-content {
        @include vsl-gradient-full-rev;
        h1 {
            color: $white;
            margin: 0;
        }
    }
    .carousel,
    .color-block .color-bg-darkblue {
        background-color: $color-brand-tangerine;
    }
    .btn.btn-primary,
    .hero-banner-custom-bg-img .btn.btn-orange,
    .webstore-product-container .btn-lt-blue {
        background-color: $color-brand-tangerine;
        &:active,
        &.active {
            background-color: $color-brand-tangerine-dark;
        }
        &:hover,
        &:focus {
            box-shadow: inset 0 -3.25em 0 0 $color-brand-tangerine-dark;
        }
    }
    .btn-side-by-side,
    .btn-side-by-side:active,
    .btn-side-by-side:visited {
        border: 2px solid $color-brand-tangerine;
        color: $color-brand-tangerine;
    }

    a.btn.btn-side-by-side,
    a.btn.btn-side-by-side:active,
    a.btn.btn-side-by-side:visited {
        color: $color-brand-tangerine;
    }
}
