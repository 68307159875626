﻿.table>thead>tr>th {
    border-bottom: 0 none;
}

table {

}
th {
font-weight: normal;
text-align: left;
}

// Baseline styles

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
        > th,
        > td {
            vertical-align: top;
            padding: 8px;
            line-height: 1em;
            border-bottom: 1px solid $color-neutral-gray-13;
            ul {
                line-height: 1.5em
            }
            p {
                &:last-child,
                &:only-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
// Bottom align for column headings
> thead > tr > th {
    vertical-align: bottom;
    border-bottom: 1px solid $color-neutral-gray-13;
    font-weight: bold;
}
// Account for multiple tbody instances
> tbody + tbody {
    border-top: 1px solid $color-neutral-gray-13;
}

// Nesting
.table {
    background-color: $white;
}
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table--bordered {
border: 1px solid $color-neutral-gray-13;
> tbody,
> tfoot {
    > tr {
    > th,
    > td {
        border: 1px solid $color-neutral-gray-13;
    }
    }
}
> thead > tr {
    > th,
    > td {
    border: 1px solid $color-neutral-gray-13;
    }
}
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table--striped {
> tbody > tr:nth-child(odd) {
    > td,
    > th {
    background-color: $color-neutral-gray-07;
    a {
        color: $color-brand-blue-dark; //Accessibility requirement
    }
    }
}
}
// Zebra-stripe columns

.table--alt-striped {
> tbody > tr {
    > td:nth-child(odd),
    > th:nth-child(odd) {
    background-color: $color-neutral-gray-07;
    a {
        color: $color-brand-blue-dark; //Accessibility requirement
    }
    }
}
}


// Hover rows effect
//
// Placed here since it has to come after the potential zebra striping

.table--hover {
> tbody > tr:hover {
    > td,
    > th {
    background-color: $color-neutral-gray-13;
    }
}
}
.table-highlight {
    background-color: $color-neutral-gray-07;
    .table--reference:hover & {
        background-color: transparent;
    }
}

// Condensed table w/ half padding

.table--condensed {
> thead,
> tbody,
> tfoot {
    > tr {
    > th,
    > td {
        padding: 3px;
        line-height: 1em;
    }
    }
}
}

// Condensed table w/ double padding

.table--expanded {
> thead,
> tbody,
> tfoot {
    > tr {
    > th,
    > td {
        padding: 1em;
    }
    }
}
}

.table--header {
    >caption+thead>tr:first-child>td,
    >caption+thead>tr:first-child>th,
    >colgroup+thead>tr:first-child>td,
    >colgroup+thead>tr:first-child>th,
    >thead:first-child>tr:first-child>td,
    >thead:first-child>tr:first-child>th {
        border-top: 1px solid $color-neutral-gray-13;
    }
    > thead > tr:first-child,
    > tbody > tr:first-child {
        > th,
        > .th {
            font-weight: bold;
            background-color: $color-neutral-gray-07;
            line-height: 1.3em;
            border: 1px solid $color-neutral-gray-13;
        }
    }
    &__dark {
        >caption+thead>tr:first-child>td,
        >caption+thead>tr:first-child>th,
        >colgroup+thead>tr:first-child>td,
        >colgroup+thead>tr:first-child>th,
        >thead:first-child>tr:first-child>td,
        >thead:first-child>tr:first-child>th {
            border-top-color: $color-neutral-gray-93;
        }
        > thead > tr:first-child,
        > tbody > tr:first-child {
        > th,
        > .th {
            font-weight: bold;
            color: $white;
            background-color: $color-neutral-gray-73;
            border: 1px solid $color-neutral-gray-93;
            }
        }
    }
    &__light {
        >caption+thead>tr:first-child>td,
        >caption+thead>tr:first-child>th,
        >colgroup+thead>tr:first-child>td,
        >colgroup+thead>tr:first-child>th,
        >thead:first-child>tr:first-child>td,
        >thead:first-child>tr:first-child>th {
            border-top-color: $color-neutral-gray-13;
        }
        > thead > tr:first-child,
        > tbody > tr:first-child {
        > th,
        > .th {
            font-weight: bold;
            background-color: $color-brand-blue-light;
            border: 1px solid $color-neutral-gray-13;
            }
        }
    }
    &__blue {
        >caption+thead>tr:first-child>td,
        >caption+thead>tr:first-child>th,
        >colgroup+thead>tr:first-child>td,
        >colgroup+thead>tr:first-child>th,
        >thead:first-child>tr:first-child>td,
        >thead:first-child>tr:first-child>th {
            border-top-color: $color-brand-blue-sky;
        }
        > thead > tr:first-child,
        > tbody > tr:first-child {
        > th,
        > .th {
            font-weight: bold;
            color: $white;
            background-color: $color-brand-blue;
            border: 1px solid $color-brand-blue-dark;
            }
        }
    }
}

.table--notes, .basic-table-notes, .table--notes-centered {
    font-size: 0.75rem;
    padding: 0;
    margin-top: 1em;
}

.table--notes-centered {
    font-size: 0.875em;
    text-align: center;
}

.table--fixed {
    table-layout: fixed;
}

.table--alt {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1em;
    background-color: $white;
    font-size: 0.875em;
    font-weight: normal;
    line-height: 1em;
    > thead,
    > tbody,
    > tfoot {
    > tr {
        > th,
        > td {
        vertical-align: center;
        padding: 15px 8px;
        border-bottom: 0;
        }
    }
    th {
        font-weight: bold;
        color: $color-neutral-gray-73;
    }
    th[scope="col"] {
        font-size: 1.15em;
        line-height: 1.4em;
    }
    }
    > tbody > tr:nth-child(even) {
    > td,
    > th {
        background-color: $color-neutral-gray-07;
        a {
        color: $color-brand-blue-dark; //Accessibility requirement
        }
    }
    }
    > tbody > tr:hover {
        > td,
        > th {
        background-color: $color-neutral-gray-27;
        a {
            color: $color-brand-blue-dark; //Accessibility requirement
        }
        }
        > th[scope="col"] {
            background-color: transparent;
        }
    }
}
.table-responsive {
    .table--alt {
        margin-bottom: 0;
    }
}

.table--narrow {
    > thead,
    > tbody,
    > tfoot {
    > tr {
        > th,
        > td {
        padding: 12px 8px;
        }
    }
    }
}

.table--valign {
    > thead,
    > tbody,
    > tfoot {
    > tr {
        > th,
        > td {
        vertical-align: middle;
        }
    }
    }
}

// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
position: static; // Prevent border hiding in Firefox and IE9/10 (see https://github.com/twbs/bootstrap/issues/11623)
float: none;
display: table-column;
}
table {
td,
th {
    &[class*="col-"] {
    position: static; // Prevent border hiding in Firefox and IE9/10 (see https://github.com/twbs/bootstrap/issues/11623)
    float: none;
    display: table-cell;
    }
}
}

.carets {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &--right {
        padding-right: 20px !important;
        &:after {
            right: 0px;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid $color-neutral-gray-13;
        }
    }
}


// Customize Bootstrap's .table-responsive to give it a border-radius and bigger bottom margin.
.table-responsive {
    width: 100%;
    border: 1px solid $color-neutral-gray-13;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1rem;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;
      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table--bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            border-top: 0;
          }
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      &.table--header {
          > thead,
          > tbody,
          > tfoot {
            > tr:first-child {
              > th {
                border-right: 1px solid $color-neutral-gray-13;
                border-top: 0;
              }
              > th:last-child,
              > td:last-child {
                border-right: 0;
              }
            }
          }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
    // Special overrides for the header tables
    > .table--header {
      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr:first-child {
          > th,
          > td {
            border-top: 0 none;
            border-left: 0 none;
            border-right: 0 none;
          }
        }
      }
    }
  }

  .table-responsive[data-pattern="priority-columns"] {
      position: relative;
  }

  // Column priority
  .mq.js .table-responsive[data-pattern="priority-columns"],
  .mq.js.lt-ie10 .sticky-table-header {

      th[data-priority],
      td[data-priority] {
         display: none;
      }

      .lt-ie9 & th[data-priority="1"],
      .lt-ie9 & td[data-priority="1"] {
          display: inline;
      }
      th[data-priority="1"],
      td[data-priority="1"] {
          display: table-cell;
      }

      @include respond-to ($break-s) {
          .lt-ie9 & th[data-priority="2"],
          .lt-ie9 & td[data-priority="2"] {
              display: inline;
          }
          th[data-priority="2"],
          td[data-priority="2"] {
              display: table-cell;
          }
      }

      @include respond-to ($tweak-s-2) {
          .lt-ie9 & th[data-priority="3"],
          .lt-ie9 & td[data-priority="3"] {
              display: inline;
          }
          th[data-priority="3"],
          td[data-priority="3"] {
              display: table-cell;
          }
      }

      @include respond-to ($break-m) {
          .lt-ie9 & th[data-priority="4"],
          .lt-ie9 & td[data-priority="4"] {
              display: inline;
          }
          th[data-priority="4"],
          td[data-priority="4"] {
              display: table-cell;
          }
      }

      @include respond-to ($tweak-m-1) {
          .lt-ie9 & th[data-priority="5"],
          .lt-ie9 & td[data-priority="5"] {
              display: inline;
          }
          th[data-priority="5"],
          td[data-priority="5"] {
              display: table-cell;
          }
      }

      @include respond-to ($tweak-m-2) {
          .lt-ie9 & th[data-priority="6"],
          .lt-ie9 & td[data-priority="6"] {
              display: inline;
          }
          th[data-priority="6"],
          td[data-priority="6"] {
              display: table-cell;
          }
      }
  }

  //table utility classes
  table {

      &.table--small-font {
          font-size: .75em;
          -webkit-text-size-adjust:none;
          line-height: 1.5em;
      }

      // focus
      &.focus-on tbody {
          tr:hover {
              cursor: pointer;
          }

          tr {
              &.unfocused {
                  th, td {
                      color: $color-neutral-gray-07;
                      color: rgba(0,0,0,0.4);
                  }
              }
              &.focused {
                  th, td {
                      background-color: $color-neutral-gray-13;
                      color: #000;
                  }
              }
          }
      }

      //hide cell
      th.col-hide,
      td.col-hide {
         display: none;
      }

      //show cell
      th.col-show,
      td.col-show {
         display: inline;
      }

      //show cell (ie9)
      .lt-ie9 & th.col-show,
      .lt-ie9 & td.col-show {
         display: table-cell;
      }

      // display/hide all
      .lt-ie9 &.display-all {
          th, td {
              display: inline !important;
          }
      }
      .lt-ie9 &.hide-all {
          tbody th, td, th[data-priority] {
              display: none !important;
          }
          td:first-child, td+th {
              display: table-cell !important;
          }
      }

      &.display-all {
          th, td {
              display: table-cell !important;
          }
      }
      &.hide-all {
          tbody th, td, th[data-priority] {
              display: none !important;
          }
          td:first-child, td+th {
              display: table-cell !important;
          }
      }
  }

  //Sticky table header
  .sticky-table-header {
    background-color: $color-neutral-gray-07;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $color-neutral-gray-13;
    position: absolute;
    top: 0;
    visibility: hidden;
    z-index: 10;
    overflow: hidden;
    min-width: 100%;

    &.fixed-solution {
        min-width: 0;
        position: fixed;

        .lt-ie10 & {
            border-left: 1px solid $color-neutral-gray-07;
            border-right: 1px solid $color-neutral-gray-07;
            box-sizing: content-box;
            border-bottom: none;
        }
    }

      // Tighten up spacing
      > .table {
        margin-bottom: 0;
      }

      // Special overrides for the bordered tables
      > .table--bordered {
        border: 0;

        // Nuke the appropriate borders so that the parent can handle them
        > thead,
        > tbody,
        > tfoot {
          > tr {
            > th:first-child,
            > td:first-child {
              border-left: 0;
            }
            > th:last-child,
            > td:last-child {
              border-right: 0;
            }
          }
        }

        // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
        // chances are there will be only one `tr` in a `thead` and that would
        // remove the border altogether.
        > tbody,
        > tfoot {
          > tr:last-child {
            > th,
            > td {
              border-bottom: 0;
            }
          }
        }

      }
  }

  .k-data-table,
  .k-table {
    color: $color-brand-font;
  }